import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import PropTypes from 'prop-types';
import { Button } from '../Button';

export const ConfirmDialog = ({
  open,
  handleClose,
  dialogTitle,
  dialogBody,
  classes,
  dialogIcon,
  buttons,
  ...restProps
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="confirm-box-wrapper custom-dialog"
      classes={classes}
      {...restProps}>
      <div className="dialog-content-wrapper">
        {dialogIcon && (
          <span className="icon-block">
            <img src={dialogIcon} alt="icon" />
          </span>
        )}
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogBody}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {buttons &&
            buttons.map((button, index) => (
              <Button
                key={index}
                onClick={button.event}
                name={button.name}
                className={button.className}
              />
            ))}
        </DialogActions>
      </div>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  dialogTitle: PropTypes.string.isRequired,
  dialogBody: PropTypes.string,
  handleSubmit: PropTypes.func,
  classes: PropTypes.object,
  dialogIcon: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      event: PropTypes.func,
      classname: PropTypes.string
    })
  )
};
