import { call, put, takeLatest } from 'redux-saga/effects';
import { RESPONSE_STATUS_CODES } from '../../constants/common';
import { GET_DASHBOARD_DATA_REQUEST } from '../../constants/redux';
import { getDashboardApi } from '../apis/dashboard';
import { apiFail } from '../slices/apiStatus';
import { getDashboardStatistics } from '../slices/dashboard';

function* getDashboardSaga() {
  try {
    const response = yield call(getDashboardApi);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      yield put(getDashboardStatistics(result));
    }
  } catch (err) {
    yield put(apiFail());
  }
}

function* watcherDashboardSaga() {
  yield takeLatest(GET_DASHBOARD_DATA_REQUEST, getDashboardSaga);
}

export default watcherDashboardSaga;
