import { createSlice } from '@reduxjs/toolkit';
import { getDateFromDateTime } from '../../utils/helper';

const initialState = {};

const memberSlice = createSlice({
  name: 'member',
  initialState: initialState,
  reducers: {
    getMembers(state, action) {
      let membersData = action.payload.memberData;
      const paginationData = action.payload.pagination;
      membersData = membersData.map((member) => {
        return {
          ...member,
          membershipEndDate: getDateFromDateTime(member.membershipEndDate)
        };
      });
      return { ...state, membersData, paginationData };
    }
  }
});

export const { getMembers } = memberSlice.actions;
export default memberSlice.reducer;
