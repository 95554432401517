import { pageLoader } from '../constants/images';
export const PageLoader = () => {
  return (
    <>
      <div className="page-loader">
        <em>
          <img src={pageLoader} alt="loader" />
        </em>
      </div>
    </>
  );
};
