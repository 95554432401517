import * as Yup from 'yup';
import {
  IABS_ALLOWED_ROLES,
  OTHER_BLAST_PERMISSION_BROKERAGE_OFFICES,
  REGEX
} from '../constants/common';
import { checkImageDimmensions, getUserRole } from '../utils/helper';

export const addUserSchema = Yup.object({
  firstName: Yup.string().min(3).required().label('First Name'),
  lastName: Yup.string().min(3).required().label('Last Name'),
  phone: Yup.number().required().label('Phone number'),
  role: Yup.number().oneOf([1, 2, 3]).required().label('Role'),
  email: Yup.string().email().required().label('Email'),
  status: Yup.number().oneOf([0, 1]).required().label('Status'),
  IABS: Yup.string()
    .nullable()
    .when('role', ([role], schema) => {
      if (IABS_ALLOWED_ROLES.includes(getUserRole(role))) {
        return schema.matches(REGEX.websiteValidation, 'Please enter correct IABS URL').required();
      }
      return schema;
    }),
  brokerageOfficeIds: Yup.array().when('role', ([role], schema) => {
    if (OTHER_BLAST_PERMISSION_BROKERAGE_OFFICES.includes(getUserRole(role))) {
      return schema.label('Other Blast Permissions');
    }
    return schema;
  })
});

export const updateProfileSchema = Yup.object({
  firstName: Yup.string().min(3).required().label('First Name'),
  lastName: Yup.string().min(3).required().label('Last Name'),
  phone: Yup.number().required().label('Phone number'),
  email: Yup.string().email().required().label('Email'),
  role: Yup.number().oneOf([1, 2, 3]).required().label('Role'),
  IABS: Yup.string()
    .nullable()
    .when('role', ([role], schema) => {
      if (IABS_ALLOWED_ROLES.includes(getUserRole(role))) {
        return schema.matches(REGEX.websiteValidation, 'Please enter correct IABS URL').required();
      }
      return schema;
    })
  // confidentialityAgreementLink: Yup.string()
  //   .nullable()
  //   .when('role', ([role], schema) => {
  //     if (IABS_ALLOWED_ROLES.includes(getUserRole(role))) {
  //       return schema
  //         .matches(
  //           REGEX.websiteValidation,
  //           'Please enter correct confidentiality Agreement Link URL'
  //         )
  //         .required();
  //     }
  //     return schema;
  //   })
});

export const changePasswordSchema = Yup.object({
  oldPassword: Yup.string().min(8).max(20).required().label('Old Password'),
  newPassword: Yup.string().min(8).max(20).required().label('New Password'),
  confirmPassword: Yup.string()
    .required()
    .label('Confirm Password')
    .oneOf([Yup.ref('newPassword'), null], 'Password must match')
});

export const adminSettingSchema = Yup.object({
  imageLimit: Yup.number().required().label('Email maximum limit'),
  propertyTag: Yup.number().required().label('Property tag reminder interval')
});

export const addBrokerageOfficeSchema = Yup.object({
  name: Yup.string().required().label('Name'),
  address: Yup.string().label('Address'),
  zipCode: Yup.number().typeError('Zip Code must be a valid number').label('Zip Code'),
  realtors: Yup.array().label('Realtors'),
  salesManager: Yup.number().required().label('Sales Manager'),
  file: Yup.mixed()
    .required()
    .label('Logo')
    .test('type', 'Only the following formats are accepted: .jpeg, .jpg, .png', (value) => {
      // Added for edit brokerage office
      if (value[0]?.imageValid) {
        return true;
      }
      return (
        value &&
        (value[0]?.type === 'image/jpeg' ||
          value[0]?.type === 'image/png' ||
          value[0]?.type === 'image/jpg')
      );
    })
    .test('aspectRatio', 'Dimenssions of image must be 225*75', async (value) => {
      // Added for edit brokerage office
      if (value[0]?.imageValid) {
        return true;
      }
      if (value.length !== 0) {
        const img = await checkImageDimmensions(value);
        return img;
      } else {
        return false;
      }
    }),
  keapTagId: Yup.number().typeError('Tag id must be a valid number').required().label('Tag Id')
});

export const reassignPropertyTagSchema = Yup.object({
  realtor: Yup.number().required().label('Realtor')
});
