import { Dialog, DialogContent } from '@mui/material';
import PropTypes from 'prop-types';
import { ReassignPropertyTag } from '../../pages/Admin/reassignPropertyTag';
import { Label } from '../Label';
import { LABELS } from '../../constants/common';
import './../../styles/inner-page-style.css';
import './../../styles/form-box-layout-style.css';

export const ReassignTagDialog = ({
  open,
  handleClose,
  classes,
  dialogIcon,
  propertyData,
  setOpenReassignDialog,
  ...restProps
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="View-user-wrapper view-brokerage-wrapper custom-dialog"
      classes={classes}
      {...restProps}>
      <div className="dialog-content-wrapper">
        <div className="title-wrapper">
          <Label variant="h3" label={LABELS.REASSIGN_TAG} />
          <button className="close-icon" onClick={handleClose} name="close">
            <img src={dialogIcon} alt="close icon" />
          </button>
        </div>
        <DialogContent>
          <ReassignPropertyTag
            propertyData={propertyData}
            setOpenReassignDialog={setOpenReassignDialog}
          />
        </DialogContent>
      </div>
    </Dialog>
  );
};

ReassignTagDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  classes: PropTypes.object,
  dialogIcon: PropTypes.string,
  propertyData: PropTypes.object,
  setOpenReassignDialog: PropTypes.func
};
