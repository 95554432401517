import { AuthenticationDetails } from 'amazon-cognito-identity-js';
import { toast } from 'react-toastify';
import { API_ROUTES } from '../../constants/apiRoutes';
import { CRYPTO_STORAGE_KEY, MESSAGES, SESSION_STORAGE_KEY } from '../../constants/common';
import { ROUTE_LIST } from '../../constants/routes';
import { decryptData, encryptData, getApiRoute } from '../../utils/helper';
import { getCognitoUser } from '../../utils/userPool';
import api from '../../utils/api';

// Endpoint call for login
export const loginApi = async ({ email, password }) => {
  try {
    const response = await new Promise((resolve, reject) => {
      email = email.toLowerCase();
      const user = getCognitoUser(email);

      const authDetails = new AuthenticationDetails({
        Username: email,
        Password: password
      });

      const encryptedUser = encryptData({
        email,
        password
      });
      // store user credentials in session storage because need that in resend code
      sessionStorage.setItem(CRYPTO_STORAGE_KEY, encryptedUser);

      // Added because we call custom lambda function which return code for MFA
      user.setAuthenticationFlowType('CUSTOM_AUTH');

      user.authenticateUser(authDetails, {
        onSuccess: (result) => {
          resolve(result);
        },
        onFailure: (err) => {
          toast.error(err.message);
          reject(err);
        },
        customChallenge: function () {
          localStorage.setItem(SESSION_STORAGE_KEY, user.Session);
          // navigate into verify otp page
          window.location.href = ROUTE_LIST.VERIFY_OTP;
        },
        newPasswordRequired: function () {
          localStorage.setItem(SESSION_STORAGE_KEY, user.Session);
          // navigate to change password page
          window.location.href = ROUTE_LIST.CHANGE_PASSWORD;
        }
      });
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

// Endpoint call for verify otp
export const verifyOtpApi = async ({ otp }) => {
  try {
    const response = await new Promise((resolve, reject) => {
      const userData = sessionStorage.getItem(CRYPTO_STORAGE_KEY);
      const decryptedUser = decryptData(userData);
      const cognitoUser = getCognitoUser(decryptedUser.email);

      // get current session from local storage
      cognitoUser.Session = localStorage.getItem(SESSION_STORAGE_KEY);
      // User authentication depends on challenge respons
      cognitoUser.sendCustomChallengeAnswer(otp, {
        onSuccess: (result) => {
          localStorage.removeItem(SESSION_STORAGE_KEY);
          sessionStorage.removeItem(CRYPTO_STORAGE_KEY);
          resolve(result);
        },
        onFailure: (err) => {
          localStorage.setItem(SESSION_STORAGE_KEY, cognitoUser.Session);
          toast.error(MESSAGES.PLEASE_ENTER_CORRECT_OTP);
          reject(err);
        }
      });
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

// Endpoint call for resend otp
export const resendOtpApi = async () => {
  try {
    const response = await new Promise((resolve, reject) => {
      const userData = sessionStorage.getItem(CRYPTO_STORAGE_KEY);
      const { email, password } = decryptData(userData);
      const user = getCognitoUser(email);

      const authDetails = new AuthenticationDetails({
        Username: email,
        Password: password
      });

      // Added because we call custom lambda function which return code for MFA
      user.setAuthenticationFlowType('CUSTOM_AUTH');

      user.authenticateUser(authDetails, {
        onSuccess: (result) => {
          resolve(result);
        },
        onFailure: (err) => {
          toast.error(err.message);
          reject(err);
        },
        customChallenge: function () {
          // set new session in local storage
          localStorage.setItem(SESSION_STORAGE_KEY, user.Session);
          toast.success(MESSAGES.SUCCESSFULLY_RESEND_CODE);
          resolve();
        }
      });
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

// Endpoint call for forgot password
export const forgotPasswordApi = async (email) => {
  try {
    const response = await new Promise((resolve, reject) => {
      email = email.toLowerCase();
      const cognitoUser = getCognitoUser(email);

      cognitoUser.forgotPassword({
        onSuccess: function (result) {
          toast.success(MESSAGES.SUCCESSFULLY_SEND_MAIL_FOR_RESET_PASSWORD);
          resolve(result);
        },
        onFailure: function (err) {
          toast.error(err.message);
          reject(err);
        }
      });
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

// Endpoint call for reset password
export const resetPasswordApi = async ({ userName, code, password }) => {
  try {
    const response = await new Promise((resolve, reject) => {
      const cognitoUser = getCognitoUser(userName);

      cognitoUser.confirmPassword(code, password, {
        onSuccess: function (result) {
          toast.success(MESSAGES.SUCCESSFULLY_CHANGED_PASSWORD);
          resolve(result);
        },
        onFailure: function (err) {
          toast.error(err.message);
          reject(err);
        }
      });
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

// Endpoint call whenever user do login first time
export const firstLoginChangePasswordApi = async ({ password: newPassword }) => {
  try {
    const response = await new Promise((resolve, reject) => {
      const userData = sessionStorage.getItem(CRYPTO_STORAGE_KEY);
      const decryptedUser = decryptData(userData);
      const cognitoUser = getCognitoUser(decryptedUser.email);

      cognitoUser.Session = localStorage.getItem(SESSION_STORAGE_KEY);
      cognitoUser.completeNewPasswordChallenge(newPassword, null, {
        onSuccess: (result) => {
          resolve(result);
        },
        onFailure: (err) => {
          toast.error(err.message);
          reject(err);
        }
      });
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getProfileApi = async () => {
  try {
    const response = await api.get(API_ROUTES.GET_PROFILE);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateProfileApi = async (user) => {
  try {
    const updateProfileApiRoute = getApiRoute(API_ROUTES.UPDATE_PROFILE, user.id);
    const response = await api.put(updateProfileApiRoute, {
      firstName: user?.firstName,
      lastName: user?.lastName,
      phone: user?.phone,
      brokerageOfficeIds: user?.brokerageOffices.map((officeId) => officeId.value),
      IABS: user?.IABS
      // confidentialityAgreementLink: user?.confidentialityAgreementLink
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
