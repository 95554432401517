import { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { FormLabel } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Label } from '../../components/Label';
import { Logo } from '../../components/Logo';
import { verifyOtp, resendOtp } from '../../redux/actions/auth';
import { apiStatusClear } from '../../redux/slices/apiStatus';
import { ROUTE_LIST } from '../../constants/routes';
import { OTP_LENGTH } from '../../constants/common';
import './../../styles/login-style.css';

export const VerifyOtp = () => {
  const [otp, setOtp] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { isFailed, isSucceed, isLoading } = useSelector((state) => state.apiStatus);

  const handleVerify = (e) => {
    e.preventDefault();
    if (otp.length === OTP_LENGTH) {
      dispatch(
        verifyOtp({
          otp: otp
        })
      );
    }
  };

  const handleResend = () => {
    dispatch(resendOtp());
  };

  useEffect(() => {
    if (isLoggedIn && isSucceed) {
      navigate(ROUTE_LIST.DASHBOARD);
    }
  }, [isLoggedIn, isSucceed]);

  useEffect(() => {
    if (isFailed) {
      setOtp(''); // Set textbox empty if api failed
      dispatch(apiStatusClear());
    }
  }, [isFailed]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleVerify(event);
    }
  };

  return (
    <div>
      <div className="login-page verify-otp-page">
        <div className="login-wrapper">
          <div className="login-block">
            <div className="logo-wrapper">
              <Link to="#" className="logo">
                <Logo />
              </Link>
            </div>
            <Label variant="h1" label={'Verify OTP'} />
            <p>Please check your email for OTP</p>
            <form onSubmit={handleVerify}>
              <div className="form-wrapper white-box otp-wrapper has-required">
                <FormLabel>Enter OTP</FormLabel>
                <OtpInput
                  value={otp}
                  shouldAutoFocus
                  onChange={setOtp}
                  numInputs={6}
                  inputType="tel"
                  // renderSeparator={<span>-</span>}
                  renderInput={(props, index) => (
                    <input
                      {...props}
                      onKeyDown={handleKeyPress}
                      placeholder="0"
                      required
                      id={index}
                    />
                  )}
                />
              </div>
              <div className="link-wrapper">
                <Button
                  type="button"
                  className="blue-link"
                  name="Resend OTP"
                  onClick={handleResend}
                />
              </div>
              <Button
                className="blue-btn"
                type="submit"
                name="Verify"
                disabled={isLoading}
                isLoading={isLoading}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
