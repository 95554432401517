import PropTypes from 'prop-types';
import { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Label } from '../../components/Label';
import { TextField } from '../../components/TextField';
import { Button } from '../../components/Button';
import { changePassword } from '../../redux/actions/admin';
import { changePasswordSchema } from '../../schemas/adminSchema';
import { LABELS } from '../../constants/common';
import 'react-phone-number-input/style.css';
import './../../styles/inner-page-style.css';
import './../../styles/form-box-layout-style.css';

export const ChangePassword = ({ handleSaveProfile, isPasswordChanged, handlePasswordChange }) => {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.apiStatus);
  const initialValues = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  };

  const { values, errors, handleChange, handleBlur, handleSubmit, touched, resetForm } = useFormik({
    initialValues,
    validationSchema: changePasswordSchema,
    onSubmit: (values) => {
      dispatch(changePassword(values));
      handleSaveProfile(false);
      handlePasswordChange(true);
      resetForm();
    }
  });

  return (
    <>
      <div className="secondary-title-wrapper">
        <Label variant="h2" label={LABELS.CHANGE_PASSWORD} />
      </div>
      <div className="white-box shadow-box">
        <form onSubmit={handleSubmit}>
          <div className="form-row-wrapper">
            <div className="form-col has-required">
              <TextField
                fullWidth
                id="oldpassword"
                label="Old Password"
                type={showOldPassword ? 'text' : 'password'}
                placeholder="Old Password"
                name="oldPassword"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.oldPassword}
                error={touched.oldPassword && Boolean(errors.oldPassword)}
                helperText={touched.oldPassword && errors.oldPassword}
                className="form-group password-wrapper"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowOldPassword((show) => !show)}
                        onMouseDown={(event) => event.preventDefault()}
                        edge="end">
                        {showOldPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </div>
            <div className="form-col fullwidth-column">
              <div className="form-row-wrapper">
                <div className="form-col has-required">
                  <TextField
                    fullWidth
                    id="newpassword"
                    label="New Password"
                    type={showNewPassword ? 'text' : 'password'}
                    placeholder="New Password"
                    name="newPassword"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.newPassword}
                    error={touched.newPassword && Boolean(errors.newPassword)}
                    helperText={touched.newPassword && errors.newPassword}
                    className="form-group password-wrapper"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowNewPassword((show) => !show)}
                            onMouseDown={(event) => event.preventDefault()}
                            edge="end">
                            {showNewPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
                <div className="form-col has-required">
                  <TextField
                    fullWidth
                    id="confirmpassword"
                    label="Confirm Password"
                    type={showConfirmPassword ? 'text' : 'password'}
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmPassword}
                    error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                    helperText={touched.confirmPassword && errors.confirmPassword}
                    className="form-group password-wrapper"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowConfirmPassword((show) => !show)}
                            onMouseDown={(event) => event.preventDefault()}
                            edge="end">
                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form-btn-wrapper">
            <Button
              className="primary-btn"
              type="submit"
              name="Change Password"
              disabled={isLoading && isPasswordChanged}
              isLoading={isLoading && isPasswordChanged}
            />
          </div>
        </form>
      </div>
    </>
  );
};

ChangePassword.propTypes = {
  handleSaveProfile: PropTypes.func,
  isPasswordChanged: PropTypes.bool,
  handlePasswordChange: PropTypes.func
};
