import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  firstLoginChangePasswordApi,
  forgotPasswordApi,
  loginApi,
  resendOtpApi,
  resetPasswordApi,
  verifyOtpApi
} from '../apis/auth';
import {
  FIRST_LOGIN_CHANGE_PASSWORD_REQUEST,
  FORGOT_PASSWORD_REQUEST,
  GET_PROFILE_REQUEST,
  LOGIN_REQUEST,
  RESEND_OTP_REQUEST,
  RESET_PASSWORD_REQUEST,
  SET_LOGGED_IN_USERINFO_REQUEST,
  UPDATE_PROFILE_REQUEST,
  VERIFY_OTP_REQUEST
} from '../../constants/redux';
import { MESSAGES, RESPONSE_STATUS_CODES } from '../../constants/common';
import { verifyOtp, setUser } from '../slices/auth';
import { apiFail, apiPending, apiSuccess } from '../slices/apiStatus';
import { getProfile } from '../slices/auth';
import { getProfileApi, updateProfileApi } from '../apis/auth';

function* loginSaga(action) {
  try {
    yield put(apiPending()); // Added pending here because api call is not from the axios
    yield call(loginApi, action.payload);
  } catch (error) {
    yield put(apiFail());
  }
}

function* verifyOtpSaga(action) {
  try {
    yield put(apiPending()); // Added pending here because api call is not from the axios
    yield call(verifyOtpApi, action.payload);
    yield put(verifyOtp());
    const response = yield call(getProfileApi);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      yield put(getProfile(result));
    }
    yield put(apiSuccess());
  } catch (error) {
    yield put(apiFail());
  }
}

function* resendOtpSaga() {
  try {
    yield put(apiPending());
    yield call(resendOtpApi);
    yield put(apiSuccess());
  } catch (error) {
    yield put(apiFail());
  }
}

function* forgotPasswordSaga(action) {
  try {
    yield put(apiPending());
    yield call(forgotPasswordApi, action.payload);
    yield put(apiSuccess());
  } catch (error) {
    yield put(apiFail());
  }
}

function* resetPasswordSaga(action) {
  try {
    yield put(apiPending());
    yield call(resetPasswordApi, action.payload);
    yield put(apiSuccess());
  } catch (error) {
    yield put(apiFail());
  }
}

function* firstLoginChangePasswordSaga(action) {
  try {
    yield put(apiPending());
    yield call(firstLoginChangePasswordApi, action.payload);
    const response = yield call(getProfileApi);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      yield put(getProfile(result));
      yield put(setUser());
    }
    yield put(apiSuccess());
  } catch (error) {
    yield put(apiFail());
  }
}

function* setUserInfoSaga() {
  try {
    const response = yield call(getProfileApi);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      yield put(getProfile(result));
      yield put(setUser());
    }
  } catch (error) {
    yield put(apiFail());
  }
}

function* getProfileSaga() {
  try {
    const response = yield call(getProfileApi);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      yield put(getProfile(result));
    }
  } catch (err) {
    yield put(apiFail());
  }
}

function* updateProfileSaga(action) {
  try {
    const response = yield call(updateProfileApi, action.payload);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      yield put(getProfile(result));
      toast.success(MESSAGES.SUCCESSFULLY_UPDATED_PROFILE);
    }
  } catch (err) {
    yield put(apiFail());
  }
}

function* watcherAuthSaga() {
  yield takeLatest(LOGIN_REQUEST, loginSaga);
  yield takeLatest(VERIFY_OTP_REQUEST, verifyOtpSaga);
  yield takeLatest(RESEND_OTP_REQUEST, resendOtpSaga);
  yield takeLatest(FORGOT_PASSWORD_REQUEST, forgotPasswordSaga);
  yield takeLatest(RESET_PASSWORD_REQUEST, resetPasswordSaga);
  yield takeLatest(FIRST_LOGIN_CHANGE_PASSWORD_REQUEST, firstLoginChangePasswordSaga);
  yield takeLatest(SET_LOGGED_IN_USERINFO_REQUEST, setUserInfoSaga);
  yield takeLatest(GET_PROFILE_REQUEST, getProfileSaga);
  yield takeLatest(UPDATE_PROFILE_REQUEST, updateProfileSaga);
}

export default watcherAuthSaga;
