import { useState } from 'react';
import PropTypes from 'prop-types';
import { Tab as MuiTab, Tabs } from '@mui/material';

const TabPanel = ({ children, value, index, ...restProps }) => {
  return <div {...restProps}>{value === index && <div>{children}</div>}</div>;
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

export const Tab = ({ tabItems, orientation = 'horizontal', classes, variant, ...restProps }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div>
      <Tabs
        orientation={orientation}
        value={activeTab}
        onChange={(event, newValue) => setActiveTab(newValue)}
        aria-label="Vertical tabs"
        variant={variant}
        classes={classes}
        {...restProps}>
        {tabItems.map((item, index) => {
          return <MuiTab label={item.label} key={index} />;
        })}
      </Tabs>
      {tabItems.map((item, index) => {
        return (
          <TabPanel value={activeTab} index={index} key={index}>
            {item.component}
          </TabPanel>
        );
      })}
    </div>
  );
};

Tab.propTypes = {
  tabItems: PropTypes.array,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  classes: PropTypes.object,
  variant: PropTypes.oneOf(['fullWidth', 'scrollable', 'standard'])
};
