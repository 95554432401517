import PropTypes from 'prop-types';
import { Header } from '../Header';
import { Sidebar } from '../Sidebar';

const Layout = ({ content }) => {
  return (
    <>
      <div className="page-wrapper">
        <Header />
        <Sidebar />
        {content}
      </div>
    </>
  );
};

// ***** PROPS TYPES *****
Layout.propTypes = {
  content: PropTypes.any
};

export default Layout;
