import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Label } from '../../components/Label';
import { BreadCrumb } from '../../components/BreadCrumb';
import { DataTable } from '../../components/DataTable';
import { Button } from '../../components/Button';
import { PageLoader } from '../../components/PageLoader';
import { OpenFileInNewTab } from '../../components/FileDownload';
import { SingleSelect } from '../../components/DropDown/SingleSelect';
import { getRealtorBrokerageOfficeApi } from '../../redux/apis/realtor';
import { deleteDraftEmailBlast, draftEmailBlastList } from '../../redux/actions/emailBlast';
import { deleteAction, dialogDeleteIcon, editAction, pdfIcon } from '../../constants/images';
import { ROUTE_LIST } from '../../constants/routes';
import {
  ALL_FILTERS,
  BREAD_CRUMB_LABELS,
  DEBOUNCE_TIMEOUT,
  defaultQueryData,
  DIALOG,
  LABELS,
  ROLE
} from '../../constants/common';
import { debounce, getApiRoute } from '../../utils/helper';
import { ConfirmDialog } from '../../components/Popup/ConfirmDialog';

export const EmailDraftList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { role } = useSelector((state) => state.auth);
  const { draftEmailBlastData, paginationData } = useSelector((state) => state.emailBlast);
  const [queryData, setQueryData] = useState({ ...defaultQueryData });
  const { isLoading } = useSelector((state) => state.apiStatus);
  const [search, setSearch] = useState('');
  const all_offices = {
    id: ALL_FILTERS,
    name: 'All Brokerage Office'
  };
  const [brokerageOfficeOptions, setBrokerageOfficeOptions] = useState([all_offices]);
  const [brokerageOffice, setBrokerageOffice] = useState(ALL_FILTERS);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [openDialog, setOpen] = useState(false);
  const [draft, setDraft] = useState();
  const breadcrumbsLinks = [
    {
      label: BREAD_CRUMB_LABELS.DASHBOARD,
      to: ROUTE_LIST.DASHBOARD
    },
    {
      label: BREAD_CRUMB_LABELS.DRAFT_EMAIL_BLAST,
      last: true
    }
  ];

  useEffect(() => {
    dispatch(draftEmailBlastList());
    getRealtorBrokerageOfficeApi().then((res) =>
      setBrokerageOfficeOptions([all_offices, ...res.data.data])
    );
    setIsInitialRender(false);
  }, []);

  //edit draft navigation
  const editActionHandler = (params) => {
    const editBlastRoute = getApiRoute(ROUTE_LIST.EDIT_DRAFTED_EMAIL_BLAST, params.id);
    navigate(editBlastRoute);
  };

  // Delete Draft
  const openDeleteDialog = (params) => {
    setOpen(true);
    setDraft(params.row);
  };
  const closeDeleteDialog = () => {
    setOpen(false);
  };
  const deleteDraftHandler = () => {
    dispatch(
      deleteDraftEmailBlast({
        draft: {
          ...draft
        },
        queryData
      })
    );
    setOpen(false);
  };
  const buttonsList = [
    {
      name: 'Cancel',
      className: 'primary-btn border-btn',
      event: closeDeleteDialog
    },
    {
      name: 'Delete',
      className: 'primary-btn',
      event: deleteDraftHandler
    }
  ];

  const columns = [
    { field: 'brokerageOfficeName', headerName: 'Brokerage office', flex: 1, minWidth: 280 },
    {
      field: 'subjectLine',
      headerName: 'Subject Line',
      flex: 1,
      minWidth: 280,
      sortable: false
    },
    {
      field: 'pdfLink',
      headerName: 'PDF Link',
      flex: 1,
      minWidth: 180,
      headerClassName: 'has-link',
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="pdf-wrapper">
            <OpenFileInNewTab
              fileUrl={params.row.IABS}
              fileName={
                <em>
                  <img src={pdfIcon} alt="pdf icon" />
                  View PDF
                </em>
              }
            />
          </div>
        );
      }
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      flex: 1,
      minWidth: 230
    },
    {
      field: 'type',
      headerName: 'Brokerage office type',
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: (params) => {
        const currentItem = params.row; // Access the current row data
        const type =
          currentItem.brokerageOfficeType === 1 ? 'Multi-Commercial Family' : 'Single Family';
        return <div style={{ fontSize: '14px' }}>{type}</div>;
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      minWidth: 150,
      maxWidth: 150,
      headerClassName: 'action-col',
      cellClassName: 'action-col',
      sortable: false,
      headerAlign: 'right',
      align: 'right',
      renderCell: (params) => {
        return (
          <div className="action-column-wrapper">
            {role?.name !== ROLE.ADMIN && (
              <>
                <div className="action-column-inner">
                  <Button
                    onClick={() => editActionHandler(params)}
                    className="edit-icon"
                    name={<img src={editAction} alt="edit-icon" />}
                    title="edit"
                  />
                </div>
                <div className="action-column-inner">
                  <Button
                    title="Delete"
                    className="delete-icon"
                    onClick={() => openDeleteDialog(params)}
                    name={<img src={deleteAction} alt="delete-icon" />}
                  />
                </div>
              </>
            )}
          </div>
        );
      }
    }
  ];
  const sortingChangeHandler = (sortModelData) => {
    if (sortModelData && sortModelData.length > 0) {
      const sortQueryData = {
        ...queryData,
        sortField: sortModelData[0].field,
        sortDirection: sortModelData[0].sort
      };
      setQueryData(sortQueryData);
      dispatch(draftEmailBlastList(sortQueryData));
    }
  };

  const paginationChangeHandler = (paginationModelData) => {
    const paginationQueryData = {
      ...queryData,
      page: paginationModelData.page + 1,
      perPage: paginationModelData.pageSize
    };
    setQueryData(paginationQueryData);
    dispatch(draftEmailBlastList(paginationQueryData));
  };

  // Search users based on timeout seconds
  const handleSearch = debounce((value) => {
    if (value === '') {
      delete queryData.search;
      dispatch(draftEmailBlastList(queryData));
    } else {
      setSearch(value);
    }
  }, DEBOUNCE_TIMEOUT);
  const searchHandler = () => {
    const searchQueryData = {
      ...queryData,
      search: search
    };
    setQueryData(searchQueryData);
    dispatch(draftEmailBlastList(searchQueryData));
  };
  useEffect(() => {
    if (search !== '') {
      searchHandler();
    }
  }, [search]);

  // brokerage office filter
  const brokerageOfficeChangeHandler = () => {
    const brokerageOfficeQueryData = {
      ...queryData,
      brokerageOfficeId: brokerageOffice
    };
    setQueryData(brokerageOfficeQueryData);
    dispatch(draftEmailBlastList(brokerageOfficeQueryData));
  };

  useEffect(() => {
    if (brokerageOffice !== '' && brokerageOffice !== ALL_FILTERS) {
      brokerageOfficeChangeHandler();
    } else if (brokerageOffice === ALL_FILTERS && !isInitialRender) {
      // delete brokerage office and fetch data for all brokerage office
      delete queryData.brokerageOfficeId;
      dispatch(draftEmailBlastList(queryData));
    }
  }, [brokerageOffice]);

  return (
    <>
      <div className="page-inner email-blast-page-inner">
        <div className="page-content-block">
          <div className="inner-page-title-wrapper">
            <div className="title-left-block">
              <BreadCrumb links={breadcrumbsLinks} />
              <Label variant="h1" label={LABELS.DRAFT_EMAIL_BLAST} />
            </div>
          </div>
          <div className="white-box shadow-box page-table-wrapper">
            {isLoading && <PageLoader />}
            <div className="filter-wrapper">
              <div className="filter-left-block">
                <TextField
                  id="search"
                  label="Search"
                  type="search"
                  placeholder="Search here..."
                  name="search"
                  className="form-group search-field"
                  onChange={(e) => handleSearch(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
              </div>
              <div className="filter-right-block">
                <div className="dropdown-wrapper has-large-dropdown">
                  <SingleSelect
                    placeholderText="Brokerage Office"
                    items={brokerageOfficeOptions}
                    label="Brokerage Office"
                    className="form-group search-field"
                    MenuProps={{ classes: { paper: 'custom-select-dropdown' } }}
                    name="brokerageOffice"
                    onChange={(e) => setBrokerageOffice(e.target.value)}
                    value={brokerageOffice}
                  />
                </div>
              </div>
            </div>
            <div className="table-wrapper no-checkbox">
              <DataTable
                columns={columns}
                rows={draftEmailBlastData}
                rowHeight={56}
                handleSorting={sortingChangeHandler}
                sortingMode="server"
                paginationMode="server"
                handlePagination={paginationChangeHandler}
                total={paginationData && paginationData.total ? paginationData.total : 0}
              />
            </div>
          </div>
        </div>
      </div>
      <ConfirmDialog
        open={openDialog}
        onClose={closeDeleteDialog}
        dialogIcon={dialogDeleteIcon}
        dialogTitle={DIALOG.DELETE_DRAFT_DIALOG_TITLE}
        dialogBody={DIALOG.DELETE_DIALOG_BODY}
        buttons={buttonsList}
      />
    </>
  );
};
