export const API_ROUTES = {
  ADD_USER: '/users',
  GET_PROFILE: '/users/info',
  UPDATE_PROFILE: '/profile',
  GET_USERS: '/users',
  UPDATE_DELETE_USERS: '/users/:id',
  USER_BULK_ACTIONS: '/users/bulkAction',
  GET_USER_ROLES: '/roles',
  GET_SETTINGS: '/settings',
  CREATE_BROKERAGE_OFFICE: '/brokerage-offices',
  GET_BROKERAGE_OFFICES: '/brokerage-offices',
  LIST_BROKERAGE_OFFICES: '/brokerage-offices/list',
  GET_BROKERAGE_OFFICE_REALTORS: '/brokerage-offices/:id/realtors',
  GET_USERS_BROKERAGE_OFFICE_BLAST_PERMISSIONS: '/users/:id/blast/brokerageOffices',
  GET_BROKERAGE_OFFICE_DETAIL: '/brokerage-offices/:id',
  GENERATE_PRESIGNED_URL: '/generate-presigned-url',
  UPDATE_BROKERAGE_OFFICES: '/brokerage-offices/:id',
  DELETE_BROKERAGE_OFFICE: '/brokerage-offices/:id',
  GET_MEMBERS: '/members',
  GET_USER_BROKERAGE_OFFICE: '/users/brokerageOffices',
  VALIDATE_ADDRESS: '/properties/address/validate',
  GET_PROPERTIES: '/properties',
  CREATE_PROPERTY: '/properties',
  DELETE_PROPERTY: '/properties/:id',
  GET_PROPERTY_DETAIL: '/properties/:id',
  UPDATE_PROPERTY: '/properties/:id',
  ADD_PROPERTY_TAG_REQUEST: '/properties/:id/tag/transfer',
  GET_PROPERTY_TAG_REQUEST: '/properties/tag-transfer-requests',
  PROPERTY_TAG_REQUEST_ACTION: '/properties/tag-transfer-requests/:id',
  GET_PROPERTY_HISTORY: '/properties/:id/history',
  GET_ROLE_BASED_USER: '/users/roles',
  GET_RELATIONSHIP_HISTORY: '/properties/:id/relationship/history',
  REASSIGNED_PROPERTY_TAG: '/properties/:id/realtor',
  GET_PROPERTY_NOTIFICATIONS: '/properties/notifications',
  REASSIGN_PROPERTY_TAG_ACTION: '/properties/:id/actions',
  GET_DASHBOARD_DATA: '/dashboard/statistics',
  CREATE_EMAIL_BLAST: '/email-blast',
  GET_EMAIL_BLAST: '/email-blast',
  GET_EMAIL_BLAST_DETAIL: '/email-blast/:id',
  GENERATE_PRESIGNED_URLS: '/generate-presigned-urls',
  GET_REALTOR_PROPERTY_LIST: '/users/properties',
  DRAFT_EMAIL_BLAST: '/email-blast/draft',
  EDIT_DRAFT_EMAIL_BLAST: '/email-blast/draft/:id',
  DELETE_DRAFT_EMAIL_BLAST: '/email-blast/draft/:id'
};
