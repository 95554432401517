import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';

const poolData = {
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID
};

const userPool = new CognitoUserPool(poolData);
export default userPool;

export const getCognitoUser = (email) => {
  return new CognitoUser({
    Username: email,
    Pool: userPool
  });
};
