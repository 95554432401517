import * as Yup from 'yup';

export const addEmailBlastSchema = Yup.object({
  brokerageOffice: Yup.number()
    .required()
    .label('Brokerage office')
    .typeError('Brokerage Office must be a valid number'),
  subjectLine: Yup.string().required().label('Subject line'),
  description: Yup.string().required().label('Description'),
  noOfBedrooms: Yup.number()
    .required()
    .label('Bedroom')
    .typeError('Bedroom must be a valid number'),
  noOfBathrooms: Yup.number()
    .required()
    .label('Bathroom')
    .typeError('Bathroom must be a valid number'),
  garageSpace: Yup.number()
    .required()
    .label('Garage Space')
    .typeError('Garage space must be a valid number'),
  squreFootage: Yup.number().required().label('SqFt').typeError('SqFt must be a valid number'),
  buildYear: Yup.number()
    .required()
    .label('Built year')
    .typeError('Built year must be a valid number'),
  afterRepairPrice: Yup.string()
    .required()
    .label('Repaired price')
    .matches(/^[0-9,]+$/, 'Repaired price must be a valid number'),
  askingPrice: Yup.string()
    .required()
    .label('Asking price')
    .matches(/^[0-9,]+$/, 'Asking price must be a valid number'),
  estimateEquityCapture: Yup.string()
    .required()
    .label('Estimated equity')
    .matches(/^[0-9,]+$/, 'Estimated equity must be a valid number'),
  returnOnEquityCapture: Yup.string()
    .required()
    .label('Return on Equity Capture')
    .typeError('Return on Equity Capture must be a valid number'),
  estimateCashPerMonth: Yup.string()
    .required()
    .label('Estimated cash')
    .matches(/^[0-9,]+$/, 'Estimated cash must be a valid number'),
  estimateCashOnCashReturn: Yup.string()
    .required()
    .label('Estimated return cash')
    .typeError('Estimated return cash must be a valid number'),
  estimateOutOfPocket: Yup.string()
    .required()
    .label('Estimated out of pocket')
    .matches(/^[0-9,]+$/, 'Estimated out of pocket must be a valid number'),
  imageLimit: Yup.number(),
  file: Yup.mixed()
    .required()
    .label('Images')
    .test('file-length', 'Number of files exceeds the image limit', function (value) {
      const { imageLimit } = this.parent;
      return value && value.length <= imageLimit;
    })
    .test('type', 'Only the following formats are accepted: .jpeg, .jpg, .png', (value) => {
      return value.every((file) => {
        return (
          file &&
          (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg')
        );
      });
    })
});

export const addMultiCommercialEmailBlastSchema = Yup.object({
  brokerageOffice: Yup.number()
    .required()
    .label('Brokerage office')
    .typeError('Brokerage Office must be a valid number'),
  subjectLine: Yup.string().required().label('Subject line'),
  editorDescription: Yup.string().required().label('Description'),
  imageLimit: Yup.number(),
  file: Yup.mixed()
    .required()
    .label('Images')
    .test('file-length', 'Number of files exceeds the image limit', function (value) {
      const { imageLimit } = this.parent;
      return value && value.length <= imageLimit;
    })
    .test('type', 'Only the following formats are accepted: .jpeg, .jpg, .png', (value) => {
      return value.every((file) => {
        return (
          file &&
          (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg')
        );
      });
    })
});
