import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

/*
 Ref: https://mui.com/material-ui/react-typography/
 Default variantMapping: { h1: 'h1', h2: 'h2', h3: 'h3', h4: 'h4', h5: 'h5', h6: 'h6', subtitle1: 'h6', subtitle2: 'h6', body1: 'p', body2: 'p', inherit: 'p', }
*/
export const Label = ({
  className,
  label,
  variant = 'subtitle1',
  align = 'inherit',
  gutterBottom = false,
  ...restProps
}) => {
  return (
    <Typography
      className={className}
      variant={variant}
      align={align}
      gutterBottom={gutterBottom}
      {...restProps}>
      {label}
    </Typography>
  );
};

// Validation of provided Prop types.
Label.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  gutterBottom: PropTypes.bool,
  restProps: PropTypes.object,
  align: PropTypes.oneOf(['center', 'inherit', 'justify', 'left', 'right']),
  variant: PropTypes.oneOf([
    'body1',
    'body2',
    'button',
    'caption',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'inherit',
    'overline',
    'subtitle1',
    'subtitle2'
  ])
};
