import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { TextField } from '@mui/material';
import { InputAdornment } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Label } from '../../components/Label';
import { BreadCrumb } from '../../components/BreadCrumb';
import { SingleSelect } from '../../components/DropDown/SingleSelect';
import { Button } from '../../components/Button';
import { EmailPreviewDialog } from '../../components/Popup/EmailPreviewDialog';
import { FileUploader } from '../../components/FileUploader';
import { PageLoader } from '../../components/PageLoader';
import { addEmailBlast } from '../../redux/actions/realtor';
import { getRealtorBrokerageOfficeApi } from '../../redux/apis/realtor';
import { getSettingsApi } from '../../redux/apis/admin';
import { generatePresignedUrlsApi } from '../../redux/apis/commonApi';
import { store } from '../../redux/store';
import { getEmailBlastDetailApi } from '../../redux/apis/emailBlast';
import { manageEmailBlast } from '../../redux/slices/realtor';
import { dialogCloseIcon } from '../../constants/images';
import { ROUTE_LIST } from '../../constants/routes';
import {
  BREAD_CRUMB_LABELS,
  BROKERAGE_OFFICE_TYPE,
  DIALOG,
  EMAIL_BLAST_PAGE,
  ESTIMATE_OUT_OF_POCKET,
  ESTIMATE_OUT_OF_POCKET_TYPE,
  IMAGE_FILE_TYPE,
  LABELS,
  MESSAGES
} from '../../constants/common';
import {
  addEmailBlastSchema,
  addMultiCommercialEmailBlastSchema
} from '../../schemas/emailBlastSchema';
import { parseCurrency, uploadFiles } from '../../utils/helper';
import { ConfirmDialog } from '../../components/Popup/ConfirmDialog';
import { draftEmailBlast, editDraftEmailBlast } from '../../redux/actions/emailBlast';
import { toast } from 'react-toastify';
import { TextEditor } from '../../components/TextEditor';
import { MultiCommercialEmailPreviewDialog } from '../../components/Popup/MultiCommercialEmailPreviewDialg';

export const CreateAndCloneEmailBlast = ({ page }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreviewOpen, setImagePreviewOpen] = useState(false);
  const [emailPreviewOpen, setEmailPreviewOpen] = useState(false);
  const [brokerageOfficeOptions, setBrokerageOfficeOptions] = useState([]);
  const [isBlastAdded, setIsBlastAdded] = useState(false);
  const [isDraftAdded, setIsDraftAdded] = useState(false);
  const { isSucceed, isLoading } = useSelector((state) => state.apiStatus);
  const [propertyData, setPropertyData] = useState();
  const [settingsData, setSettingsData] = useState();
  const [emailBlastData, setEmailBlastData] = useState([]);
  const [brokerageOffice, setBrokerageOffice] = useState('');
  const { emailBlast } = useSelector((state) => state.realtor);
  const [newDescription, setNewDescription] = useState(''); // With \n for line break
  const [openEmailBlastDialog, setOpenEmailBlastDialog] = useState(false);
  const [checkedDescription, setCheckedDescription] = useState(true);
  const [selectedOption, setSelectedOption] = useState(ESTIMATE_OUT_OF_POCKET_TYPE.HARD);
  const [isTexasOffice, setIsTexasOffice] = useState(false);
  const [isMultiCommercialOffice, setIsMultiCommercialOffice] = useState(false);
  const [editorDescriptionContent, setEditorDescriptionContent] = useState('');
  const [brokerageOfficeType, setBrokerageOfficeType] = useState(0);
  const [selectedBrokerageOfficeId, setSelectedBrokerageOfficeId] = useState('');
  const [selectedSubjectLine, setSelectedSubjectLine] = useState('');

  const breadcrumbsLinks = [
    {
      label: BREAD_CRUMB_LABELS.DASHBOARD,
      to: ROUTE_LIST.DASHBOARD
    },
    {
      label: BREAD_CRUMB_LABELS.EMAIL_BLAST,
      to: ROUTE_LIST.EMAIL_BLAST
    },
    {
      label: BREAD_CRUMB_LABELS.CREATE_EMAIL_BLAST,
      last: true
    }
  ];

  useEffect(() => {
    store.dispatch(manageEmailBlast({ isEmailBlastAdded: false, isEmailBlastDraftAdded: false }));
    (async () => {
      const [BrokerageOfficeRes, settingRes] = await Promise.all([
        getRealtorBrokerageOfficeApi(), // API - /brokerageOffices
        getSettingsApi()
      ]);
      setBrokerageOfficeOptions(BrokerageOfficeRes.data.data);
      setSettingsData(settingRes.data.data);
      if (
        page === EMAIL_BLAST_PAGE.CLONE_EMAIL_BLAST ||
        page === EMAIL_BLAST_PAGE.EDIT_EMAIL_DRAFT
      ) {
        const emailBlastRes = await getEmailBlastDetailApi(id);
        setCheckedDescription(emailBlastRes.data.data.wholesaleDisclaimer);
        setEmailBlastData(emailBlastRes.data.data);
        setSelectedOption(emailBlastRes.data.data.estimateOutOfPocketType);
        setIsMultiCommercialOffice(emailBlastRes.data.data.brokerageOfficeType);
      }
    })();
  }, []);

  // Get image limit from settings
  const imageLimit = settingsData?.find((data) => data.name === 'email_max_image_limit');
  useEffect(() => {
    if (isSucceed && isBlastAdded && emailBlast?.isEmailBlastAdded) {
      navigate(ROUTE_LIST.EMAIL_BLAST);
    }
    if (isSucceed && isDraftAdded && emailBlast?.isEmailBlastDraftAdded) {
      navigate(ROUTE_LIST.DRAFT_EMAIL_BLAST);
    }
  }, [isBlastAdded, isSucceed, emailBlast, isDraftAdded]);

  useEffect(() => {
    if (emailBlastData?.description) {
      setNewDescription(emailBlastData?.description);
      setFieldValue('description', emailBlastData.description);
      setEditorDescriptionContent(emailBlastData?.description);
    }
  }, [emailBlastData?.description]);

  const initialValues = {
    brokerageOffice: selectedBrokerageOfficeId || emailBlastData.brokerageOfficeId || '',
    subjectLine: selectedSubjectLine || emailBlastData.subjectLine || '',
    wholesaleDisclaimer: emailBlastData?.wholesaleDisclaimer || '',
    description: emailBlastData.description || '',
    noOfBedrooms: emailBlastData.noOfBedrooms || '',
    noOfBathrooms: emailBlastData.noOfBathrooms || '',
    garageSpace: emailBlastData.garageSpace || '',
    squreFootage: emailBlastData.squreFootage || '',
    buildYear: emailBlastData.buildYear || '',
    afterRepairPrice: emailBlastData.afterRepairPrice || '',
    askingPrice: emailBlastData.askingPrice || '',
    estimateEquityCapture: emailBlastData.estimateEquityCapture || '',
    returnOnEquityCapture: emailBlastData.returnOnEquityCapture || '',
    estimateCashPerMonth: emailBlastData.estimateCashPerMonth || '',
    estimateCashOnCashReturn: emailBlastData.estimateCashOnCashReturn || '',
    estimateOutOfPocketType: emailBlastData?.estimateOutOfPocketType || '',
    estimateOutOfPocket: emailBlastData.estimateOutOfPocket || '',
    file: [],
    imageLimit: '',
    draftEmailBlastId: id || '',
    brokerageOfficeType: emailBlastData.brokerageOfficeType || ''
  };

  const multiCommercialInitialValues = {
    ...initialValues,
    editorDescription: emailBlastData.description || ''
  };

  const { values, errors, handleChange, handleBlur, touched, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: isMultiCommercialOffice ? multiCommercialInitialValues : initialValues,
      validationSchema: isMultiCommercialOffice
        ? addMultiCommercialEmailBlastSchema
        : addEmailBlastSchema,
      enableReinitialize: true,
      onSubmit: async () => {
        setOpenEmailBlastDialog(true);
      }
    });

  useEffect(() => {
    setFieldValue('imageLimit', imageLimit?.value);
  }, [imageLimit, values?.file]);

  // Save Email Draft
  const saveEmailDraft = async () => {
    if (page === EMAIL_BLAST_PAGE.CREATE_EMAIL_BLAST && brokerageOffice == null) {
      toast.error(MESSAGES.BROKERAGE_OFFICE_IS_NECESSARY);
    }
    if (page === EMAIL_BLAST_PAGE.EDIT_EMAIL_DRAFT) {
      if (isMultiCommercialOffice) {
        dispatch(
          editDraftEmailBlast(id, {
            ...values,
            editorDescription: editorDescriptionContent,
            brokerageOfficeType
          })
        );
      } else {
        dispatch(
          editDraftEmailBlast(id, {
            ...values,
            newDescription: newDescription,
            brokerageOfficeType
          })
        );
      }
    } else if (isMultiCommercialOffice) {
      dispatch(
        draftEmailBlast({
          ...values,
          editorDescription: editorDescriptionContent,
          brokerageOfficeType
        })
      );
    } else {
      dispatch(
        draftEmailBlast({
          ...values,
          newDescription: newDescription,
          brokerageOfficeType
        })
      );
    }

    setIsDraftAdded(true);
  };

  // Email preview dialog
  const openEmailPreviewDialog = () => {
    setEmailPreviewOpen(true);
    setPropertyData({
      ...values,
      brokerageOfficeName: brokerageOffice?.name,
      brokerageOfficeLogo: brokerageOffice?.logo
    });
  };

  useEffect(() => {
    if (brokerageOfficeType === Number(isMultiCommercialOffice)) {
      setSelectedBrokerageOfficeId(values.brokerageOffice);
      setSelectedSubjectLine(values.subjectLine);
    }
  }, [values.brokerageOffice, values.subjectLine]);

  //Hide and show Texas office and Multifamily & Commercial office viceVersa in preview dialog and borkerage office selection respectively
  useEffect(() => {
    if (brokerageOffice) {
      const texasOffice = brokerageOfficeOptions.find((office) => {
        return office.id === brokerageOffice.id && office.isTexasBrokerageOffice === true;
      });
      const multiCommercialOffice = brokerageOfficeOptions.find((office) => {
        return office.id === brokerageOffice.id && office.isMultifamilyAndCommercial === true;
      });
      if (texasOffice) {
        setIsTexasOffice(true);
        setIsMultiCommercialOffice(false);
        setBrokerageOfficeType(BROKERAGE_OFFICE_TYPE.SINGLE_FAMILY);
      } else if (multiCommercialOffice) {
        setIsTexasOffice(false);
        setIsMultiCommercialOffice(true);
        setBrokerageOfficeType(BROKERAGE_OFFICE_TYPE.MULTI_FAMILY_AND_COMMERCIAL);
      } else {
        setIsTexasOffice(false);
        setIsMultiCommercialOffice(false);
        setBrokerageOfficeType(BROKERAGE_OFFICE_TYPE.SINGLE_FAMILY);
      }
    }
  }, [brokerageOfficeOptions, brokerageOffice]);

  const closeEmailPreviewDialog = () => {
    setEmailPreviewOpen(false);
  };

  // Added for file upload
  const fileChangeHandler = () => {
    setSelectedFile(true);
    let inp = document.getElementById('input-file-upload');
    let fileArr = [];
    for (let i = 0; i < inp.files.length; i++) {
      fileArr.push(inp.files.item(i));
    }
    setFiles([...files, ...fileArr]);
  };

  useEffect(() => {
    setFieldValue('file', files);
  }, [files]);

  const handleDelete = (fileName) => {
    const filteredFileData = files?.filter((file) => file.name !== fileName);
    setFiles(filteredFileData);
  };

  // Get selected brokerage office name and logo for mail preview dialog
  useEffect(() => {
    const officeName = brokerageOfficeOptions.find(
      (office) => office.id === values.brokerageOffice
    );
    setBrokerageOffice(officeName);
  }, [values.brokerageOffice]);

  const closeSendEmailDialog = () => {
    setOpenEmailBlastDialog(false);
  };

  const sendBlastHandler = async () => {
    setOpenEmailBlastDialog(false);
    const files = values.file;
    // Api call for generate presigned url
    const presignedUrlData = await generatePresignedUrlsApi({
      files: files,
      imageType: IMAGE_FILE_TYPE.EMAIL_BLAST_FILES
    });
    const presignedUrls = presignedUrlData?.data.data;
    // upload all files on s3
    const fileNameArr = await uploadFiles(presignedUrls, files);
    delete values.file;
    if (isMultiCommercialOffice) {
      dispatch(
        addEmailBlast({
          ...values,
          uploadedFiles: fileNameArr,
          editorDescription: editorDescriptionContent,
          brokerageOfficeType
        })
      );
    } else {
      dispatch(
        addEmailBlast({
          ...values,
          uploadedFiles: fileNameArr,
          newDescription: newDescription,
          brokerageOfficeType
        })
      );
    }
    setIsBlastAdded(true);
  };

  const blastButtonList = [
    {
      name: 'Cancel',
      className: 'primary-btn border-btn',
      event: closeSendEmailDialog
    },
    {
      name: 'Confirm',
      className: 'primary-btn',
      event: sendBlastHandler
    }
  ];

  // select Brokerage office can auto insert its value to subject line
  const brokerageOfficeHandleChange = (event) => {
    event.preventDefault();
    const selectedOptionId = event.target.value;
    const selectedBrokerage = brokerageOfficeOptions.find((res) => res.id == selectedOptionId);
    setFieldValue('subjectLine', selectedBrokerage.name);
    setFieldValue('brokerageOffice', selectedOptionId);
  };

  useEffect(() => {
    if (brokerageOfficeOptions.length === 1) {
      setFieldValue('brokerageOffice', brokerageOfficeOptions[0].id);
      setFieldValue('subjectLine', brokerageOfficeOptions[0].name);
    }
  }, [brokerageOfficeOptions]);

  // Disclaimer checkbox
  const handleDisclaimerCheckboxChange = (event) => {
    handleChange(event);
    setCheckedDescription(event.target.checked);
  };

  // hard or convenience
  const outOfPocketHandler = (event) => {
    handleChange(event);
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    if (!isMultiCommercialOffice) {
      setFieldValue('estimateOutOfPocketType', selectedOption ?? ESTIMATE_OUT_OF_POCKET_TYPE.HARD);
      setFieldValue('wholesaleDisclaimer', Number(checkedDescription) ?? 1);
    }
  }, [
    selectedOption,
    checkedDescription,
    isMultiCommercialOffice,
    values.estimateOutOfPocketType,
    values.wholesaleDisclaimer
  ]);

  //Common Radio check
  const outOfPocketEstimate =
    Number(selectedOption) === ESTIMATE_OUT_OF_POCKET_TYPE.HARD
      ? ESTIMATE_OUT_OF_POCKET.HARD
      : ESTIMATE_OUT_OF_POCKET.CONV;

  // Return on equity capture calculation handlechange
  useEffect(() => {
    try {
      if (
        !isMultiCommercialOffice &&
        parseCurrency(values.estimateEquityCapture) &&
        parseCurrency(values.estimateOutOfPocket) &&
        parseCurrency(values.estimateOutOfPocket) !== 0
      ) {
        const totalReturn = Math.round(
          (parseCurrency(values.estimateEquityCapture) /
            parseCurrency(values.estimateOutOfPocket)) *
            100
        );
        setFieldValue('returnOnEquityCapture', totalReturn.toString());
      } else {
        setFieldValue('returnOnEquityCapture', '0');
      }
    } catch (error) {
      console.log('error:', error);
    }
  }, [values.estimateEquityCapture, values.estimateOutOfPocket]);

  // estimated Cash Flow PerMonth calculation handlechange
  useEffect(() => {
    try {
      if (
        !isMultiCommercialOffice &&
        parseCurrency(values.estimateCashPerMonth) &&
        parseCurrency(values.estimateOutOfPocket) &&
        parseCurrency(values.estimateOutOfPocket) !== 0
      ) {
        const totalCashFlow =
          ((parseCurrency(values.estimateCashPerMonth) * 12) /
            parseCurrency(values.estimateOutOfPocket)) *
          100;
        setFieldValue('estimateCashOnCashReturn', totalCashFlow.toFixed(1).toString());
      } else {
        setFieldValue('estimateCashOnCashReturn', '0');
      }
    } catch (error) {
      console.log('error:', error);
    }
  }, [values.estimateCashPerMonth, values.estimateOutOfPocket]);

  // Changing input field for only numbers and commas
  const handleRegExInputChange = (e, fieldName) => {
    let value = e.target.value;
    if (value.match(/^[0-9,]*$/)) {
      setFieldValue(fieldName, value);
    }
  };

  // Add text editor for description
  const handleDescriptionContentChange = (content) => {
    if (isMultiCommercialOffice === true) {
      handleChange(content);
      setEditorDescriptionContent(content);
      setFieldValue('editorDescription', content);
    }
  };

  useEffect(() => {
    setFieldValue('description', editorDescriptionContent);
  }, [editorDescriptionContent]);

  return (
    <>
      <div className="page-inner email-blast-page-inner">
        <div className="page-content-block">
          <div className="inner-page-title-wrapper">
            <div className="title-left-block">
              <BreadCrumb links={breadcrumbsLinks} />
              {page === EMAIL_BLAST_PAGE.CREATE_EMAIL_BLAST ||
              page === EMAIL_BLAST_PAGE.EDIT_EMAIL_DRAFT ? (
                <Label variant="h1" label={LABELS.CREATE_EMAIL_BLAST} />
              ) : (
                <Label variant="h1" label={LABELS.CLONE_EMAIL_BLAST} />
              )}
            </div>
          </div>
          <div className="white-box shadow-box">
            {isLoading && <PageLoader />}
            <form id="form-file-upload" className="create-clone-email" onSubmit={handleSubmit}>
              <div className="form-row-wrapper">
                <div className="form-col has-required">
                  <SingleSelect
                    placeholderText="Select Brokerage Office"
                    items={brokerageOfficeOptions}
                    label="Brokerage Office"
                    MenuProps={{ classes: { paper: 'custom-select-dropdown' } }}
                    name="brokerageOffice"
                    id="brokerageOffice"
                    onChange={brokerageOfficeHandleChange}
                    onBlur={handleBlur}
                    value={values.brokerageOffice}
                    error={touched.brokerageOffice && Boolean(errors.brokerageOffice)}
                    helperText={touched.brokerageOffice && errors.brokerageOffice}
                  />
                </div>
                <div className="form-col has-required">
                  <TextField
                    fullWidth
                    id="subjectLine"
                    label="Subject Line"
                    placeholder="Subject Line"
                    name="subjectLine"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.subjectLine}
                    error={touched.subjectLine && Boolean(errors.subjectLine)}
                    helperText={touched.subjectLine && errors.subjectLine}
                    className="form-group"
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                {!isMultiCommercialOffice ? (
                  <>
                    <div className="disclaimer-checkbox form-col">
                      <input
                        id="wholesaleDisclaimer"
                        name="wholesaleDisclaimer"
                        type="checkbox"
                        onChange={handleDisclaimerCheckboxChange}
                        value={values.wholesaleDisclaimer}
                        checked={values.wholesaleDisclaimer}
                      />
                      <label htmlFor="wholesaleDisclaimer">Wholesale Disclaimer</label>
                    </div>
                    <div className="form-col full-width  has-required">
                      {checkedDescription && (
                        <div className="disclaimer-note">
                          NOTE: The original buyer is selling an option or assigning an interest in
                          a contract, for a fee, and does not have the legal title to the property.
                        </div>
                      )}
                      <label className="extra-small-width editor-has-required">Description</label>
                      <TextEditor
                        id="description"
                        name="description"
                        label="Description"
                        placeholder="Description"
                        className="form-group full-width textarea-editor"
                        value={values.description ?? ''}
                        onEditorChange={(event) => {
                          handleChange(event);
                          setNewDescription(event);
                          setFieldValue('description', event);
                        }}
                      />
                    </div>
                    <div className="form-col extra-small-width has-required">
                      <TextField
                        fullWidth
                        id="bedRoom"
                        label="Bedrooms"
                        placeholder="Bedrooms"
                        name="noOfBedrooms"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.noOfBedrooms}
                        error={touched.noOfBedrooms && Boolean(errors.noOfBedrooms)}
                        helperText={touched.noOfBedrooms && errors.noOfBedrooms}
                        className="form-group"
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                    <div className="form-col extra-small-width has-required">
                      <TextField
                        fullWidth
                        id="bathRoom"
                        label="Bathrooms"
                        placeholder="Bathrooms"
                        name="noOfBathrooms"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.noOfBathrooms}
                        error={touched.noOfBathrooms && Boolean(errors.noOfBathrooms)}
                        helperText={touched.noOfBathrooms && errors.noOfBathrooms}
                        className="form-group"
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                    <div className="form-col extra-small-width has-required">
                      <TextField
                        fullWidth
                        id="garageSpace"
                        label="Garage"
                        placeholder="Garage"
                        name="garageSpace"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.garageSpace}
                        error={touched.garageSpace && Boolean(errors.garageSpace)}
                        helperText={touched.garageSpace && errors.garageSpace}
                        className="form-group suffix"
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                    <div className="form-col extra-small-width has-required">
                      <TextField
                        fullWidth
                        id="squreFootage"
                        label="Square Footage"
                        placeholder="Square Footage"
                        name="squreFootage"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.squreFootage}
                        error={touched.squreFootage && Boolean(errors.squreFootage)}
                        helperText={touched.squreFootage && errors.squreFootage}
                        className="form-group suffix"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">SqFt</InputAdornment>
                        }}
                      />
                    </div>
                    <div className="form-col extra-small-width has-required">
                      <TextField
                        fullWidth
                        id="buildYear"
                        label="Year Built"
                        placeholder="Year Built"
                        name="buildYear"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.buildYear}
                        error={touched.buildYear && Boolean(errors.buildYear)}
                        helperText={touched.buildYear && errors.buildYear}
                        className="form-group"
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                    <div className="form-col medium-small-width has-required">
                      <TextField
                        fullWidth
                        id="afterRepairPrice"
                        label="After Repair Price Opinion"
                        placeholder="After Repair Price Opinion"
                        name="afterRepairPrice"
                        onChange={(e) => handleRegExInputChange(e, 'afterRepairPrice')}
                        onBlur={handleBlur}
                        value={values.afterRepairPrice}
                        error={touched.afterRepairPrice && Boolean(errors.afterRepairPrice)}
                        helperText={touched.afterRepairPrice && errors.afterRepairPrice}
                        className="form-group prefix"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                      />
                    </div>
                    <div className="form-col medium-small-width has-required">
                      <TextField
                        fullWidth
                        id="askingPrice"
                        label="Asking Price"
                        placeholder="Asking Price"
                        name="askingPrice"
                        onChange={(e) => handleRegExInputChange(e, 'askingPrice')}
                        onBlur={handleBlur}
                        value={values.askingPrice}
                        error={touched.askingPrice && Boolean(errors.askingPrice)}
                        helperText={touched.askingPrice && errors.askingPrice}
                        className="form-group prefix"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                      />
                    </div>
                    <div className="form-col medium-small-width has-required">
                      <TextField
                        fullWidth
                        id="estimateEquityCapture"
                        label="Estimated Equity Capture"
                        placeholder="Estimated Equity Capture"
                        name="estimateEquityCapture"
                        onChange={(e) => handleRegExInputChange(e, 'estimateEquityCapture')}
                        onBlur={handleBlur}
                        value={values.estimateEquityCapture}
                        error={
                          touched.estimateEquityCapture && Boolean(errors.estimateEquityCapture)
                        }
                        helperText={touched.estimateEquityCapture && errors.estimateEquityCapture}
                        className="form-group prefix"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                      />
                    </div>
                    <div className="form-col medium-small-width has-required">
                      <TextField
                        fullWidth
                        id="estimateCashPerMonth"
                        label="Estimated Cash Flow per Month"
                        placeholder="Estimated Cash Flow per Month"
                        name="estimateCashPerMonth"
                        onChange={(e) => handleRegExInputChange(e, 'estimateCashPerMonth')}
                        onBlur={handleBlur}
                        value={values.estimateCashPerMonth}
                        error={touched.estimateCashPerMonth && Boolean(errors.estimateCashPerMonth)}
                        helperText={touched.estimateCashPerMonth && errors.estimateCashPerMonth}
                        className="form-group prefix"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                      />
                    </div>
                    <div className="estimateOutOfPocket ">
                      <div className="outofpocket-radio ">
                        <label htmlFor="hardpocketradio">
                          <input
                            id="hardpocketradio"
                            name="pocketradio"
                            type="radio"
                            value={ESTIMATE_OUT_OF_POCKET_TYPE.HARD}
                            checked={Number(selectedOption) === ESTIMATE_OUT_OF_POCKET_TYPE.HARD}
                            onChange={outOfPocketHandler}
                          />
                          Hard
                        </label>
                        <label htmlFor="convpocketradio">
                          <input
                            id="convpocketradio"
                            name="pocketradio"
                            type="radio"
                            value={ESTIMATE_OUT_OF_POCKET_TYPE.CONV}
                            checked={Number(selectedOption) === ESTIMATE_OUT_OF_POCKET_TYPE.CONV}
                            onChange={outOfPocketHandler}
                          />
                          Conventional
                        </label>
                      </div>
                      {(selectedOption ||
                        Number(selectedOption) === ESTIMATE_OUT_OF_POCKET_TYPE.CONV) && (
                        <div className="full-width form-col has-required">
                          <TextField
                            fullWidth
                            id="estimateOutOfPocket"
                            label={`Estimated Out of Pocket (${outOfPocketEstimate})`}
                            placeholder={`Estimated Out of Pocket (${outOfPocketEstimate})`}
                            name="estimateOutOfPocket"
                            onChange={(e) => handleRegExInputChange(e, 'estimateOutOfPocket')}
                            onBlur={handleBlur}
                            value={values.estimateOutOfPocket}
                            error={
                              touched.estimateOutOfPocket && Boolean(errors.estimateOutOfPocket)
                            }
                            helperText={touched.estimateOutOfPocket && errors.estimateOutOfPocket}
                            className="form-group prefix"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className="form-col medium-small-width has-required">
                      <TextField
                        fullWidth
                        readOnly
                        id="returnOnEquityCapture"
                        label="Return on Equity Capture"
                        placeholder="Return on Equity Capture"
                        name="returnOnEquityCapture"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.returnOnEquityCapture}
                        error={
                          touched.returnOnEquityCapture && Boolean(errors.returnOnEquityCapture)
                        }
                        helperText={touched.returnOnEquityCapture && errors.returnOnEquityCapture}
                        className="form-group suffix"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>
                        }}
                      />
                    </div>
                    <div className="form-col medium-small-width has-required">
                      <TextField
                        fullWidth
                        readOnly
                        id="estimateCashOnCashReturn"
                        label="Estimated Cash on Cash Return"
                        placeholder="Estimated Cash on Cash Return"
                        name="estimateCashOnCashReturn"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.estimateCashOnCashReturn}
                        error={
                          touched.estimateCashOnCashReturn &&
                          Boolean(errors.estimateCashOnCashReturn)
                        }
                        helperText={
                          touched.estimateCashOnCashReturn && errors.estimateCashOnCashReturn
                        }
                        className="form-group suffix"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <label className="form-col extra-small-width editor-has-required">
                      Description
                    </label>
                    <TextEditor
                      id="editorDescription"
                      name="editorDescription"
                      className=" form-col full-width text-editor"
                      value={editorDescriptionContent}
                      onEditorChange={handleDescriptionContentChange}
                    />
                  </>
                )}
                <div className="form-col full-width">
                  <FileUploader
                    handleChange={fileChangeHandler}
                    setImagePreviewOpen={setImagePreviewOpen}
                    imagePreviewOpen={imagePreviewOpen}
                    selectedFile={selectedFile}
                    files={files}
                    multiple={true}
                    label="Upload Images"
                    handleDelete={(fileName) => handleDelete(fileName)}
                    name="file"
                    onBlur={handleBlur}
                    error={touched.file && Boolean(errors.file)}
                    helperText={touched.file && errors.file}
                  />
                </div>
              </div>
              <div className="form-btn-wrapper two-btns">
                <Button
                  className="primary-btn border-btn float-left"
                  name="Preview"
                  onClick={openEmailPreviewDialog}
                />
                <Button
                  className="primary-btn border-btn float-left"
                  name="Save Draft"
                  onClick={saveEmailDraft}
                />
                <Button className="primary-btn" type="submit" name="Send Email Blast" />
              </div>
            </form>
          </div>
        </div>
      </div>
      {isMultiCommercialOffice ? (
        <MultiCommercialEmailPreviewDialog
          open={emailPreviewOpen}
          dialogIcon={dialogCloseIcon}
          onClose={closeEmailPreviewDialog}
          handleClose={closeEmailPreviewDialog}
          isViewOnly={true}
          propertyData={{
            ...propertyData,
            editorDescription: editorDescriptionContent,
            brokerageOfficeType
          }}
        />
      ) : (
        <EmailPreviewDialog
          open={emailPreviewOpen}
          dialogIcon={dialogCloseIcon}
          onClose={closeEmailPreviewDialog}
          handleClose={closeEmailPreviewDialog}
          isViewOnly={true}
          propertyData={{
            ...propertyData,
            officeExist: isTexasOffice,
            newDescription: newDescription,
            brokerageOfficeType
          }}
        />
      )}
      <ConfirmDialog
        open={openEmailBlastDialog}
        onClose={closeSendEmailDialog}
        dialogTitle={DIALOG.SEND_EMAIL_BLAST_TITLE}
        dialogBody={DIALOG.SEND_EMAIL_BLAST_BODY}
        buttons={blastButtonList}
      />
    </>
  );
};

CreateAndCloneEmailBlast.propTypes = {
  page: PropTypes.oneOf([
    EMAIL_BLAST_PAGE.CREATE_EMAIL_BLAST,
    EMAIL_BLAST_PAGE.CLONE_EMAIL_BLAST,
    EMAIL_BLAST_PAGE.EDIT_EMAIL_DRAFT
  ])
};
