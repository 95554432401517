import { TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { BreadCrumb } from '../../components/BreadCrumb';
import { Button } from '../../components/Button';
import { AutoCompleteMultiSelect } from '../../components/DropDown/AutoCompleteMultiSelect';
import { FileUploader } from '../../components/FileUploader';
import { AutoCompleteSingleSelect } from '../../components/DropDown/AutoCompleteSingleSelect';
import { Label } from '../../components/Label';
import { PageLoader } from '../../components/PageLoader';
import { generatePresignedUrlApi, uploadFileApi } from '../../redux/apis/commonApi';
import { getBrokerageOfficeDetailApi } from '../../redux/apis/brokerageOffice';
import { getRoleBasedUsersApi } from '../../redux/apis/admin';
import { updateBrokerageOffice } from '../../redux/actions/admin';
import { store } from '../../redux/store';
import { manageBrokerageOffice } from '../../redux/slices/admin';
import {
  BREAD_CRUMB_LABELS,
  DEBOUNCE_TIMEOUT,
  IMAGE_FILE_TYPE,
  LABELS,
  REALTOR_QUERY_DATA,
  RESPONSE_STATUS_CODES,
  SALES_MANAGER_QUERY_DATA
} from '../../constants/common';
import { ROUTE_LIST } from '../../constants/routes';
import { addBrokerageOfficeSchema } from '../../schemas/adminSchema';
import { debounce } from '../../utils/helper';

export const EditBrokerageOffice = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [imagePreviewOpen, setImagePreviewOpen] = useState(false);
  const [realtorData, setRealtorData] = useState([]);
  const [realtorOptions, setRealtorOptions] = useState([]);
  const [realtorSearch, setRealtorSearch] = useState('');
  const [salesManagerData, setsalesManagerData] = useState();
  const [salesManagerSearch, setsalesManagerSearch] = useState('');
  const [salesManagerOptions, setSalesManagerOptions] = useState();
  const { brokerageOffice } = useSelector((state) => state.admin);
  const [selectedFile, setSelectedFile] = useState(true);
  const [files, setFiles] = useState([]);
  const [isPastLogoDeleted, setIsPastLogoDeleted] = useState(false); // Flag for logo image is changed or not
  const { isSucceed, isLoading, isfailed } = useSelector((state) => state.apiStatus);
  const [isEdited, setIsEdited] = useState(false);
  const [brokerageOfficeData, setBrokerageOfficeData] = useState();
  const [isTexasOffice, setIsTexasOffice] = useState(false);
  const [isMultiOrCommercialOffice, setIsMultiOrCommercialOffice] = useState(false);

  const breadcrumbsLinks = [
    {
      label: BREAD_CRUMB_LABELS.DASHBOARD,
      to: ROUTE_LIST.DASHBOARD
    },
    {
      label: BREAD_CRUMB_LABELS.BROKERAGE_OFFICE,
      to: ROUTE_LIST.BROKERAGE_OFFICE
    },
    {
      label: BREAD_CRUMB_LABELS.EDIT_BROKERAGE_OFFICE,
      last: true
    }
  ];

  useEffect(() => {
    // set edited flag false initially
    store.dispatch(manageBrokerageOffice({ isBrokerageOfficeEdited: false }));
    getBrokerageOfficeDetailApi({ brokerageOfficeId: id }).then((res) =>
      setBrokerageOfficeData(res.data.data)
    );
    // Get realtor and sales manager data for options
    getRoleBasedUsersApi(REALTOR_QUERY_DATA).then((res) => setRealtorData(res.data.data.userData));
    getRoleBasedUsersApi(SALES_MANAGER_QUERY_DATA).then((res) =>
      setsalesManagerData(res.data.data.userData)
    );
  }, []);

  useEffect(() => {
    if (brokerageOfficeData) {
      let fileName = brokerageOfficeData?.logo.split('/');
      fileName = fileName[fileName.length - 1];
      setFiles([
        {
          name: fileName,
          pathName: brokerageOfficeData?.logo,
          imageValid: true
        }
      ]);
    }
  }, [brokerageOfficeData]);

  // redirect to brokerage office list after editing brokerage office
  useEffect(() => {
    if (isSucceed && isEdited && brokerageOffice?.isBrokerageOfficeEdited)
      navigate(ROUTE_LIST.BROKERAGE_OFFICE);
  }, [isSucceed, isEdited, brokerageOffice]);

  // Added for file upload
  const fileChangeHandler = (e) => {
    setSelectedFile(true);
    const fileData = e.target.files[0];
    setFiles([fileData]);
    setIsPastLogoDeleted(true);
  };
  useEffect(() => {
    setFieldValue('file', files);
  }, [files, setSelectedFile, isPastLogoDeleted]);

  useEffect(() => {
    // realtor options
    setRealtorOptions(
      realtorData?.map((realtor) => {
        return {
          value: realtor.id,
          label: realtor.firstName + ' ' + realtor.lastName
        };
      })
    );
    // sales manager options
    setSalesManagerOptions(
      salesManagerData?.map((manager) => {
        return {
          value: manager.id,
          label: manager.firstName + ' ' + manager.lastName
        };
      })
    );
  }, [realtorData, salesManagerData]);

  // Search realtors based on timeout seconds
  const handleRealtorSearch = debounce((value) => {
    setRealtorSearch(value);
  }, DEBOUNCE_TIMEOUT);
  const realtorSearchHandler = () => {
    getRoleBasedUsersApi({
      ...REALTOR_QUERY_DATA,
      search: realtorSearch
    }).then((res) => setRealtorData(res.data.data.userData));
  };
  useEffect(() => {
    if (realtorSearch !== '' && realtorSearch !== 0) {
      realtorSearchHandler();
    }
  }, [realtorSearch]);

  // Search sales manager based on timeout seconds
  const handleSalesManagerSearch = debounce((value) => {
    setsalesManagerSearch(value);
  }, DEBOUNCE_TIMEOUT);
  const salesManagerSearchHandler = () => {
    getRoleBasedUsersApi({
      ...SALES_MANAGER_QUERY_DATA,
      search: salesManagerSearch
    }).then((res) => setsalesManagerData(res.data.data.userData));
  };
  useEffect(() => {
    if (salesManagerSearch !== '' && salesManagerSearch !== 0) {
      salesManagerSearchHandler();
    }
  }, [salesManagerSearch]);

  const initialValues = {
    name: brokerageOfficeData?.name || '',
    address: brokerageOfficeData?.address || '',
    zipCode: brokerageOfficeData?.zip || '',
    realtors: brokerageOfficeData?.realtors || [],
    salesManager: brokerageOfficeData?.salesManagerId || '',
    file: brokerageOfficeData?.logo || '',
    keapTagId: brokerageOfficeData?.tagId || '',
    isTexas: brokerageOfficeData?.isTexasBrokerageOffice || false,
    isMultiOrCommercial: brokerageOfficeData?.isMultifamilyAndCommercial || false
  };

  const { values, errors, handleChange, handleBlur, handleSubmit, touched, setFieldValue } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      validationSchema: addBrokerageOfficeSchema,
      onSubmit: async (values) => {
        // If logo is change then generate presigned url and dispatch update brokerage office actions
        if (isPastLogoDeleted) {
          const presingedUrlData = await generatePresignedUrlApi({
            file: values.file[0],
            imageType: IMAGE_FILE_TYPE.BROKERAGE_OFFICE_LOGO
          });
          if (presingedUrlData?.status === RESPONSE_STATUS_CODES.OK) {
            const presignedUrl = presingedUrlData?.data.data.split('?')[0];
            const uploadFile = await uploadFileApi({
              file: values.file[0],
              url: presingedUrlData.data.data
            });
            if (uploadFile?.status === RESPONSE_STATUS_CODES.OK) {
              const brokerageOfficeData = { ...values };
              delete brokerageOfficeData.file;
              dispatch(
                updateBrokerageOffice({
                  ...brokerageOfficeData,
                  isPastLogoDeleted: isPastLogoDeleted,
                  id: id,
                  presignedUrl
                })
              );
            }
          }
        } else {
          dispatch(
            updateBrokerageOffice({
              ...values,
              isPastLogoDeleted: isPastLogoDeleted,
              logo: brokerageOfficeData?.logo,
              id: id
            })
          );
        }

        if (!isfailed) setIsEdited(true);
      }
    });

  const isTexasHandler = (e) => {
    handleChange(e);
    setIsTexasOffice(true);
  };
  const isMultiOrCommercialHandler = (e) => {
    handleChange(e);
    setIsMultiOrCommercialOffice(true);
  };

  useEffect(() => {
    setFieldValue('isTexas', Boolean(isTexasOffice));
    setFieldValue('isMultiOrCommercial', Boolean(isMultiOrCommercialOffice));
  }, [isTexasOffice, isMultiOrCommercialOffice]);

  return (
    <>
      <div className="page-inner">
        <div className="page-content-block">
          <div className="inner-page-title-wrapper">
            <div className="title-left-block">
              <BreadCrumb links={breadcrumbsLinks} />
              <Label variant="h1" label={LABELS.EDIT_BROKERAGE_OFFICE} />
            </div>
          </div>
          <div className="white-box shadow-box">
            {isLoading && !brokerageOfficeData && <PageLoader />}
            {isLoading && isEdited && <PageLoader />}

            <form onSubmit={handleSubmit}>
              <div className="form-row-wrapper">
                <div className="form-col has-required">
                  <TextField
                    fullWidth
                    id="name"
                    label="Name"
                    placeholder="Name"
                    name="name"
                    className="form-group"
                    InputLabelProps={{ shrink: true }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </div>
                <div className="form-col">
                  <TextField
                    fullWidth
                    id="address"
                    label="Address"
                    placeholder="Address"
                    name="address"
                    className="form-group"
                    InputLabelProps={{ shrink: true }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address}
                    error={touched.address && Boolean(errors.address)}
                    helperText={touched.address && errors.address}
                  />
                </div>
                <div className="form-col">
                  <TextField
                    fullWidth
                    id="zipCode"
                    label="Zip Code"
                    placeholder="Zip Code"
                    name="zipCode"
                    className="form-group"
                    InputLabelProps={{ shrink: true }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.zipCode}
                    error={touched.zipCode && Boolean(errors.zipCode)}
                    helperText={touched.zipCode && errors.zipCode}
                  />
                </div>
                <div
                  className={
                    touched.realtors && Boolean(errors.realtors) ? 'form-col error' : 'form-col'
                  }>
                  <AutoCompleteMultiSelect
                    name="realtors"
                    className="multi-select"
                    filterOptions={(options) => options}
                    options={realtorOptions}
                    label="Realtors"
                    classes={{ paper: 'custom-select-dropdown multiselect-dropdown' }}
                    placeholder={values.realtors.length > 0 ? '' : 'Realtors'}
                    onChange={(event, value) => {
                      if (!value || value.length === 0) {
                        // Clear the options when a all selected options are cleared
                        getRoleBasedUsersApi(REALTOR_QUERY_DATA).then((res) =>
                          setRealtorData(res.data.data.userData)
                        );
                      }
                      setFieldValue('realtors', value);
                    }}
                    onBlur={handleBlur}
                    value={values.realtors}
                    error={touched.realtors && Boolean(errors.realtors)}
                    helperText={touched.realtors && errors.realtors}
                    onInputChange={(e, value, reason) => {
                      handleRealtorSearch(e.target.value);
                      if (reason === 'reset') {
                        getRoleBasedUsersApi(REALTOR_QUERY_DATA).then((res) =>
                          setRealtorData(res.data.data.userData)
                        );
                      }
                    }}
                  />
                </div>
                <div
                  className={
                    touched.salesManager && Boolean(errors.salesManager)
                      ? 'form-col has-required error'
                      : 'form-col has-required'
                  }>
                  <AutoCompleteSingleSelect
                    placeholder="Sales Manager"
                    filterOptions={(options) => options}
                    options={salesManagerOptions}
                    label="Sales Manager"
                    classes={{ paper: 'custom-select-dropdown multiselect-dropdown' }}
                    name="salesManager"
                    onChange={(event, value) => {
                      value
                        ? setFieldValue('salesManager', value?.value)
                        : setFieldValue('salesManager', '');
                    }}
                    onBlur={handleBlur}
                    value={values.salesManager}
                    error={touched.salesManager && Boolean(errors.salesManager)}
                    helperText={touched.salesManager && errors.salesManager}
                    onInputChange={(e, value) => {
                      if (value === '') {
                        getRoleBasedUsersApi(SALES_MANAGER_QUERY_DATA).then((res) =>
                          setsalesManagerData(res.data.data.userData)
                        );
                      }
                      handleSalesManagerSearch(value);
                    }}
                  />
                </div>
                <div className="form-col has-required">
                  <TextField
                    fullWidth
                    id="keapTagId"
                    label="Keap Brokerage Subscribe Tag Id"
                    placeholder="Tag Id"
                    name="keapTagId"
                    className="form-group"
                    InputLabelProps={{ shrink: true }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.keapTagId}
                    error={touched.keapTagId && Boolean(errors.keapTagId)}
                    helperText={touched.keapTagId && errors.keapTagId}
                  />
                </div>
                <div className="disclaimer-checkbox form-col">
                  <input
                    id="isTexas"
                    name="isTexas"
                    type="checkbox"
                    onChange={isTexasHandler}
                    value={values.isTexas}
                    checked={values.isTexas}
                  />
                  <label htmlFor="isTexas">Is Brokerage Office in Texas?</label>
                </div>
                <div className="disclaimer-checkbox form-col">
                  <input
                    id="isMultiOrCommercial"
                    name="isMultiOrCommercial"
                    type="checkbox"
                    onChange={isMultiOrCommercialHandler}
                    value={values.isMultiOrCommercial}
                    checked={values.isMultiOrCommercial}
                  />
                  <label htmlFor="isMultiOrCommercial">
                    Is Brokerage Office Multifamily or Commercial?
                  </label>
                </div>
                <div className="form-col full-width has-required">
                  <FileUploader
                    handleChange={fileChangeHandler}
                    setImagePreviewOpen={setImagePreviewOpen}
                    imagePreviewOpen={imagePreviewOpen}
                    selectedFile={selectedFile}
                    files={files}
                    label="Upload Logo"
                    handleDelete={() => {
                      setFiles([]);
                      setSelectedFile(false);
                      setIsPastLogoDeleted(true);
                    }}
                    name="file"
                    onBlur={handleBlur}
                    error={touched.file && Boolean(errors.file)}
                    helperText={touched.file && errors.file}
                  />
                </div>
              </div>
              <div className="form-btn-wrapper">
                <Button
                  className="primary-btn border-btn"
                  name="Cancel"
                  onClick={() => {
                    navigate(ROUTE_LIST.BROKERAGE_OFFICE);
                  }}
                />
                <Button
                  className="primary-btn"
                  type="submit"
                  name="Save"
                  isLoading={isLoading}
                  disabled={isLoading}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
