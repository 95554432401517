import * as Yup from 'yup';
import { ROLE } from '../constants/common';

export const addPropertySchema = Yup.object({
  address: Yup.string().required().label('Address'),
  city: Yup.string().required().label('City'),
  source: Yup.string().required().label('Source'),
  state: Yup.string().required().label('State'),
  stage: Yup.string().required().label('Stage'),
  note: Yup.string().required().label('Latest Note'),
  status: Yup.number().oneOf([0, 1]).required().label('Status'),
  brokerageOffice: Yup.number().required().label('Brokerage Office'),
  role: Yup.string(),
  realtor: Yup.number()
    .label('Realtor')
    .nullable()
    .when('role', ([role], schema) => {
      if (role === ROLE.ADMIN || role === ROLE.SALES_MANAGER) {
        return schema.required();
      }
      return schema;
    })
});
