import { createSlice } from '@reduxjs/toolkit';
import { getDateFromDateTime } from '../../utils/helper';

const initialState = {};

const emailBlastSlice = createSlice({
  name: 'emailBlast',
  initialState: initialState,
  reducers: {
    getEmailBlast(state, action) {
      let emailBlastData = action.payload.emailBlastData;
      const paginationData = action.payload.pagination;
      emailBlastData = emailBlastData.map((blast) => {
        return {
          ...blast,
          updatedAt: getDateFromDateTime(blast.updatedAt),
          brokerageOfficeType: blast.brokerageOfficeType
        };
      });
      return { ...state, emailBlastData, paginationData };
    },
    getDraftEmailBlast(state, action) {
      let draftEmailBlastData = action.payload.emailBlastData;
      const paginationData = action.payload.pagination;
      draftEmailBlastData = draftEmailBlastData.map((blast) => {
        return {
          ...blast,
          createdAt: getDateFromDateTime(blast.createdAt),
          brokerageOfficeType: blast.brokerageOfficeType
        };
      });
      return { ...state, draftEmailBlastData, paginationData };
    }
  }
});

export const { getEmailBlast, getDraftEmailBlast } = emailBlastSlice.actions;
export default emailBlastSlice.reducer;
