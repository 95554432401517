import { Outlet, Navigate } from 'react-router-dom';
import Layout from '../Layout';
import { ROUTE_LIST } from '../../constants/routes';
import userPool from '../../utils/userPool';

const PrivateRoutes = () => {
  // Get current user from cognito user pool
  const cognitoUser = userPool.getCurrentUser();

  // TODO: Add condition if !cognitouser then redirect to login otherwise redirect to 404 page
  return cognitoUser ? <Layout content={<Outlet />} /> : <Navigate to={ROUTE_LIST.LOGIN} />;
};

export default PrivateRoutes;
