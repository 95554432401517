import { Dialog, DialogContent, DialogActions } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Label } from '../../components/Label';
import { Button } from '../Button';
import { addEmailBlast } from '../../redux/actions/realtor';
import { generatePresignedUrlsApi } from '../../redux/apis/commonApi';
import { PropertyDetailsTable } from './partials/PropertyDetailsTable';
import { UploadedFileList } from './partials/UploadedFileList';
import { IMAGE_FILE_TYPE } from '../../constants/common';
import { uploadFiles } from '../../utils/helper';

export const EmailPreviewDialog = ({
  open,
  handleClose,
  classes,
  dialogIcon,
  isViewOnly,
  propertyData,
  ...restProps
}) => {
  const userData = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const sendMailToMeHandler = async () => {
    const files = propertyData?.file;
    let fileNameArr = [];
    if (files.length !== 0) {
      // Api call for generate presigned url
      const presingedUrlData = await generatePresignedUrlsApi({
        files: files,
        imageType: IMAGE_FILE_TYPE.EMAIL_BLAST_FILES
      });
      const presignedUrls = presingedUrlData?.data.data;
      // upload all files on s3
      fileNameArr = await uploadFiles(presignedUrls, files);
      delete propertyData?.file;
    }
    dispatch(
      addEmailBlast({
        ...propertyData,
        isSendToMe: true,
        uploadedFiles: fileNameArr
      })
    );
  };

  const descriptionMessage = (
    <div style={{ marginBottom: '20px' }}>
      **OFF MARKET NON REFUNDABLE DEPOSIT**
      <br />
      <br />
      NOTE: The original buyer is selling an option or assigning an interest in a contract, for a
      fee, and does not have the legal title to the property.
    </div>
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="View-user-wrapper custom-dialog email-preview-dialog"
      classes={classes}
      {...restProps}>
      <div className="dialog-content-wrapper">
        <div className="title-wrapper">
          <Label variant="h3" label="Email Preview" />
          <button className="close-icon" onClick={handleClose} name="close">
            <img src={dialogIcon} alt="close icon" />
          </button>
        </div>
        <DialogContent>
          <table cellSpacing={0} cellPadding={0} border={0} align="center" className="table">
            <tbody>
              <tr>
                <td>
                  <table
                    cellPadding={0}
                    cellSpacing={0}
                    border={0}
                    width="100%"
                    style={{ backgroundColor: '#F6F6F6' }}>
                    <tbody>
                      <tr>
                        <td width={25} className="outer-td-width fixed-width" />
                        <td>
                          <table cellPadding={0} cellSpacing={0} border={0} width="100%">
                            <tbody>
                              <tr>
                                <td height={27} className="outer-td-height fixed-height" />
                              </tr>
                              <tr>
                                <td>
                                  <table cellPadding={0} cellSpacing={0} border={0} width="100%">
                                    <tbody>
                                      <tr>
                                        <td align="center">
                                          <a
                                            href="#"
                                            title="lifestyles reality"
                                            width="196"
                                            height="46"
                                            style={{
                                              display: 'block',
                                              width: '196px',
                                              height: '46px'
                                            }}>
                                            <img
                                              src={propertyData?.brokerageOfficeLogo}
                                              width="194"
                                              height="50"
                                              style={{
                                                display: 'block',
                                                width: '194px',
                                                height: '50px'
                                              }}
                                            />
                                          </a>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td height={25} className="outer-td-height fixed-height" />
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    color: '#212121',
                                    fontSize: 14,
                                    fontWeight: 400,
                                    lineHeight: '21px',
                                    textAlign: 'left'
                                  }}>
                                  <span style={{ fontWeight: 600 }}>
                                    IMPORTANT NOTICE TO MEMBERS:&nbsp;
                                  </span>
                                  You
                                  <i>
                                    <b> must </b>
                                  </i>
                                  be pre-approved to write an offer. If the following property meets
                                  your specific criteria, please respond to the email address of the
                                  agent listed at the bottom of the listing. By properly responding,
                                  you will ensure that your desire to write an offer on this
                                  offering will be correctly recorded and timestamped. Calling the
                                  agent directly will not give you a timestamp. You will be notified
                                  of the property availability status within 24 hours. If you are
                                  given an opportunity to write an offer, any specific requirements
                                  will be given at that time.
                                </td>
                              </tr>
                              <tr>
                                <td height={10} className="outer-td-height" />
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    color: '#212121',
                                    fontSize: 14,
                                    fontWeight: 600,
                                    lineHeight: '20px',
                                    textAlign: 'center'
                                  }}>
                                  Here is another property brought to you by
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    color: '#212121',
                                    fontSize: 14,
                                    fontWeight: 600,
                                    lineHeight: '20px',
                                    textAlign: 'center'
                                  }}>
                                  {`${userData?.firstName} ${userData?.lastName}`} and{' '}
                                  {propertyData?.brokerageOfficeName}.
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    color: '#212121',
                                    fontSize: 14,
                                    fontWeight: 600,
                                    lineHeight: '20px',
                                    textAlign: 'center'
                                  }}>
                                  your full-service real estate brokerage.
                                </td>
                              </tr>
                              <tr>
                                <td height={10} className="outer-td-height" />
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    color: '#212121',
                                    fontSize: 14,
                                    fontWeight: 600,
                                    lineHeight: '20px',
                                    textAlign: 'center'
                                  }}>
                                  Investment - Personal residence
                                </td>
                              </tr>
                              <tr>
                                <td height={15} className="outer-td-height" />
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    height: 1,
                                    backgroundColor: '#ECECEC',
                                    width: '100%'
                                  }}
                                />
                              </tr>
                              <tr>
                                <td height={15} className="outer-td-height" />
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    color: '#15397F',
                                    fontSize: 14,
                                    fontWeight: 600,
                                    lineHeight: '21px',
                                    textAlign: 'left'
                                  }}>
                                  Property Description
                                </td>
                              </tr>
                              <tr>
                                <td height={10} className="outer-td-height" />
                              </tr>
                              <tr>
                                <td
                                  className="inner-text-html"
                                  style={{
                                    color: '#15397F',
                                    fontSize: 14,
                                    fontWeight: 400,
                                    lineHeight: '21px',
                                    textAlign: 'left'
                                  }}>
                                  {propertyData?.wholesaleDisclaimer ? descriptionMessage : ''}
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        propertyData?.newDescription || propertyData?.description
                                    }}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td height={10} className="outer-td-height" />
                              </tr>
                              <tr>
                                <td height={15} className="outer-td-height" />
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    color: '#212121',
                                    fontSize: 14,
                                    fontWeight: 400,
                                    lineHeight: '24px',
                                    textAlign: 'left'
                                  }}>
                                  {`${propertyData?.noOfBedrooms}/${propertyData?.noOfBathrooms}/${propertyData?.garageSpace}`}
                                  <br />
                                  {`${propertyData?.squreFootage} sq ft`}
                                  <br />
                                  {`Built ${propertyData?.buildYear}`}
                                  <br />
                                </td>
                              </tr>
                              <tr>
                                <td height="10" className="outer-td-height"></td>
                              </tr>
                              <PropertyDetailsTable propertyData={propertyData} />
                              {propertyData?.file && propertyData?.file.length ? (
                                <UploadedFileList propertyData={propertyData} />
                              ) : (
                                <UploadedFileList />
                              )}
                              {propertyData?.officeExist && (
                                <>
                                  <tr>
                                    <td height={10} className="outer-td-height"></td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        color: '#212121',
                                        fontSize: 12,
                                        fontWeight: 400,
                                        lineHeight: '21px',
                                        textAlign: 'left'
                                      }}>
                                      Texas law requires all license holders to provide the{' '}
                                      <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={userData.IABS}
                                        style={{
                                          color: '#143980',
                                          textDecoration: 'underline'
                                        }}>
                                        {' '}
                                        Information About Brokerage Services form
                                      </a>{' '}
                                      to prospective clients.
                                    </td>
                                  </tr>
                                </>
                              )}
                              <tr>
                                <td
                                  style={{
                                    fontFamily: '"Roboto", Verdana',
                                    color: '#212121',
                                    fontSize: 12,
                                    fontWeight: 400,
                                    lineHeight: '21px',
                                    textAlign: 'left'
                                  }}>
                                  For more Lifestyles Realty Inc. News &amp; Market Updates,{' '}
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://lifestylesrealtyinc.com/news/"
                                    style={{
                                      color: '#143980',
                                      textDecoration: 'underline'
                                    }}>
                                    click here
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    color: '#212121',
                                    fontSize: 12,
                                    fontWeight: 400,
                                    lineHeight: '21px',
                                    textAlign: 'left'
                                  }}>
                                  Please reply to this email if you would like more information
                                  about this property.
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    color: '#212121',
                                    fontSize: 12,
                                    fontWeight: 400,
                                    lineHeight: '21px',
                                    textAlign: 'left'
                                  }}>
                                  Sincerely,
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    color: '#143980',
                                    fontSize: 12,
                                    fontWeight: 400,
                                    lineHeight: '21px',
                                    textAlign: 'left'
                                  }}>
                                  {`${propertyData?.userFirstName || userData.firstName} ${
                                    propertyData?.userLastName || userData.lastName
                                  }`}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    color: '#212121',
                                    fontSize: 12,
                                    fontWeight: 400,
                                    lineHeight: '21px',
                                    textAlign: 'left'
                                  }}>
                                  {`${
                                    propertyData?.userRole?.toUpperCase() ||
                                    userData.role.name?.toUpperCase()
                                  }`}{' '}
                                  &reg;
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    color: '#212121',
                                    fontSize: 12,
                                    fontWeight: 400,
                                    lineHeight: '21px',
                                    textAlign: 'left'
                                  }}>
                                  Direct: {propertyData.userPhone || userData.phone}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    color: '#212121',
                                    fontSize: 12,
                                    fontWeight: 400,
                                    lineHeight: '21px',
                                    textAlign: 'left'
                                  }}>
                                  E-mail: {propertyData.userEmail || userData.email}
                                </td>
                              </tr>
                              <tr>
                                <td height={15} className="outer-td-height" />
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    height: 1,
                                    backgroundColor: '#ECECEC',
                                    width: '100%'
                                  }}
                                />
                              </tr>
                              <tr>
                                <td height={15} className="outer-td-height" />
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    height: 1,
                                    backgroundColor: '#ECECEC',
                                    width: '100%'
                                  }}
                                />
                              </tr>
                              <tr>
                                <td height={15} className="outer-td-height" />
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    color: '#212121',
                                    fontSize: 12,
                                    fontWeight: 600,
                                    lineHeight: '21px',
                                    textAlign: 'center'
                                  }}>
                                  <Link to="#" style={{ color: '#212121' }} title="contact us">
                                    Contact us today for a FREE analysis of your existing portfolio.
                                  </Link>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    color: '#212121',
                                    fontSize: 12,
                                    fontWeight: 600,
                                    lineHeight: '21px',
                                    textAlign: 'center'
                                  }}>
                                  <Link to="#" style={{ color: '#212121' }} title="">
                                    Many investors are realizing their equity now.
                                  </Link>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    color: '#143980',
                                    fontSize: 12,
                                    fontWeight: 600,
                                    lineHeight: '21px',
                                    textAlign: 'center'
                                  }}>
                                  <Link
                                    to={`${process.env.REACT_APP_CMA_REQUEST_LINK}`}
                                    target="_blank"
                                    style={{ color: '#143980', textDecoration: 'underline' }}
                                    title="click for CMA">
                                    Click here if you would like a CMA of a property you are
                                    considering selling
                                  </Link>
                                </td>
                              </tr>
                              <tr>
                                <td height={15} className="outer-td-height" />
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    color: '#143980',
                                    fontSize: 12,
                                    fontWeight: 400,
                                    lineHeight: '16px',
                                    textAlign: 'left'
                                  }}>
                                  Disclaimer: This represents an estimated sale price for this
                                  property. It is not the same as the opinion of value in an
                                  appraisal developed by a licensed appraiser under the Uniform
                                  Standards of Professional Appraisal Practice. In making any
                                  decision that relies upon my work, you should know that I have not
                                  followed the guidelines for development of an appraisal or
                                  analysis contained in the Uniform Standards of Professional
                                  Appraisal Practice of the Appraisal Foundation. Lifestyles Realty
                                  Dallas, Inc. believes the information obtained herein has been
                                  obtained from sources deemed reliable. However, it is your
                                  responsibility to verify the accuracy of the information.
                                  Lifestyles Realty Dallas, Inc. makes no warranty, guaranty, or
                                  representation regarding the accuracy of the information or the
                                  results of your evaluations.
                                  <span
                                    style={{
                                      color: '#143980',
                                      fontSize: '12px',
                                      fontWeight: 400,
                                      lineHeight: '16px',
                                      textAlign: 'left',
                                      marginTop: '5px'
                                    }}>
                                    To unsubscribe from Lifestyles Realty, Inc. property emails,
                                    please log into your{'  '}
                                    <a
                                      style={{
                                        color: '#143980',
                                        fontWeight: 600,
                                        textDecoration: 'underline',
                                        lineHeight: '18px'
                                      }}
                                      href={`${process.env.REACT_APP_FRONTEND_URL}`}
                                      target="_blank"
                                      rel="noreferrer">
                                      Lifestyles Portal
                                    </a>
                                    {'  '}account and click the{'  '}
                                    <a
                                      style={{
                                        color: '#143980',
                                        fontWeight: 600,
                                        textDecoration: 'underline',
                                        lineHeight: '18px'
                                      }}
                                      href={`${process.env.REACT_APP_FRONTEND_URL}`}
                                      target="_blank"
                                      rel="noreferrer">
                                      Property Blasts{' '}
                                    </a>{' '}
                                    {'  '}link.
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td height={27} className="outer-td-height fixed-height" />
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td width={25} className="outer-td-width fixed-width" />
                      </tr>
                    </tbody>
                  </table>
                  <table
                    cellPadding={0}
                    cellSpacing={0}
                    border="0"
                    width="100%"
                    style={{
                      backgroundColor: '#fff'
                    }}></table>
                </td>
              </tr>
            </tbody>
          </table>
        </DialogContent>
        <DialogActions>
          {isViewOnly && (
            <Button className="primary-btn" onClick={sendMailToMeHandler} name="Send to Me" />
          )}
        </DialogActions>
      </div>
    </Dialog>
  );
};

EmailPreviewDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  classes: PropTypes.object,
  dialogIcon: PropTypes.string,
  isViewOnly: PropTypes.bool,
  propertyData: PropTypes.object
};
