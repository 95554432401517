import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { Button } from '../../components/Button';
import { SingleSelect } from '../../components/DropDown/SingleSelect';
import { REALTOR_QUERY_DATA } from '../../constants/common';
import { getRoleBasedUsersApi } from '../../redux/apis/admin';
import { useDispatch } from 'react-redux';
import { reassignPropertyTag } from '../../redux/actions/admin';
import { reassignPropertyTagSchema } from '../../schemas/adminSchema';

export const ReassignPropertyTag = ({ propertyData, setOpenReassignDialog }) => {
  const dispatch = useDispatch();
  const [realtorData, setRealtorData] = useState([]);
  const [realtorOptions, setRealtorOptions] = useState([]);

  useEffect(() => {
    getRoleBasedUsersApi({
      ...REALTOR_QUERY_DATA
    }).then((res) => setRealtorData(res.data.data.userData));
  }, []);

  useEffect(() => {
    setRealtorOptions(
      realtorData?.map((realtor) => {
        return {
          id: realtor.id,
          name: realtor.firstName + ' ' + realtor.lastName
        };
      })
    );
  }, [realtorData]);

  const initialValues = {
    realtor: ''
  };

  const { values, errors, handleChange, handleBlur, handleSubmit, touched } = useFormik({
    initialValues,
    validationSchema: reassignPropertyTagSchema,
    onSubmit: (values) => {
      dispatch(reassignPropertyTag({ propertyId: propertyData.id, realtorId: values.realtor }));
      setOpenReassignDialog(false);
    }
  });

  return (
    <div className="page-inner">
      <div className="page-content-block">
        <form onSubmit={handleSubmit}>
          <div className="form-row-wrapper">
            <div className="form-col full-width">
              <SingleSelect
                placeholderText="Realtor"
                items={realtorOptions}
                label="Realtor"
                MenuProps={{ classes: { paper: 'custom-select-dropdown' } }}
                name="realtor"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.realtor}
                error={touched.realtor && Boolean(errors.realtor)}
                helperText={touched.realtor && errors.realtor}
              />
            </div>
          </div>
          <div className="form-btn-wrapper">
            <Button className="primary-btn" type="submit" name="Save" />
          </div>
        </form>
      </div>
    </div>
  );
};

ReassignPropertyTag.propTypes = {
  propertyData: PropTypes.object,
  setOpenReassignDialog: PropTypes.func
};
