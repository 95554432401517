import axios from 'axios';
import { toast } from 'react-toastify';
import { apiPending, apiStatusClear, apiFail, apiSuccess } from '../redux/slices/apiStatus';
import { store } from '../redux/store';
import { API_ERROR_MESSAGES, RESPONSE_STATUS_CODES } from '../constants/common';
import { API_ROUTES } from '../constants/apiRoutes';
import { ROUTE_LIST } from '../constants/routes';
import { getUserIdToken } from './manageAccessToken';

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

axios.interceptors.request.use(
  async function (config) {
    const url = config.url.split('?')[0];
    let token = await getUserIdToken();
    if (url) {
      store.dispatch(apiStatusClear());
      store.dispatch(apiPending());
    }
    // If url is not for login then set access token in header
    if (
      config.url !== API_ROUTES.LOGIN &&
      !config.url.startsWith(process.env.REACT_APP_S3_BUCKET_URL)
    ) {
      config.headers.Authorization = token;
    }
    return config;
  },
  function (error) {
    store.dispatch(apiFail());
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    store.dispatch(apiSuccess());
    return response;
  },
  (error) => {
    if (error?.response?.status && error?.response?.data) {
      store.dispatch(apiFail());
      const { status, data } = error.response;
      const apiErrorMessage = data.message;
      if (status === RESPONSE_STATUS_CODES?.TOKEN_EXPIRED) {
        localStorage.clear();
        window.location.replace(ROUTE_LIST.LOGIN);
      } else if (apiErrorMessage) {
        toast.error(apiErrorMessage);
      } else if (API_ERROR_MESSAGES[status]) {
        toast.error(API_ERROR_MESSAGES[status]);
      } else {
        toast.error(API_ERROR_MESSAGES.default);
      }
    } else {
      // Please note: On Network error we are redirecting to login page. This is not the case for all apis and server errors. Just the case when auth token gets expired.
      // Also In case of Network error and Cors error, this will redirect to login page.
      localStorage.clear();
      window.location.replace(ROUTE_LIST.LOGIN);
      store.dispatch(apiFail(error?.message));
      // toast.error(error?.message);
    }
    return Promise.reject(error);
  }
);

export default axios;
