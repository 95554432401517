import { call, put, takeLatest } from 'redux-saga/effects';
import { RESPONSE_STATUS_CODES } from '../../constants/common';
import { GET_DRAFT_EMAIL_BLAST_LIST_REQUEST, GET_EMAIL_BLAST_REQUEST } from '../../constants/redux';
import { getDraftEmailBlastApi, getEmailBlastApi } from '../apis/emailBlast';
import { apiFail } from '../slices/apiStatus';
import { getDraftEmailBlast, getEmailBlast } from '../slices/emailBlast';

function* getEmailBlastSaga(action) {
  try {
    const response = yield call(getEmailBlastApi, action.payload);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      yield put(getEmailBlast(result));
    }
  } catch (err) {
    yield put(apiFail());
  }
}
function* getDraftEmailBlastListSaga(action) {
  try {
    const response = yield call(getDraftEmailBlastApi, action.payload);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      yield put(getDraftEmailBlast(result));
    }
  } catch (err) {
    yield put(apiFail());
  }
}

function* watcherEmailBlastSaga() {
  yield takeLatest(GET_EMAIL_BLAST_REQUEST, getEmailBlastSaga);
  yield takeLatest(GET_DRAFT_EMAIL_BLAST_LIST_REQUEST, getDraftEmailBlastListSaga);
}

export default watcherEmailBlastSaga;
