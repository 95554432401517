import { Dialog, DialogContent } from '@mui/material';
import PropTypes from 'prop-types';
import { Label } from '../../components/Label';
import { OTHER_BLAST_PERMISSION_BROKERAGE_OFFICES } from '../../constants/common';
import { BrokerageOfficeSalesManagerList } from '../../pages/brokerageOfficeSalesManagerList';

export const ViewUserDialog = ({
  open,
  handleClose,
  classes,
  dialogIcon,
  userData,
  ...restProps
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="View-user-wrapper custom-dialog"
      classes={classes}
      {...restProps}>
      <div className="dialog-content-wrapper">
        <div className="title-wrapper">
          <Label variant="h3" label="View User" />
          <button className="close-icon" onClick={handleClose} name="close">
            <img src={dialogIcon} alt="close icon" />
          </button>
        </div>
        <DialogContent>
          <div className="user-detail-wrapper">
            <div className="single-detail-wrapper">
              <span className="label">Name</span>
              <div className="detail">
                <p>{`${userData?.firstName} ${userData?.lastName}`}</p>
              </div>
            </div>
            <div className="single-detail-wrapper">
              <span className="label">Phone Number</span>
              <div className="detail">
                <p>{userData?.phone}</p>
              </div>
            </div>
            <div className="single-detail-wrapper">
              <span className="label">Email</span>
              <div className="detail">
                <p>{userData?.email}</p>
              </div>
            </div>
            <div className="single-detail-wrapper">
              <span className="label">Roles</span>
              <div className="detail">
                <p>{userData?.role}</p>
              </div>
            </div>
            <div className="single-detail-wrapper">
              <span className="label">Status</span>
              <div className="detail">
                <span className={`status-badge ${userData?.status?.toLowerCase() || ''}`}>
                  {userData?.status}
                </span>
              </div>
            </div>
          </div>
          {OTHER_BLAST_PERMISSION_BROKERAGE_OFFICES[0] === userData?.role && (
            <BrokerageOfficeSalesManagerList brokerageOfficeId={userData?.id} />
          )}
        </DialogContent>
      </div>
    </Dialog>
  );
};

ViewUserDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  classes: PropTypes.object,
  dialogIcon: PropTypes.string,
  userData: PropTypes.object
};
