import {
  ADD_PROPERTY_TAG_REQUEST,
  GET_PROPERTY_HISTORY_REQUEST,
  GET_PROPERTIES_TAG_REQUEST,
  MY_PROPERTY_REQUEST,
  PROPERTY_TAG_ACTION_REQUEST,
  GET_RELATIONSHIP_HISTORY_REQUEST,
  GET_PROPERTY_NOTIFICATION_REQUEST,
  PROPERTY_NOTIFICATION_ACTION_REQUEST,
  CREATE_EMAIL_BLAST_REQUEST
} from '../../constants/redux';

export const myProperties = (queryData) => {
  return {
    type: MY_PROPERTY_REQUEST,
    payload: queryData
  };
};

export const addPropertyTagRequest = (propertyId) => {
  return {
    type: ADD_PROPERTY_TAG_REQUEST,
    payload: propertyId
  };
};

export const getPropertyHistory = (queryData, propertyId) => {
  return {
    type: GET_PROPERTY_HISTORY_REQUEST,
    payload: { queryData, propertyId }
  };
};

export const getPropertyTagRequests = (queryData) => {
  return {
    type: GET_PROPERTIES_TAG_REQUEST,
    payload: queryData
  };
};

export const propertyTagRequestAction = (propertyData) => {
  return {
    type: PROPERTY_TAG_ACTION_REQUEST,
    payload: propertyData
  };
};

export const getRelationshipHistory = (queryData, propertyId) => {
  return {
    type: GET_RELATIONSHIP_HISTORY_REQUEST,
    payload: { queryData, propertyId }
  };
};

export const getPropertyNotification = (queryData) => {
  return {
    type: GET_PROPERTY_NOTIFICATION_REQUEST,
    payload: { queryData }
  };
};

export const propertyTagNotificationAction = ({ propertyId, action }) => {
  return {
    type: PROPERTY_NOTIFICATION_ACTION_REQUEST,
    payload: { propertyId, action }
  };
};

export const addEmailBlast = (emailBlastData) => {
  return {
    type: CREATE_EMAIL_BLAST_REQUEST,
    payload: emailBlastData
  };
};
