import {
  ADD_USER_REQUEST,
  CHANGE_PASSWORD_REQUEST,
  GET_USERS_REQUEST,
  USER_BULK_ACTION_REQUEST,
  DELETE_USER_REQUEST,
  GET_USER_ROLES_REQUEST,
  GET_EDIT_USER_DETAILS_REQUEST,
  UPDATE_USER_DETAILS_REQUEST,
  GET_SETTINGS_REQUEST,
  UPDATE_SETTINGS_REQUEST,
  ADD_BROKERAGE_OFFICE_REQUEST,
  UPDATE_BROKERAGE_OFFICE_REQUEST,
  DELETE_BROKERAGE_OFFICE_REQUEST,
  REASSIGN_PROPERTY_TAG_REQUEST
} from '../../constants/redux';

export const addUser = (userData) => {
  return {
    type: ADD_USER_REQUEST,
    payload: userData
  };
};

export const changePassword = (credentials) => {
  return {
    type: CHANGE_PASSWORD_REQUEST,
    payload: credentials
  };
};

export const getUsers = (queryData) => {
  return {
    type: GET_USERS_REQUEST,
    payload: queryData
  };
};

export const userBulkAction = (userData) => {
  return {
    type: USER_BULK_ACTION_REQUEST,
    payload: userData
  };
};

export const deleteUser = (userData) => {
  return {
    type: DELETE_USER_REQUEST,
    payload: userData
  };
};

export const getUserRoles = () => {
  return {
    type: GET_USER_ROLES_REQUEST
  };
};

export const getEditUserDetails = (userId) => {
  return {
    type: GET_EDIT_USER_DETAILS_REQUEST,
    payload: userId
  };
};

export const updateUserDetails = (userData) => {
  return {
    type: UPDATE_USER_DETAILS_REQUEST,
    payload: userData
  };
};

export const getSettings = () => {
  return {
    type: GET_SETTINGS_REQUEST
  };
};

export const updateSettings = (settingsData) => {
  return {
    type: UPDATE_SETTINGS_REQUEST,
    payload: settingsData
  };
};

export const addBrokerageOffice = (brokerageOfficeData) => {
  return {
    type: ADD_BROKERAGE_OFFICE_REQUEST,
    payload: brokerageOfficeData
  };
};

export const updateBrokerageOffice = (brokerageOfficeData) => {
  return {
    type: UPDATE_BROKERAGE_OFFICE_REQUEST,
    payload: brokerageOfficeData
  };
};

export const deleteBrokerageOffice = (brokerageOfficeData) => {
  return {
    type: DELETE_BROKERAGE_OFFICE_REQUEST,
    payload: brokerageOfficeData
  };
};

export const reassignPropertyTag = ({ propertyId, realtorId }) => {
  return {
    type: REASSIGN_PROPERTY_TAG_REQUEST,
    payload: { propertyId, realtorId }
  };
};
