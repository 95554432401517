import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  getEditUserDetails,
  getSettings,
  getUserRoles,
  getUsers,
  manageBrokerageOffice
} from '../slices/admin';
import { apiFail, apiPending, apiStatusClear, apiSuccess } from '../slices/apiStatus';
import { getBrokerageOffices } from '../slices/brokerageOffice';
import {
  addUserApi,
  changePasswordApi,
  getUsersApi,
  userBulkActionApi,
  deleteUsersApi,
  getUserRolesApi,
  getEditUserDetailsApi,
  updateUserDetailsApi,
  getSettingsApi,
  updateSettingsApi,
  addBrokerageOfficeApi,
  updateBrokerageOfficeApi,
  deleteBrokerageOfficeApi,
  reassignPropertyTagApi
} from '../apis/admin';
import { getBrokerageOfficesApi } from '../apis/brokerageOffice';
import { MESSAGES, RESPONSE_STATUS_CODES } from '../../constants/common';
import {
  ADD_USER_REQUEST,
  CHANGE_PASSWORD_REQUEST,
  GET_USERS_REQUEST,
  USER_BULK_ACTION_REQUEST,
  DELETE_USER_REQUEST,
  GET_USER_ROLES_REQUEST,
  GET_EDIT_USER_DETAILS_REQUEST,
  UPDATE_USER_DETAILS_REQUEST,
  GET_SETTINGS_REQUEST,
  UPDATE_SETTINGS_REQUEST,
  ADD_BROKERAGE_OFFICE_REQUEST,
  UPDATE_BROKERAGE_OFFICE_REQUEST,
  DELETE_BROKERAGE_OFFICE_REQUEST,
  REASSIGN_PROPERTY_TAG_REQUEST
} from '../../constants/redux';
import { getPropertiesApi } from '../apis/property';
import { getProperty } from '../slices/property';

function* addUserSaga(action) {
  try {
    const addUserResponse = yield call(addUserApi, action.payload);
    if (addUserResponse?.status === RESPONSE_STATUS_CODES.OK) {
      toast.success(MESSAGES.SUCCESSFULLY_CREATED_USER);
      const response = yield call(getUsersApi);
      if (response?.status === RESPONSE_STATUS_CODES.OK) {
        const result = response.data.data;
        yield put(getUsers(result));
      }
    }
  } catch (err) {
    yield put(apiFail());
  }
}

function* changePasswordSaga(action) {
  try {
    yield put(apiPending());
    yield call(changePasswordApi, action.payload);
    yield put(apiSuccess());
  } catch (err) {
    yield put(apiFail());
  }
}

function* getUsersSaga(action) {
  try {
    const response = yield call(getUsersApi, action.payload);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      yield put(getUsers(result));
    }
  } catch (err) {
    yield put(apiFail());
  }
}

function* getUserRolesSaga() {
  try {
    const response = yield call(getUserRolesApi);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      yield put(getUserRoles(result));
    }
    yield put(apiStatusClear());
  } catch (err) {
    yield put(apiFail());
  }
}

function* userBulkActionSaga(action) {
  try {
    const { userData, queryData } = action.payload;
    yield call(userBulkActionApi, userData);
    const response = yield call(getUsersApi, queryData);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      yield put(getUsers(result));
    }
  } catch (err) {
    yield put(apiFail());
  }
}

function* deleteUserSaga(action) {
  try {
    const { id, queryData } = action.payload;
    const deletedData = yield call(deleteUsersApi, id);
    if (deletedData?.status === RESPONSE_STATUS_CODES.OK) {
      const response = yield call(getUsersApi, queryData);
      if (response?.status === RESPONSE_STATUS_CODES.OK) {
        toast.success(MESSAGES.SUCCESSFULLY_DELETED_USER_DETAILS);
        const result = response.data.data;
        yield put(getUsers(result));
      }
    }
  } catch (err) {
    yield put(apiFail());
  }
}

function* getEditUserDetailsSaga(action) {
  try {
    const response = yield call(getEditUserDetailsApi, action.payload);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      yield put(getEditUserDetails(result));
    }
  } catch (err) {
    yield put(apiFail());
  }
}

function* updateUserDetailsSaga(action) {
  try {
    const updatedUserData = yield call(updateUserDetailsApi, action.payload);
    if (updatedUserData?.status === RESPONSE_STATUS_CODES.OK) {
      toast.success(MESSAGES.SUCCESSFULLY_UPDATED_USER_DETAILS);
    }
  } catch (err) {
    yield put(apiFail());
  }
}

function* getSettingSaga(action) {
  try {
    const settingsData = yield call(getSettingsApi, action.payload);
    if (settingsData?.status === RESPONSE_STATUS_CODES.OK) {
      yield put(getSettings(settingsData.data.data));
    }
  } catch (err) {
    yield put(apiFail());
  }
}

function* updateSettingSaga(action) {
  try {
    const settingsData = yield call(updateSettingsApi, action.payload);
    if (settingsData?.status === RESPONSE_STATUS_CODES.OK) {
      yield put(getSettings(settingsData.data.data));
      toast.success(MESSAGES.SUCCESSFULLY_CHANGED_SETTING);
    }
  } catch (err) {
    yield put(apiFail());
  }
}

function* addBrokerageOfficeSaga(action) {
  try {
    const response = yield call(addBrokerageOfficeApi, {
      ...action.payload,
      imageUrl: action.payload.presignedUrl
    });
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      yield put(manageBrokerageOffice({ isBrokerageOfficeAdded: true }));
      toast.success(MESSAGES.SUCCESSFULLY_CREATED_BROKERAGE_OFFICE);
    }
  } catch (err) {
    yield put(apiFail());
  }
}

function* updateBrokerageOfficeSaga(action) {
  try {
    const response = yield call(updateBrokerageOfficeApi, {
      ...action.payload,
      imageUrl: action.payload.presignedUrl || action.payload.logo
    });
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      yield put(manageBrokerageOffice({ isBrokerageOfficeEdited: true }));
      toast.success(MESSAGES.SUCCESSFULLY_UPDATED_BROKERAGE_OFFICE);
    }
  } catch (err) {
    yield put(apiFail());
  }
}

function* deleteBrokerageOfficeSaga(action) {
  try {
    const { brokerageOfficeId, queryData } = action.payload;
    const deleteBrokerageOfficeData = yield call(deleteBrokerageOfficeApi, brokerageOfficeId);
    if (deleteBrokerageOfficeData?.status === RESPONSE_STATUS_CODES.OK) {
      toast.success(MESSAGES.SUCCESSFULLY_DELETED_BROKERAGE_OFFICE_DETAILS);
      const response = yield call(getBrokerageOfficesApi, queryData);
      if (response?.status === RESPONSE_STATUS_CODES.OK) {
        const result = response.data.data;
        yield put(getBrokerageOffices(result));
      }
    }
  } catch (err) {
    yield put(apiFail());
  }
}

function* reassignPropertyTagSaga(action) {
  try {
    const { propertyId, realtorId } = action.payload;
    const reassignTagData = yield call(reassignPropertyTagApi, { propertyId, realtorId });
    if (reassignTagData?.status === RESPONSE_STATUS_CODES.OK) {
      toast.success(MESSAGES.SUCCESSFULLY_ASSIGN_PROPERTY_TAG);
      const response = yield call(getPropertiesApi);
      if (response?.status === RESPONSE_STATUS_CODES.OK) {
        const result = response.data.data;
        yield put(getProperty(result));
      }
    }
  } catch (err) {
    yield put(apiFail());
  }
}

function* watcherAdminSaga() {
  yield takeLatest(ADD_USER_REQUEST, addUserSaga);
  yield takeLatest(CHANGE_PASSWORD_REQUEST, changePasswordSaga);
  yield takeLatest(GET_USERS_REQUEST, getUsersSaga);
  yield takeLatest(USER_BULK_ACTION_REQUEST, userBulkActionSaga);
  yield takeLatest(DELETE_USER_REQUEST, deleteUserSaga);
  yield takeLatest(GET_USER_ROLES_REQUEST, getUserRolesSaga);
  yield takeLatest(GET_EDIT_USER_DETAILS_REQUEST, getEditUserDetailsSaga);
  yield takeLatest(UPDATE_USER_DETAILS_REQUEST, updateUserDetailsSaga);
  yield takeLatest(GET_SETTINGS_REQUEST, getSettingSaga);
  yield takeLatest(UPDATE_SETTINGS_REQUEST, updateSettingSaga);
  yield takeLatest(ADD_BROKERAGE_OFFICE_REQUEST, addBrokerageOfficeSaga);
  yield takeLatest(UPDATE_BROKERAGE_OFFICE_REQUEST, updateBrokerageOfficeSaga);
  yield takeLatest(DELETE_BROKERAGE_OFFICE_REQUEST, deleteBrokerageOfficeSaga);
  yield takeLatest(REASSIGN_PROPERTY_TAG_REQUEST, reassignPropertyTagSaga);
}

export default watcherAdminSaga;
