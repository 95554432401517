import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const brokerageOfficeSlice = createSlice({
  name: 'brokerageOffice',
  initialState: initialState,
  reducers: {
    getBrokerageOffices(state, action) {
      const brokerageOfficeData = action.payload.brokerageOfficeData;
      const paginationData = action.payload.pagination;
      return { ...state, brokerageOfficeData, paginationData };
    },
    getBrokerageOfficeRealtors(state, action) {
      const brokerageOfficeRealtorsData = action.payload.brokerageOfficeRealtorData;
      const realtorsPaginationData = action.payload.pagination;
      return { ...state, brokerageOfficeRealtorsData, realtorsPaginationData };
    },
    getBrokerageOfficeBlastPermissions(state, action) {
      const brokerageOfficeBlastPermissionsData =
        action.payload.brokerageOfficeBlastPermissionsData;
      const blastPermissionsPaginationData = action.payload.pagination;
      return { ...state, brokerageOfficeBlastPermissionsData, blastPermissionsPaginationData };
    }
  }
});

export const {
  getBrokerageOffices,
  getBrokerageOfficeRealtors,
  getBrokerageOfficeBlastPermissions
} = brokerageOfficeSlice.actions;
export default brokerageOfficeSlice.reducer;
