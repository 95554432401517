import React from 'react';
import PropTypes from 'prop-types';
import { formatCurrency } from '../../../utils/helper';
import { ESTIMATE_OUT_OF_POCKET, ESTIMATE_OUT_OF_POCKET_TYPE } from '../../../constants/common';

export const PropertyDetailsTable = ({ propertyData }) => {
  return (
    <>
      <tr>
        <td
          style={{
            color: '#15397F',
            fontFamily: '"Roboto", Verdana',
            fontSize: 14,
            fontWeight: 600,
            lineHeight: '24px',
            textAlign: 'left'
          }}>
          Details
        </td>
      </tr>
      <tr>
        <td height={15} className="outer-td-height" />
      </tr>
      <tr>
        <td>
          <table cellPadding={0} cellSpacing={0} border={0} width="100%">
            <tbody>
              <tr>
                <td
                  className="table-left-col"
                  style={{
                    width: '50%'
                  }}>
                  <table cellPadding={0} cellSpacing={0} border={0} width="100%">
                    <tbody>
                      <tr>
                        <td
                          style={{
                            color: '#212121',
                            fontSize: 12,
                            lineHeight: '14px',
                            fontWeight: 400
                          }}>
                          After-repair Price Opinion
                        </td>
                        <td className="outer-td-width" width="15px" />
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td
                  className="table-right-col"
                  style={{
                    width: '50%'
                  }}>
                  <table cellPadding={0} cellSpacing={0} border={0} width="100%">
                    <tbody>
                      <tr>
                        <td
                          style={{
                            color: '#212121',
                            fontWeight: 400,
                            fontSize: 12,
                            lineHeight: '14px'
                          }}>
                          {`$${formatCurrency(propertyData?.afterRepairPrice)}`}
                        </td>
                        <td className="outer-td-width" width="15px" />
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td height={10} className="outer-td-height" />
              </tr>
              <tr>
                <td
                  className="table-left-col"
                  style={{
                    width: '50%'
                  }}>
                  <table cellPadding={0} cellSpacing={0} border={0} width="100%">
                    <tbody>
                      <tr>
                        <td
                          style={{
                            color: '#212121',
                            fontSize: 12,
                            lineHeight: '14px',
                            fontWeight: 400
                          }}>
                          Asking Price
                        </td>
                        <td className="outer-td-width" width="15px" />
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td
                  className="table-right-col"
                  style={{
                    width: '50%'
                  }}>
                  <table cellPadding={0} cellSpacing={0} border={0} width="100%">
                    <tbody>
                      <tr>
                        <td
                          style={{
                            color: '#212121',
                            fontWeight: 400,
                            fontSize: 12,
                            lineHeight: '14px'
                          }}>
                          ${formatCurrency(propertyData?.askingPrice)}
                        </td>
                        <td className="outer-td-width" width="15px" />
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td height={10} className="outer-td-height" />
              </tr>
              <tr>
                <td
                  className="table-left-col"
                  style={{
                    width: '50%'
                  }}>
                  <table cellPadding={0} cellSpacing={0} border={0} width="100%">
                    <tbody>
                      <tr>
                        <td
                          style={{
                            color: '#212121',
                            fontSize: 12,
                            lineHeight: '14px',
                            fontWeight: 400
                          }}>
                          Estimated Equity Capture
                        </td>
                        <td className="outer-td-width" width="15px" />
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td
                  className="table-right-col"
                  style={{
                    width: '50%'
                  }}>
                  <table cellPadding={0} cellSpacing={0} border={0} width="100%">
                    <tbody>
                      <tr>
                        <td
                          style={{
                            color: '#212121',
                            fontWeight: 400,
                            fontSize: 12,
                            lineHeight: '14px'
                          }}>
                          ${formatCurrency(propertyData?.estimateEquityCapture)}
                        </td>
                        <td className="outer-td-width" width="15px" />
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td height={10} className="outer-td-height" />
              </tr>
              <tr>
                <td
                  className="table-left-col"
                  style={{
                    width: '50%'
                  }}>
                  <table cellPadding={0} cellSpacing={0} border={0} width="100%">
                    <tbody>
                      <tr>
                        <td
                          style={{
                            color: '#212121',
                            fontSize: 12,
                            lineHeight: '14px',
                            fontWeight: 400
                          }}>
                          Return on Equity Capture
                        </td>
                        <td className="outer-td-width" width="15px" />
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td
                  className="table-right-col"
                  style={{
                    width: '50%'
                  }}>
                  <table cellPadding={0} cellSpacing={0} border={0} width="100%">
                    <tbody>
                      <tr>
                        <td
                          style={{
                            color: '#212121',
                            fontWeight: 400,
                            fontSize: 12,
                            lineHeight: '14px'
                          }}>
                          {propertyData?.returnOnEquityCapture}%
                        </td>
                        <td className="outer-td-width" width="15px" />
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td height={10} className="outer-td-height" />
              </tr>
              <tr>
                <td
                  className="table-left-col"
                  style={{
                    width: '50%'
                  }}>
                  <table cellPadding={0} cellSpacing={0} border={0} width="100%">
                    <tbody>
                      <tr>
                        <td
                          style={{
                            color: '#212121',
                            fontSize: 12,
                            lineHeight: '14px',
                            fontWeight: 400
                          }}>
                          Estimated Cash Flow per Month
                        </td>
                        <td className="outer-td-width" width="15px" />
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td
                  className="table-right-col"
                  style={{
                    width: '50%'
                  }}>
                  <table cellPadding={0} cellSpacing={0} border={0} width="100%">
                    <tbody>
                      <tr>
                        <td
                          style={{
                            color: '#212121',
                            fontWeight: 400,
                            fontSize: 12,
                            lineHeight: '14px'
                          }}>
                          ${formatCurrency(propertyData?.estimateCashPerMonth)}
                        </td>
                        <td className="outer-td-width" width="15px" />
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td height={10} className="outer-td-height" />
              </tr>
              <tr>
                <td
                  className="table-left-col"
                  style={{
                    width: '50%'
                  }}>
                  <table cellPadding={0} cellSpacing={0} border={0} width="100%">
                    <tbody>
                      <tr>
                        <td
                          style={{
                            color: '#212121',
                            fontSize: 12,
                            lineHeight: '14px',
                            fontWeight: 400
                          }}>
                          Estimated Cash on Cash Return
                        </td>
                        <td className="outer-td-width" width="15px" />
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td
                  className="table-right-col"
                  style={{
                    width: '50%'
                  }}>
                  <table cellPadding={0} cellSpacing={0} border={0} width="100%">
                    <tbody>
                      <tr>
                        <td
                          style={{
                            color: '#212121',
                            fontWeight: 400,
                            fontSize: 12,
                            lineHeight: '14px'
                          }}>
                          {propertyData?.estimateCashOnCashReturn}%
                        </td>
                        <td className="outer-td-width" width="15px" />
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td height={10} className="outer-td-height" />
              </tr>
              <tr>
                <td
                  className="table-left-col"
                  style={{
                    width: '50%'
                  }}>
                  <table cellPadding={0} cellSpacing={0} border={0} width="100%">
                    <tbody>
                      <tr>
                        <td
                          style={{
                            color: '#212121',
                            fontSize: 12,
                            lineHeight: '14px',
                            fontWeight: 400
                          }}>
                          Estimated Out of Pocket(
                          {Number(propertyData?.estimateOutOfPocketType) ===
                          ESTIMATE_OUT_OF_POCKET_TYPE.HARD
                            ? ESTIMATE_OUT_OF_POCKET.HARD
                            : ESTIMATE_OUT_OF_POCKET.CONV}
                          )
                        </td>
                        <td className="outer-td-width" width="15px" />
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td
                  className="table-right-col"
                  style={{
                    width: '50%'
                  }}>
                  <table cellPadding={0} cellSpacing={0} border={0} width="100%">
                    <tbody>
                      <tr>
                        <td
                          style={{
                            color: '#212121',
                            fontWeight: 400,
                            fontSize: 12,
                            lineHeight: '14px'
                          }}>
                          ${formatCurrency(propertyData?.estimateOutOfPocket)}
                        </td>
                        <td className="outer-td-width" width="15px" />
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td height={10} className="outer-td-height" />
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </>
  );
};

PropertyDetailsTable.propTypes = {
  propertyData: PropTypes.object
};
