import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { DataTable } from '../../components/DataTable';
import { ConfirmDialog } from '../../components/Popup/ConfirmDialog';
import { ViewUserDialog } from '../../components/Popup/ViewUserDialog';
import { Label } from '../../components/Label';
import { BreadCrumb } from '../../components/BreadCrumb';
import { SingleSelect } from '../../components/DropDown/SingleSelect';
import { PageLoader } from '../../components/PageLoader';
import { Button } from '../../components/Button';
import { deleteUser, getUserRoles, getUsers, userBulkAction } from '../../redux/actions/admin';
import { apiStatusClear } from '../../redux/slices/apiStatus';
import { ROUTE_LIST } from '../../constants/routes';
import {
  ALL_FILTERS,
  DEBOUNCE_TIMEOUT,
  defaultQueryData,
  DIALOG,
  MESSAGES,
  STATUS_ITEMS,
  STATUS_LABELS
} from '../../constants/common';
import {
  actionDots,
  dialogDeleteIcon,
  dialogCloseIcon,
  viewAction,
  editAction,
  deleteAction,
  disableDeleteAction
} from '../../constants/images';
import { debounce } from '../../utils/helper';
import './../../styles/inner-page-style.css';
import './../../styles/data-table-style.css';
import './../../styles/custom-dialog-style.css';

export const UserList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [user, setUser] = useState();
  const [openDialog, setOpen] = useState(false);
  const [viewUserOpen, setviewUserOpen] = useState(false);
  const { usersData, paginationData } = useSelector((state) => state.admin);
  const { isLoading } = useSelector((state) => state.apiStatus);
  const { id: loggedInUserId } = useSelector((state) => state.auth);
  const [queryData, setQueryData] = useState({ ...defaultQueryData });
  const [status, setStatus] = useState(ALL_FILTERS);
  const [role, setRole] = useState(ALL_FILTERS);
  const [isInitialRender, setIsInitialRender] = useState(true);
  let { userRoles } = useSelector((state) => state.admin);
  let userRolesList = [
    {
      id: ALL_FILTERS,
      name: 'All Roles'
    }
  ];
  // Added options for all roles
  if (userRoles) {
    userRolesList = [...userRolesList, ...userRoles];
  }
  // Added options for all status
  const statusItems = [
    {
      id: ALL_FILTERS,
      name: 'All Status'
    },
    ...STATUS_ITEMS
  ];

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openDeleteDialog = (params) => {
    setOpen(true);
    setUser(params);
  };
  const closeDeleteDialog = () => {
    setOpen(false);
  };

  const deleteUserHandler = () => {
    dispatch(deleteUser({ id: user.id, queryData }));
    setOpen(false);
  };

  const buttonsList = [
    {
      name: 'Cancel',
      className: 'primary-btn border-btn',
      event: closeDeleteDialog
    },
    {
      name: 'Delete',
      className: 'primary-btn',
      event: deleteUserHandler
    }
  ];

  const openViewUserDialog = (params) => {
    setUser(params.row);
    setviewUserOpen(true);
  };
  const closeViewUserDialog = () => {
    setviewUserOpen(false);
  };

  useEffect(() => {
    setIsInitialRender(false);
    dispatch(getUsers(queryData));
    if (!userRoles) dispatch(getUserRoles());
  }, []);

  const sortingChangeHandler = (sortModelData) => {
    if (sortModelData && sortModelData.length > 0) {
      const sortQueryData = {
        ...queryData,
        sortField: sortModelData[0].field,
        sortDirection: sortModelData[0].sort
      };
      setQueryData(sortQueryData);
      dispatch(getUsers(sortQueryData));
    }
  };

  const paginationChangeHandler = (paginationModelData) => {
    const paginationQueryData = {
      ...queryData,
      page: paginationModelData.page + 1,
      perPage: paginationModelData.pageSize
    };
    setQueryData(paginationQueryData);
    dispatch(getUsers(paginationQueryData));
  };

  const bulkActionHandler = (action) => {
    if (rowSelectionModel.length !== 0) {
      const userData = { action, userIds: rowSelectionModel };
      dispatch(userBulkAction({ userData, queryData }));
    } else {
      toast.info(MESSAGES.PLEASE_SELECT_ANY_ROW);
    }
  };
  const editActionHandler = (params) => {
    navigate(`${ROUTE_LIST.USER_LIST}/edit/${params.id}`);
  };

  const columns = [
    {
      field: 'firstName',
      headerName: 'First name',
      flex: 1,
      minWidth: 140,
      renderCell: (params) => {
        return (
          <>
            <span className="user-fname">{params.row.firstName}</span>
          </>
        );
      }
    },
    { field: 'lastName', headerName: 'Last name', flex: 1, minWidth: 120 },
    { field: 'email', headerName: 'Email', flex: 1, minWidth: 320 },
    {
      field: 'phone',
      headerName: 'Phone',
      flex: 1,
      minWidth: 140,
      sortable: false
    },
    {
      field: 'role',
      headerName: 'Role',
      flex: 1,
      minWidth: 130,
      sortable: false
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 90,
      sortable: false,
      renderCell: (params) => {
        const statusClass = params.row.status.toLowerCase() || '';
        return (
          <>
            <span className={`status-badge ${statusClass}`}>{params.row.status}</span>
          </>
        );
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      minWidth: 130,
      headerClassName: 'action-col',
      cellClassName: 'action-col',
      sortable: false,
      align: 'right',
      renderHeader: () => {
        return (
          <div className="action-heading-wrapper">
            <div>
              <p>Action</p>
            </div>
            <div className="action-btn-wrapper">
              <IconButton
                disableRipple
                label="action"
                onClick={handleClick}
                size="small"
                aria-controls={open ? 'action-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                className="user-profile-menu-link">
                <img src={actionDots} alt="action-dots-icon" />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                id="action-menu"
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                className="table-action-menu">
                <MenuItem
                  onClick={() => {
                    setAnchorEl(false);
                    bulkActionHandler(STATUS_LABELS.ACTIVE);
                    setRowSelectionModel([]);
                  }}>
                  <span>Active</span>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setAnchorEl(false);
                    bulkActionHandler(STATUS_LABELS.INACTIVE);
                    setRowSelectionModel([]);
                  }}>
                  <span>Inactive</span>
                </MenuItem>
              </Menu>
            </div>
          </div>
        );
      },
      renderCell: (params) => {
        return (
          <div className="action-column-wrapper">
            <div className="action-column-inner">
              <Button
                title="View"
                className="view-icon"
                onClick={() => openViewUserDialog(params)}
                name={<img src={viewAction} alt="view-icon" />}
              />
            </div>
            <div className="action-column-inner">
              <Button
                title="Edit"
                className="edit-icon"
                onClick={() => editActionHandler(params)}
                name={<img src={editAction} alt="edit-icon" />}
              />
            </div>
            <div className="action-column-inner">
              <Button
                title="Delete"
                className="delete-icon"
                onClick={() => openDeleteDialog(params)}
                disabled={params.id === loggedInUserId}
                name={
                  <img
                    src={params.id === loggedInUserId ? disableDeleteAction : deleteAction}
                    alt="delete-icon"
                  />
                }
              />
            </div>
          </div>
        );
      }
    }
  ];

  const breadcrumbsLinks = [
    {
      label: 'Dashboard',
      to: ROUTE_LIST.DASHBOARD
    },
    {
      label: 'Users',
      last: true
    }
  ];

  const handleCreateUser = () => {
    dispatch(apiStatusClear());
  };

  // Search users based on timeout seconds
  const handleSearch = debounce((value) => {
    if (value === '') {
      delete queryData.search;
      dispatch(getUsers(queryData));
    } else {
      setSearch(value);
    }
  }, DEBOUNCE_TIMEOUT);
  const searchHandler = () => {
    const searchQueryData = {
      ...queryData,
      search: search
    };
    setQueryData(searchQueryData);
    dispatch(getUsers(searchQueryData));
  };
  useEffect(() => {
    if (search !== '') {
      searchHandler();
    }
  }, [search]);

  // role filter
  const roleChangeHandler = () => {
    const roleQueryData = {
      ...queryData,
      roleId: role
    };
    setQueryData(roleQueryData);
    dispatch(getUsers(roleQueryData));
  };
  useEffect(() => {
    if (role !== '' && role !== ALL_FILTERS) {
      roleChangeHandler();
    } else if (role === ALL_FILTERS && !isInitialRender) {
      // delete role id and fetch data for all roles
      delete queryData.roleId;
      dispatch(getUsers(queryData));
    }
  }, [role]);

  // status filter
  const statusChangeHandler = () => {
    const statusQueryData = {
      ...queryData,
      status: status
    };
    setQueryData(statusQueryData);
    dispatch(getUsers(statusQueryData));
  };
  useEffect(() => {
    if (status !== '' && status !== ALL_FILTERS) {
      statusChangeHandler();
    } else if (status === ALL_FILTERS && !isInitialRender) {
      // delete status and fetch data for all status
      delete queryData.status;
      dispatch(getUsers(queryData));
    }
  }, [status]);

  return (
    <>
      <div className="page-inner">
        <div className="page-content-block">
          <div className="inner-page-title-wrapper">
            <div className="title-left-block">
              <BreadCrumb links={breadcrumbsLinks} />
              <Label variant="h1" label={'Users'} />
            </div>
            <div className="title-right-block">
              <div className="right-block-inner">
                <Link
                  to={ROUTE_LIST.ADD_USER}
                  onClick={handleCreateUser}
                  className="primary-btn"
                  title="CREATE NEW USER">
                  + Create New User
                </Link>
              </div>
            </div>
          </div>
          <div className="white-box shadow-box page-table-wrapper">
            {isLoading && <PageLoader />}

            <div className="filter-wrapper">
              <div className="filter-left-block">
                <TextField
                  id="search"
                  label="Search"
                  type="search"
                  placeholder="Search here..."
                  name="search"
                  className="form-group search-field"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => handleSearch(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
              </div>
              <div className="filter-right-block">
                <div className="dropdown-wrapper">
                  <SingleSelect
                    placeholderText="Role"
                    items={userRolesList}
                    label="Role"
                    className="form-group search-field"
                    MenuProps={{ classes: { paper: 'custom-select-dropdown' } }}
                    name="role"
                    onChange={(e) => setRole(e.target.value)}
                    value={role}
                  />
                </div>
                <div className="dropdown-wrapper">
                  <SingleSelect
                    placeholderText="Status"
                    items={statusItems}
                    label="Status"
                    className="form-group search-field"
                    MenuProps={{ classes: { paper: 'custom-select-dropdown' } }}
                    name="status"
                    onChange={(e) => setStatus(e.target.value)}
                    value={status}
                  />
                </div>
              </div>
            </div>
            <div className="table-wrapper">
              <DataTable
                handlePagination={paginationChangeHandler}
                // loading={isLoading}
                total={paginationData && paginationData.total ? paginationData.total : 0}
                columns={columns}
                rows={usersData}
                checkboxSelection
                rowHeight={56}
                handleSorting={sortingChangeHandler}
                sortingMode="server"
                paginationMode="server"
                onRowSelectionModelChange={(selectedRow) => {
                  setRowSelectionModel(selectedRow);
                }}
                rowSelectionModel={rowSelectionModel}
              />
            </div>
          </div>
        </div>
      </div>
      <ConfirmDialog
        open={openDialog}
        onClose={closeDeleteDialog}
        dialogIcon={dialogDeleteIcon}
        dialogTitle={DIALOG.DELETE_DIALOG_TITLE}
        dialogBody={DIALOG.DELETE_DIALOG_BODY}
        buttons={buttonsList}
      />
      <ViewUserDialog
        open={viewUserOpen}
        dialogIcon={dialogCloseIcon}
        onClose={closeViewUserDialog}
        handleClose={closeViewUserDialog}
        userData={user}
      />
    </>
  );
};
