import { createSlice } from '@reduxjs/toolkit';
import { getUserRole, getUserStatus } from '../../utils/helper';

const initialState = {};

const adminSlice = createSlice({
  name: 'admin',
  initialState: initialState,
  reducers: {
    getUsers(state, action) {
      let usersData = action.payload.userData;
      const paginationData = action.payload.pagination;
      usersData = usersData.map((userItem) => {
        return {
          ...userItem,
          status: getUserStatus(userItem.status),
          role: getUserRole(userItem.roleId)
        };
      });
      return { ...state, usersData, paginationData };
    },
    getUserRoles(state, action) {
      return { ...state, userRoles: action.payload };
    },
    getEditUserDetails(state, action) {
      return { ...state, editUserData: action.payload };
    },
    getSettings(state, action) {
      return { ...state, settingsData: action.payload };
    },
    manageBrokerageOffice(state, action) {
      return { ...state, brokerageOffice: action.payload };
    }
  }
});
export const {
  getProfile,
  getUsers,
  getUserRoles,
  getEditUserDetails,
  getSettings,
  manageBrokerageOffice
} = adminSlice.actions;
export default adminSlice.reducer;
