import api from '../../utils/api';
import { API_ROUTES } from '../../constants/apiRoutes';
import { memberPaginationQueryData } from '../../constants/common';

export const getMembersApi = async (queryData = memberPaginationQueryData) => {
  try {
    const response = await api.get(API_ROUTES.GET_MEMBERS, { params: queryData });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
