import { InputAdornment, TextField } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux';
import { BreadCrumb } from '../components/BreadCrumb';
import { Label } from '../components/Label';
import { Button } from '../components/Button';
import { DataTable } from '../components/DataTable';
import { ViewPropertyDialog } from '../components/Popup/ViewPropertyDialog';
import { PageLoader } from '../components/PageLoader';
import { ConfirmDialog } from '../components/Popup/ConfirmDialog';
import { SingleSelect } from '../components/DropDown/SingleSelect';
import { ReassignTagDialog } from '../components/Popup/reassignTagDialog';
import { ViewHistoryDialog } from '../components/Popup/ViewHistoryDialog';
import { getRealtorBrokerageOfficeApi } from '../redux/apis/realtor';
import { getProperties } from '../redux/actions/property';
import { addPropertyTagRequest } from '../redux/actions/realtor';
import { deleteProperty } from '../redux/actions/property';
import { apiStatusClear } from '../redux/slices/apiStatus';
import {
  ALL_FILTERS,
  BREAD_CRUMB_LABELS,
  DEBOUNCE_TIMEOUT,
  defaultQueryData,
  DIALOG,
  LABELS,
  PROPERTY_TAG_ACTION,
  ROLE,
  TAG_REQUEST_ICON
} from '../constants/common';
import { ROUTE_LIST } from '../constants/routes';
import {
  deleteAction,
  dialogCloseIcon,
  dialogDeleteIcon,
  editAction,
  historyIcon,
  reassignTagIcon,
  tagTransferBlueIcon,
  tagTransferIcon,
  viewAction
} from '../constants/images';
import { debounce } from '../utils/helper';
import './../styles/inner-page-style.css';
import './../styles/data-table-style.css';
import './../styles/custom-dialog-style.css';

export const PropertyList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [property, setProperty] = useState();
  const [viewPropertyOpen, setviewPropertyOpen] = useState(false);
  const { propertiesData, paginationData } = useSelector((state) => state.property);
  const [queryData, setQueryData] = useState({ ...defaultQueryData });
  const [search, setSearch] = useState('');
  const { isLoading } = useSelector((state) => state.apiStatus);
  const { role, id: loggedInRealtorId } = useSelector((state) => state.auth);
  const [openDialog, setOpen] = useState(false);
  const [openReassignDialog, setOpenReassignDialog] = useState(false);
  const all_offices = {
    id: ALL_FILTERS,
    name: 'All Brokerage Office'
  };
  const [brokerageOfficeOptions, setBrokerageOfficeOptions] = useState([all_offices]);
  const [brokerageOffice, setBrokerageOffice] = useState(ALL_FILTERS);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [viewHistoryOpen, setViewHistoryOpen] = useState(false);
  const [openDeletePropertyDialog, setOpenDeletePropertyDialog] = useState(false);

  const breadcrumbsLinks = [
    {
      label: BREAD_CRUMB_LABELS.DASHBOARD,
      to: ROUTE_LIST.DASHBOARD
    },
    {
      label: BREAD_CRUMB_LABELS.PROPERTIES,
      to: ROUTE_LIST.PROPERTIES
    }
  ];

  useEffect(() => {
    dispatch(getProperties());
    getRealtorBrokerageOfficeApi().then((res) =>
      setBrokerageOfficeOptions([all_offices, ...res.data.data])
    );
    setIsInitialRender(false);
  }, []);

  // Delete property
  const openDeleteDialog = (params) => {
    setOpenDeletePropertyDialog(true);
    setProperty(params.row);
  };
  const closeDeleteDialog = () => {
    setOpenDeletePropertyDialog(false);
  };
  const deletePropertyHandler = () => {
    dispatch(
      deleteProperty({
        property: {
          ...property,
          role
        },
        queryData
      })
    );
    setOpenDeletePropertyDialog(false);
  };
  const buttonsList = [
    {
      name: 'Cancel',
      className: 'primary-btn border-btn',
      event: closeDeleteDialog
    },
    {
      name: 'Delete',
      className: 'primary-btn',
      event: deletePropertyHandler
    }
  ];

  // Edit property
  const editActionHandler = (params) => {
    navigate(`${ROUTE_LIST.MY_PROPERTIES}/edit/${params.id}`);
  };

  // View History
  const openViewHistoryDialog = (params) => {
    setProperty(params.row);
    setViewHistoryOpen(true);
  };
  const closeViewHistoryDialog = () => {
    setViewHistoryOpen(false);
  };

  // View Property
  const openViewPropertyDialog = (params) => {
    setProperty(params.row);
    setviewPropertyOpen(true);
  };
  const closeViewPropertyDialog = () => {
    setviewPropertyOpen(false);
  };

  // Request for tag transfer
  const openTagRequestDialog = (params) => {
    setOpen(true);
    setProperty(params.row);
  };
  const closeTagRequestDialog = () => {
    setOpen(false);
  };
  const tagRequestHandler = () => {
    dispatch(addPropertyTagRequest(property.id));
    setOpen(false);
  };
  const transferButtonsList = [
    {
      name: 'Cancel',
      className: 'primary-btn border-btn',
      event: closeTagRequestDialog
    },
    {
      name: 'Confirm',
      className: 'primary-btn',
      event: tagRequestHandler
    }
  ];

  // Reassign tag
  const openReassignTagDialog = (params) => {
    setOpenReassignDialog(true);
    setProperty(params.row);
  };
  const closeReassignTagDialog = () => {
    setOpenReassignDialog(false);
  };

  const columns = [
    { field: 'brokerageOfficeName', headerName: 'Brokerage Office', flex: 1, minWidth: 160 },
    { field: 'address', headerName: 'Address', flex: 1, minWidth: 200, sortable: false },
    { field: 'city', headerName: 'City', flex: 1, minWidth: 140 },
    { field: 'state', headerName: 'State', flex: 1, minWidth: 100 },
    { field: 'dateTagged', headerName: 'Tagged Date', flex: 1, minWidth: 120, sortable: false },
    {
      field: 'realtorName',
      headerName: 'Realtor',
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {/* Realtor can be null while release property tag */}
            {params.row.realtorFirstName !== null && params.row.realtorLastName !== null ? (
              <div className="MuiDataGrid-cellContent">{`${params.row.realtorFirstName} ${params.row.realtorLastName}`}</div>
            ) : (
              ''
            )}
          </>
        );
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 90,
      sortable: false,
      renderCell: (params) => {
        const statusClass = params.row.status.toLowerCase() || '';
        return (
          <>
            <span className={`status-badge ${statusClass}`}>{params.row.status}</span>
          </>
        );
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      minWidth: 200,
      headerClassName: 'action-col',
      cellClassName: 'action-col',
      sortable: false,
      headerAlign: 'right',
      align: 'right',
      renderCell: (params) => {
        return (
          <div className="action-column-wrapper">
            {role?.name === ROLE.REALTOR &&
              loggedInRealtorId !== params.row.realtorId &&
              TAG_REQUEST_ICON.SHOW === params.row.canSendTagRequest && (
                <>
                  <div className="action-column-inner">
                    <Button
                      title="Request for tag transfer"
                      className="edit-icon"
                      onClick={() => openTagRequestDialog(params)}
                      name={<img src={tagTransferIcon} alt="edit-icon" />}
                    />
                  </div>
                </>
              )}
            {role?.name === ROLE.ADMIN &&
              params.row.actionStatus === PROPERTY_TAG_ACTION.TAG_RELEASE && (
                <div className="action-column-inner">
                  <Button
                    title="Reassign the tag"
                    className="edit-icon"
                    onClick={() => openReassignTagDialog(params)}
                    name={<img src={reassignTagIcon} alt="reassign-tag-icon" />}
                  />
                </div>
              )}
            <div className="action-column-inner">
              <Button
                title="View"
                className="view-icon"
                onClick={() => openViewPropertyDialog(params)}
                name={<img src={viewAction} alt="view-icon" />}
              />
            </div>
            {(role?.name === ROLE.ADMIN || role?.name === ROLE.SALES_MANAGER) && (
              <>
                <div className="action-column-inner">
                  <Button
                    title="History"
                    className="edit-icon"
                    onClick={() => openViewHistoryDialog(params)}
                    name={<img src={historyIcon} alt="history-icon" />}
                  />
                </div>
                <div className="action-column-inner">
                  <Button
                    title="Edit"
                    className="edit-icon"
                    onClick={() => editActionHandler(params)}
                    name={<img src={editAction} alt="edit-icon" />}
                    disabled={!params.row.canEdit}
                  />
                </div>
                <div className="action-column-inner">
                  <Button
                    title="Delete"
                    className="delete-icon"
                    onClick={() => openDeleteDialog(params)}
                    name={<img src={deleteAction} alt="delete-icon" />}
                    disabled={!params.row.canEdit}
                  />
                </div>
              </>
            )}
          </div>
        );
      }
    }
  ];

  const paginationChangeHandler = (paginationModelData) => {
    const paginationQueryData = {
      ...queryData,
      page: paginationModelData.page + 1,
      perPage: paginationModelData.pageSize
    };
    setQueryData(paginationQueryData);
    dispatch(getProperties(paginationQueryData));
  };

  const sortingChangeHandler = (sortModelData) => {
    if (sortModelData && sortModelData.length > 0) {
      const sortQueryData = {
        ...queryData,
        sortField: sortModelData[0].field,
        sortDirection: sortModelData[0].sort
      };
      setQueryData(sortQueryData);
      dispatch(getProperties(sortQueryData));
    }
  };

  // Search users based on timeout seconds
  const handleSearch = debounce((value) => {
    if (value === '') {
      delete queryData.search;
      dispatch(getProperties(queryData));
    } else {
      setSearch(value);
    }
  }, DEBOUNCE_TIMEOUT);
  const searchHandler = () => {
    const searchQueryData = {
      ...queryData,
      search: search
    };
    setQueryData(searchQueryData);
    dispatch(getProperties(searchQueryData));
  };
  useEffect(() => {
    if (search !== '') {
      searchHandler();
    }
  }, [search]);

  // brokerage office filter
  const brokerageOfficeChangeHandler = () => {
    const brokerageOfficeQueryData = {
      ...queryData,
      brokerageOfficeId: brokerageOffice
    };
    setQueryData(brokerageOfficeQueryData);
    dispatch(getProperties(brokerageOfficeQueryData));
  };
  useEffect(() => {
    if (brokerageOffice !== '' && brokerageOffice !== ALL_FILTERS) {
      brokerageOfficeChangeHandler();
    } else if (brokerageOffice === ALL_FILTERS && !isInitialRender) {
      // delete brokerage office and fetch data for all brokerage office
      delete queryData.brokerageOfficeId;
      dispatch(getProperties(queryData));
    }
  }, [brokerageOffice]);

  const statusClearHandler = () => {
    dispatch(apiStatusClear());
  };
  return (
    <>
      <div className="page-inner">
        <div className="page-content-block">
          <div className="inner-page-title-wrapper">
            <div className="title-left-block">
              <BreadCrumb links={breadcrumbsLinks} />
              <Label variant="h1" label={LABELS.PROPERTIES} />
            </div>
            {role?.name !== ROLE.REALTOR && (
              <div className="title-right-block">
                <div className="right-block-inner">
                  <Link
                    to={ROUTE_LIST.CREATE_PROPERTY}
                    onClick={statusClearHandler}
                    className="primary-btn"
                    title="Create New Property">
                    + Create New Property
                  </Link>
                </div>
              </div>
            )}
          </div>
          <div className="white-box shadow-box page-table-wrapper">
            {isLoading && <PageLoader />}
            <div className="filter-wrapper">
              <div className="filter-left-block">
                <TextField
                  id="search"
                  label="Search"
                  type="search"
                  placeholder="Search here..."
                  name="search"
                  className="form-group search-field"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => handleSearch(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
              </div>
              <div className="filter-right-block">
                <div className="dropdown-wrapper has-large-dropdown">
                  <SingleSelect
                    placeholderText="Brokerage Office"
                    items={brokerageOfficeOptions}
                    label="Brokerage Office"
                    className="form-group search-field"
                    MenuProps={{ classes: { paper: 'custom-select-dropdown' } }}
                    name="brokerageOffice"
                    onChange={(e) => setBrokerageOffice(e.target.value)}
                    value={brokerageOffice}
                  />
                </div>
              </div>
            </div>
            <div className="table-wrapper no-checkbox">
              <DataTable
                handlePagination={paginationChangeHandler}
                total={paginationData && paginationData.total ? paginationData.total : 0}
                columns={columns}
                rows={propertiesData}
                rowHeight={56}
                handleSorting={sortingChangeHandler}
                sortingMode="server"
                paginationMode="server"
              />
            </div>
          </div>
        </div>
      </div>
      <ConfirmDialog
        open={openDialog}
        onClose={closeTagRequestDialog}
        dialogIcon={tagTransferBlueIcon}
        dialogTitle={DIALOG.TAG_TRANSFER_REQUEST_DIALOG_TITLE}
        dialogBody={DIALOG.TAG_TRANSFER_REQUEST_DIALOG_BODY}
        buttons={transferButtonsList}
      />
      <ViewPropertyDialog
        open={viewPropertyOpen}
        dialogIcon={dialogCloseIcon}
        onClose={closeViewPropertyDialog}
        handleClose={closeViewPropertyDialog}
        propertyData={property}
      />
      <ReassignTagDialog
        open={openReassignDialog}
        dialogIcon={dialogCloseIcon}
        onClose={closeReassignTagDialog}
        handleClose={closeReassignTagDialog}
        propertyData={property}
        setOpenReassignDialog={setOpenReassignDialog}
      />
      <ConfirmDialog
        open={openDeletePropertyDialog}
        onClose={closeDeleteDialog}
        dialogIcon={dialogDeleteIcon}
        dialogTitle={DIALOG.DELETE_PROPERTY_DIALOG_TITLE}
        dialogBody={DIALOG.DELETE_DIALOG_BODY}
        buttons={buttonsList}
      />
      <ViewHistoryDialog
        open={viewHistoryOpen}
        dialogIcon={dialogCloseIcon}
        onClose={closeViewHistoryDialog}
        handleClose={closeViewHistoryDialog}
        propertyData={property}
      />
    </>
  );
};
