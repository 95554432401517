import {
  DELETE_DRAFT_EMAIL_BLAST_REQUEST,
  EDIT_DRAFT_EMAIL_BLAST_REQUEST,
  GET_DRAFT_EMAIL_BLAST_LIST_REQUEST,
  GET_EMAIL_BLAST_REQUEST,
  SAVE_DRAFT_EMAIL_BLAST_REQUEST
} from '../../constants/redux';

export const getEmailBlasts = (queryData) => {
  return {
    type: GET_EMAIL_BLAST_REQUEST,
    payload: queryData
  };
};
export const draftEmailBlastList = (queryData) => {
  return {
    type: GET_DRAFT_EMAIL_BLAST_LIST_REQUEST,
    payload: queryData
  };
};

export const draftEmailBlast = (emailBlastData) => {
  return {
    type: SAVE_DRAFT_EMAIL_BLAST_REQUEST,
    payload: emailBlastData
  };
};

export const editDraftEmailBlast = (emailBlastId, emailBlastData) => {
  return {
    type: EDIT_DRAFT_EMAIL_BLAST_REQUEST,
    payload: { emailBlastId, emailBlastData }
  };
};

export const deleteDraftEmailBlast = (emailBlastData) => {
  return {
    type: DELETE_DRAFT_EMAIL_BLAST_REQUEST,
    payload: emailBlastData
  };
};
