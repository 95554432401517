import { Dialog, DialogContent } from '@mui/material';
import PropTypes from 'prop-types';
import { BrokerageOfficeRealtorsList } from '../../pages/brokerageOfficeRealtorsList';
import { Label } from '../Label';

export const ViewBrokerageOfficeDialog = ({
  open,
  handleClose,
  classes,
  dialogIcon,
  brokerageOfficeData,
  ...restProps
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="View-user-wrapper view-brokerage-wrapper custom-dialog"
      classes={classes}
      {...restProps}>
      <div className="dialog-content-wrapper">
        <div className="title-wrapper">
          <Label variant="h3" label="View Brokerage Office" />
          <button className="close-icon" onClick={handleClose} name="close">
            <img src={dialogIcon} alt="close icon" />
          </button>
        </div>
        <DialogContent>
          <div className="user-detail-wrapper">
            <div className="single-detail-wrapper">
              <span className="label">Name</span>
              <div className="detail">
                <p>{brokerageOfficeData?.name}</p>
              </div>
            </div>
            <div className="single-detail-wrapper">
              <span className="label">Sales Manager</span>
              <div className="detail">
                <p>
                  {`${brokerageOfficeData?.salesManagerFirstName} ${brokerageOfficeData?.salesManagerLastName}`}
                </p>
              </div>
            </div>
            <div className="single-detail-wrapper">
              <span className="label">Keap Brokerage Subscribe Tag Id</span>
              <div className="detail">
                <p>{brokerageOfficeData?.tagId}</p>
              </div>
            </div>
            <div className="single-detail-wrapper">
              <span className="label">Is Brokerage office in Texas?</span>
              <div className="detail">
                <p>{brokerageOfficeData?.isTexasBrokerageOffice ? 'Yes' : 'No'}</p>
              </div>
            </div>
            <div className="single-detail-wrapper">
              <span className="label">Is Brokerage Office Multifamily or Commercial?</span>
              <div className="detail">
                <p>{brokerageOfficeData?.isMultifamilyAndCommercial ? 'Yes' : 'No'}</p>
              </div>
            </div>
            {brokerageOfficeData?.address !== '' && (
              <div className="single-detail-wrapper">
                <span className="label">Address</span>
                <div className="detail">
                  <p>{brokerageOfficeData?.address}</p>
                </div>
              </div>
            )}
            {brokerageOfficeData?.zip !== '' && (
              <div className="single-detail-wrapper">
                <span className="label">Zip Code</span>
                <div className="detail">
                  <p>{brokerageOfficeData?.zip}</p>
                </div>
              </div>
            )}
          </div>
          <BrokerageOfficeRealtorsList brokerageOfficeId={brokerageOfficeData?.id} />
        </DialogContent>
      </div>
    </Dialog>
  );
};

ViewBrokerageOfficeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  classes: PropTypes.object,
  dialogIcon: PropTypes.string,
  brokerageOfficeData: PropTypes.object
};
