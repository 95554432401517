import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const DashboardSlice = createSlice({
  name: 'dashboard',
  initialState: initialState,
  reducers: {
    getDashboardStatistics(state, action) {
      return { ...state, ...action.payload };
    }
  }
});
export const { getDashboardStatistics } = DashboardSlice.actions;
export default DashboardSlice.reducer;
