import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from '@redux-saga/core';

// RootSaga
import rootSaga from './sagas/root';

// Reducers
import apiStatusReducer from './slices/apiStatus';
import authReducer from './slices/auth';
import adminReducer from './slices/admin';
import brokerageOfficeReducer from './slices/brokerageOffice';
import propertyReducer from './slices/property';
import realtorReducer from './slices/realtor';
import memberReducer from './slices/member';
import dashboardReducer from './slices/dashboard';
import emailBlastReducer from './slices/emailBlast';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    apiStatus: apiStatusReducer,
    auth: authReducer,
    admin: adminReducer,
    brokerageOffice: brokerageOfficeReducer,
    property: propertyReducer,
    realtor: realtorReducer,
    member: memberReducer,
    dashboard: dashboardReducer,
    emailBlast: emailBlastReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: false }).concat(sagaMiddleware)
});

sagaMiddleware.run(rootSaga);
