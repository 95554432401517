import { whiteLoader } from './../constants/images.js';
export const ButtonLoader = () => {
  return (
    <>
      <em className="btn-loader">
        <img src={whiteLoader} alt="loader" />
      </em>
    </>
  );
};
