import { Button as MuiButton } from '@mui/material';
import PropTypes from 'prop-types';
import { ButtonLoader } from './ButtonLoader';

export const Button = ({
  className,
  name,
  onClick,
  disabled,
  color = 'primary',
  variant = 'contained',
  isLoading,
  ...restProps
}) => {
  return (
    <MuiButton
      className={isLoading ? `${className} loader` : className}
      color={color}
      disabled={disabled}
      variant={variant}
      onClick={onClick}
      {...restProps}>
      {name}
      {isLoading ? <ButtonLoader /> : ''}
    </MuiButton>
  );
};

// Validation of provided Prop types.
Button.propTypes = {
  className: PropTypes.string,
  name: PropTypes.any,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  restProps: PropTypes.object,
  isLoading: PropTypes.bool,
  variant: PropTypes.oneOf(['text', 'contained', 'outlined']),
  color: PropTypes.oneOf(['inherit', 'primary', 'secondary', 'success', 'error', 'info', 'warning'])
};
