import { useFormik } from 'formik';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { Label } from '../components/Label';
import { TextField } from '../components/TextField';
import { Button } from '../components/Button';
import { BreadCrumb } from '../components/BreadCrumb';
import { PhoneNumberInput } from '../components/PhoneNumberInput';
import { ChangePassword } from './Admin/changePassword';
import { getProfile, updateProfile } from '../redux/actions/auth';
import { updateProfileSchema } from '../schemas/adminSchema';
import { DEBOUNCE_TIMEOUT, LABELS, ROLE } from '../constants/common';
import { ROUTE_LIST } from '../constants/routes';
import 'react-phone-number-input/style.css';
import './../styles/inner-page-style.css';
import './../styles/form-box-layout-style.css';
import { AutoCompleteMultiSelect } from '../components/DropDown/AutoCompleteMultiSelect';
import { getBrokerageOfficesListApi } from '../redux/apis/brokerageOffice';
import { debounce } from '../utils/helper';

export const Profile = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth);
  const { isLoading } = useSelector((state) => state.apiStatus);
  const [isProfileSaved, setIsProfileSaved] = useState(false);
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const [brokerageOfficeData, setBrokerageOfficeData] = useState([]);
  const [brokerageOfficeSearch, setBrokerageOfficeSearch] = useState('');

  const breadcrumbsLinks = [
    {
      label: 'Dashboard',
      to: ROUTE_LIST.DASHBOARD
    },
    {
      label: 'Profile',
      last: true
    }
  ];

  useEffect(() => {
    if (!userData.firstName) {
      dispatch(getProfile());
    }
  }, [dispatch]);

  useEffect(() => {
    // Get brokerage office lists for options
    getBrokerageOfficesListApi().then((res) => {
      const responseData = res.data.data;
      const brokerageArray = [];
      responseData.forEach(({ id, name }) => {
        brokerageArray.push({
          value: id,
          label: name
        });
      });
      setBrokerageOfficeData(brokerageArray);
    });
  }, []);

  // Search realtors based on timeout seconds
  const handleBrokerageOfficeSearch = debounce((value) => {
    setBrokerageOfficeSearch(value);
  }, DEBOUNCE_TIMEOUT);

  const brokerageOfficeSearchHandler = () => {
    getBrokerageOfficesListApi({
      search: brokerageOfficeSearch
    }).then((res) => {
      let resData = res.data.data;
      setBrokerageOfficeData(resData.map((data) => ({ label: data.name, value: data.id })));
    });
  };

  useEffect(() => {
    if (brokerageOfficeSearch !== '' && brokerageOfficeSearch !== 0) {
      brokerageOfficeSearchHandler();
    }
  }, [brokerageOfficeSearch]);

  const initialValues = {
    firstName: userData?.firstName || '',
    lastName: userData?.lastName || '',
    email: userData?.email || '',
    phone: userData?.phone || '',
    role: userData?.role?.id || '',
    brokerageOffices: userData?.brokerageOffices || [],
    IABS: userData?.IABS || ''
    // confidentialityAgreementLink: userData?.confidentialityAgreementLink || ''
  };

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    setFieldValue,
    resetForm
  } = useFormik({
    initialValues,
    validationSchema: updateProfileSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(updateProfile(values));
      setIsPasswordChanged(false);
      setIsProfileSaved(true);
    }
  });

  // Validate phone number based on country code
  useMemo(() => {
    if (!isValidPhoneNumber(values.phone)) {
      errors.phone = 'Please enter valid phone number';
    } else {
      delete errors.phone;
    }
  }, [values.phone, errors]);

  return (
    <div className="page-inner">
      <div className="page-content-block profile-page">
        <div className="inner-page-title-wrapper">
          <div className="title-left-block">
            <BreadCrumb links={breadcrumbsLinks} />
            <Label variant="h1" label={LABELS.MY_PROFILE} />
          </div>
        </div>
        <div className="white-box shadow-box">
          <form onSubmit={handleSubmit}>
            <div className="form-row-wrapper">
              <div className="form-col has-required">
                <TextField
                  fullWidth
                  id="fname"
                  label="First Name"
                  placeholder="First Name"
                  name="firstName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                  error={touched.firstName && Boolean(errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                  className="form-group"
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className="form-col has-required">
                <TextField
                  fullWidth
                  id="lname"
                  label="Last Name"
                  placeholder="Last Name"
                  name="lastName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                  error={touched.lastName && Boolean(errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                  className="form-group"
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className="form-col has-required">
                <TextField
                  fullWidth
                  id="email"
                  label="Email"
                  placeholder="Email Address"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  className="form-group"
                  InputLabelProps={{ shrink: true }}
                  disabled
                />
              </div>
              <div className="form-col has-required">
                <PhoneNumberInput
                  placeholderText="Phone Number"
                  name="phone"
                  label="Phone"
                  onChange={setFieldValue}
                  onBlur={handleBlur}
                  value={values.phone}
                  className={
                    touched.phone && Boolean(errors.phone) ? 'form-group error' : 'form-group'
                  }
                  error={touched.phone && Boolean(errors.phone)}
                  helperText={touched.phone && errors.phone}
                />
              </div>
              {userData?.role?.name !== ROLE.ADMIN && (
                <div
                  className={
                    touched.brokerageOffices && Boolean(errors.brokerageOffices)
                      ? 'form-col error'
                      : 'form-col'
                  }>
                  <AutoCompleteMultiSelect
                    freeSolo // user input is not bound with provided option. Added this because of getting warning of unmatched options.
                    filterOptions={(options) => options}
                    name="subscriptions"
                    className="multi-select"
                    options={brokerageOfficeData}
                    label="My Subscriptions"
                    classes={{ paper: 'custom-select-dropdown multiselect-dropdown' }}
                    placeholder={values.brokerageOffices.length > 0 ? '' : 'My Subscriptions'}
                    onChange={(event, value) => {
                      if (value && value.length > 0) {
                        setFieldValue('brokerageOffices', value);
                      } else {
                        setFieldValue('brokerageOffices', []);
                        if (!value.length) {
                          getBrokerageOfficesListApi().then((res) => {
                            setBrokerageOfficeData(
                              res.data.data.map((data) => ({ label: data.name, value: data.id }))
                            );
                          });
                        }
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.brokerageOffices}
                    error={touched.brokerageOffices && Boolean(errors.brokerageOffices)}
                    helperText={touched.brokerageOffices && errors.brokerageOffices}
                    onInputChange={(e, value, reason) => {
                      handleBrokerageOfficeSearch(e.target.value);
                      if (reason === 'reset') {
                        getBrokerageOfficesListApi().then((res) => {
                          setBrokerageOfficeData(
                            res.data.data.map((data) => ({ label: data.name, value: data.id }))
                          );
                        });
                      }
                    }}
                  />
                </div>
              )}
              {userData?.role?.name !== ROLE.ADMIN && (
                <div className="form-col has-required">
                  <TextField
                    fullWidth
                    id="IABS"
                    label="IABS"
                    placeholder="IABS"
                    name="IABS"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.IABS}
                    error={touched.IABS && Boolean(errors.IABS)}
                    helperText={touched.IABS && errors.IABS}
                    className="form-group"
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
              )}
            </div>

            <div className="form-btn-wrapper">
              <Button className="primary-btn border-btn" name="Cancel" onClick={resetForm} />
              <Button
                className="primary-btn"
                type="submit"
                name="Save"
                disabled={isLoading && isProfileSaved}
                isLoading={isLoading && isProfileSaved}
              />
            </div>
          </form>
        </div>
        <ChangePassword
          handleSaveProfile={setIsProfileSaved}
          isPasswordChanged={isPasswordChanged}
          handlePasswordChange={setIsPasswordChanged}
        />
      </div>
    </div>
  );
};
