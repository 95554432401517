import { InputAdornment, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { BreadCrumb } from '../../components/BreadCrumb';
import { ConfirmDialog } from '../../components/Popup/ConfirmDialog';
import { Label } from '../../components/Label';
import { DataTable } from '../../components/DataTable';
import { Button } from '../../components/Button';
import { ViewPropertyDialog } from '../../components/Popup/ViewPropertyDialog';
import { PageLoader } from '../../components/PageLoader';
import { ViewHistoryDialog } from '../../components/Popup/ViewHistoryDialog';
import { apiStatusClear } from '../../redux/slices/apiStatus';
import { myProperties } from '../../redux/actions/realtor';
import { deleteProperty } from '../../redux/actions/property';
import {
  BREAD_CRUMB_LABELS,
  DEBOUNCE_TIMEOUT,
  defaultQueryData,
  DIALOG,
  LABELS
} from '../../constants/common';
import { ROUTE_LIST } from '../../constants/routes';
import {
  deleteAction,
  dialogCloseIcon,
  dialogDeleteIcon,
  editAction,
  historyIcon,
  viewAction
} from '../../constants/images';
import { debounce } from '../../utils/helper';
import './../../styles/inner-page-style.css';
import './../../styles/data-table-style.css';
import './../../styles/custom-dialog-style.css';

export const MyPropertyList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openDialog, setOpen] = useState(false);
  const [property, setProperty] = useState();
  const [viewPropertyOpen, setviewPropertyOpen] = useState(false);
  const [search, setSearch] = useState('');
  const { myPropertiesData, paginationData } = useSelector((state) => state.realtor);
  const [queryData, setQueryData] = useState({ ...defaultQueryData });
  const { isLoading } = useSelector((state) => state.apiStatus);
  const [viewHistoryOpen, setViewHistoryOpen] = useState(false);
  const { role } = useSelector((state) => state.auth);

  const breadcrumbsLinks = [
    {
      label: BREAD_CRUMB_LABELS.DASHBOARD,
      to: ROUTE_LIST.DASHBOARD
    },
    {
      label: BREAD_CRUMB_LABELS.MY_PROPERTIES,
      last: true
    }
  ];

  useEffect(() => {
    dispatch(myProperties(queryData));
  }, []);

  // Delete property
  const openDeleteDialog = (params) => {
    setOpen(true);
    setProperty(params.row);
  };
  const closeDeleteDialog = () => {
    setOpen(false);
  };
  const deletePropertyHandler = () => {
    dispatch(
      deleteProperty({
        property: {
          ...property,
          role
        },
        queryData
      })
    );
    setOpen(false);
  };
  const buttonsList = [
    {
      name: 'Cancel',
      className: 'primary-btn border-btn',
      event: closeDeleteDialog
    },
    {
      name: 'Delete',
      className: 'primary-btn',
      event: deletePropertyHandler
    }
  ];

  // Edit property
  const editActionHandler = (params) => {
    navigate(`${ROUTE_LIST.MY_PROPERTIES}/edit/${params.id}`);
  };

  // View Property
  const openViewPropertyDialog = (params) => {
    setProperty(params.row);
    setviewPropertyOpen(true);
  };
  const closeViewPropertyDialog = () => {
    setviewPropertyOpen(false);
  };

  // View History
  const openViewHistoryDialog = (params) => {
    setProperty(params.row);
    setViewHistoryOpen(true);
  };
  const closeViewHistoryDialog = () => {
    setViewHistoryOpen(false);
  };

  const columns = [
    { field: 'brokerageOfficeName', headerName: 'Brokerage Office', flex: 1, minWidth: 160 },
    { field: 'address', headerName: 'Address', flex: 1, minWidth: 200, sortable: false },
    { field: 'city', headerName: 'City', flex: 1, minWidth: 140 },
    { field: 'state', headerName: 'State', flex: 1, minWidth: 100 },
    { field: 'dateTagged', headerName: 'Tagged Date', flex: 1, minWidth: 120, sortable: false },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 90,
      sortable: false,
      renderCell: (params) => {
        const statusClass = params.row.status.toLowerCase() || '';
        return (
          <>
            <span className={`status-badge ${statusClass}`}>{params.row.status}</span>
          </>
        );
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      minWidth: 150,
      headerClassName: 'action-col',
      cellClassName: 'action-col',
      sortable: false,
      headerAlign: 'right',
      align: 'right',
      renderCell: (params) => {
        return (
          <div className="action-column-wrapper">
            <div className="action-column-inner">
              <Button
                title="View"
                className="view-icon"
                onClick={() => openViewPropertyDialog(params)}
                name={<img src={viewAction} alt="view-icon" />}
              />
            </div>
            <div className="action-column-inner">
              <Button
                title="History"
                className="edit-icon"
                onClick={() => openViewHistoryDialog(params)}
                name={<img src={historyIcon} alt="history-icon" />}
              />
            </div>
            <div className="action-column-inner">
              <Button
                title="Edit"
                className="edit-icon"
                onClick={() => editActionHandler(params)}
                name={<img src={editAction} alt="edit-icon" />}
              />
            </div>
            <div className="action-column-inner">
              <Button
                title="Delete"
                className="delete-icon"
                onClick={() => openDeleteDialog(params)}
                name={<img src={deleteAction} alt="delete-icon" />}
              />
            </div>
          </div>
        );
      }
    }
  ];

  const statusClearHandler = () => {
    dispatch(apiStatusClear());
  };

  const paginationChangeHandler = (paginationModelData) => {
    const paginationQueryData = {
      ...queryData,
      page: paginationModelData.page + 1,
      perPage: paginationModelData.pageSize
    };
    setQueryData(paginationQueryData);
    dispatch(myProperties(paginationQueryData));
  };

  const sortingChangeHandler = (sortModelData) => {
    if (sortModelData && sortModelData.length > 0) {
      const sortQueryData = {
        ...queryData,
        sortField: sortModelData[0].field,
        sortDirection: sortModelData[0].sort
      };
      setQueryData(sortQueryData);
      dispatch(myProperties(sortQueryData));
    }
  };

  // Search users based on timeout seconds
  const handleSearch = debounce((value) => {
    if (value === '') {
      delete queryData.search;
      dispatch(myProperties(queryData));
    } else {
      setSearch(value);
    }
  }, DEBOUNCE_TIMEOUT);
  const searchHandler = () => {
    const searchQueryData = {
      ...queryData,
      search: search
    };
    setQueryData(searchQueryData);
    dispatch(myProperties(searchQueryData));
  };
  useEffect(() => {
    if (search !== '') {
      searchHandler();
    }
  }, [search]);

  return (
    <>
      <div className="page-inner">
        <div className="page-content-block">
          <div className="inner-page-title-wrapper">
            <div className="title-left-block">
              <BreadCrumb links={breadcrumbsLinks} />
              <Label variant="h1" label={LABELS.MY_PROPERTIES} />
            </div>
            <div className="title-right-block">
              <div className="right-block-inner">
                <Link
                  to={ROUTE_LIST.CREATE_PROPERTY}
                  onClick={statusClearHandler}
                  className="primary-btn"
                  title="Create New Property">
                  + Create New Property
                </Link>
                <Link
                  to={ROUTE_LIST.VIEW_TAG_REQUEST}
                  onClick={statusClearHandler}
                  className="primary-btn"
                  title="View Tag Request">
                  View Tag Requests
                </Link>
                <Link
                  to={ROUTE_LIST.GET_NOTIFICATIONS}
                  onClick={statusClearHandler}
                  className="primary-btn"
                  title="Notifications">
                  Notifications
                </Link>
              </div>
            </div>
          </div>
          <div className="white-box shadow-box page-table-wrapper">
            {isLoading && <PageLoader />}
            <div className="filter-wrapper">
              <div className="filter-left-block">
                <TextField
                  id="search"
                  label="Search"
                  type="search"
                  placeholder="Search here..."
                  name="search"
                  className="form-group search-field"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => handleSearch(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
              </div>
            </div>
            <div className="table-wrapper no-checkbox">
              <DataTable
                handlePagination={paginationChangeHandler}
                total={paginationData && paginationData.total ? paginationData.total : 0}
                columns={columns}
                rows={myPropertiesData}
                rowHeight={56}
                handleSorting={sortingChangeHandler}
                sortingMode="server"
                paginationMode="server"
              />
            </div>
          </div>
        </div>
      </div>
      <ConfirmDialog
        open={openDialog}
        onClose={closeDeleteDialog}
        dialogIcon={dialogDeleteIcon}
        dialogTitle={DIALOG.DELETE_PROPERTY_DIALOG_TITLE}
        dialogBody={DIALOG.DELETE_DIALOG_BODY}
        buttons={buttonsList}
      />
      <ViewPropertyDialog
        open={viewPropertyOpen}
        dialogIcon={dialogCloseIcon}
        onClose={closeViewPropertyDialog}
        handleClose={closeViewPropertyDialog}
        propertyData={property}
      />
      <ViewHistoryDialog
        open={viewHistoryOpen}
        dialogIcon={dialogCloseIcon}
        onClose={closeViewHistoryDialog}
        handleClose={closeViewHistoryDialog}
        propertyData={property}
      />
    </>
  );
};
