import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { defaultQueryData } from '../constants/common';

export const DataTable = ({
  columns = [],
  rows = [],
  currentPage = 0,
  classes,
  total,
  loading = false,
  paginationMode = 'client',
  pageSizeCustom = defaultQueryData.perPage,
  paginationModel,
  handlePagination,
  sortingMode = 'client',
  sortModel,
  handleSorting,
  checkbox = false,
  rowSelectionModel,
  handleRowSelection,
  keepRowSelected = true,
  ...restProps
}) => {
  const handleSortModelChange = (sortModel) => {
    if (handleSorting) handleSorting(sortModel);
  };

  const handlePaginationModelChange = (paginationModel) => {
    if (handlePagination) handlePagination(paginationModel);
  };

  const handleRowSelectionModelChange = (rowSelectionModel) => {
    if (handleRowSelection) handleRowSelection(rowSelectionModel);
  };
  return (
    <DataGrid
      disableRowSelectionOnClick
      rows={rows}
      columns={columns}
      headerHeight={52}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: pageSizeCustom,
            page: currentPage
          }
        },
        sorting: {
          sortModel: sortModel
        }
      }}
      paginationMode={paginationMode}
      paginationModel={paginationModel}
      onPaginationModelChange={handlePaginationModelChange}
      pageSize={pageSizeCustom}
      pageSizeOptions={[pageSizeCustom, 25, 50, 100]}
      autoHeight
      classes={classes}
      disableColumnMenu={true}
      rowCount={total}
      loading={loading}
      sortingMode={sortingMode}
      sortModel={sortModel}
      onSortModelChange={handleSortModelChange}
      checkboxSelection={checkbox}
      rowSelectionModel={rowSelectionModel}
      onRowSelectionModelChange={handleRowSelectionModelChange}
      keepNonExistentRowsSelected={keepRowSelected}
      components={{
        NoRowsOverlay: () => <p className="no-data-found">No Data Found</p>
      }}
      {...restProps}
    />
  );
};

DataTable.propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,
  currentPage: PropTypes.number,
  classes: PropTypes.object,
  total: PropTypes.number,
  loading: PropTypes.bool,
  paginationMode: PropTypes.oneOf(['client', 'server']),
  pageSizeCustom: PropTypes.number,
  paginationModel: PropTypes.shape({
    page: PropTypes.number,
    pageSize: PropTypes.number
  }),
  handlePagination: PropTypes.func,
  sortingMode: PropTypes.oneOf(['client', 'server']),
  sortModel: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      sort: PropTypes.oneOf(['asc', 'desc'])
    })
  ),
  handleSorting: PropTypes.func,
  checkbox: PropTypes.bool,
  rowSelectionModel: PropTypes.array,
  handleRowSelection: PropTypes.func,
  keepRowSelected: PropTypes.bool
};
