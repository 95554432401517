import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export const OpenFileInNewTab = ({ fileUrl, fileName }) => {
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = fileUrl;
    document.body.appendChild(link);
    link.target = '_blank'; // open in new tab
    link.click();

    // clean up anshor element
    document.body.removeChild(link);
  };

  return (
    <Link to="#" onClick={handleDownload}>
      {fileName}
    </Link>
  );
};

OpenFileInNewTab.propTypes = {
  fileUrl: PropTypes.string,
  fileName: PropTypes.any
};
