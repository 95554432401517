import {
  GET_BROKERAGE_OFFICES_DETAIL_REQUEST,
  GET_BROKERAGE_OFFICES_LIST_REQUEST,
  GET_BROKERAGE_OFFICES_REQUEST,
  GET_BROKERAGE_OFFICE_BLAST_PERMISSIONS,
  GET_BROKERAGE_OFFICE_REALTORS_REQUEST
} from '../../constants/redux';

export const getBrokerageOffices = (queryData) => {
  return {
    type: GET_BROKERAGE_OFFICES_REQUEST,
    payload: queryData
  };
};

export const getBrokerageOfficeRealtors = (queryData, brokerageOfficeId) => {
  return {
    type: GET_BROKERAGE_OFFICE_REALTORS_REQUEST,
    payload: { queryData, brokerageOfficeId }
  };
};

export const getBlastPermissionsBrokerageOffices = (queryData, brokerageOfficeId) => {
  return {
    type: GET_BROKERAGE_OFFICE_BLAST_PERMISSIONS,
    payload: { queryData, brokerageOfficeId }
  };
};

export const getBrokerageOfficeDetails = (brokerageOfficeId) => {
  return {
    type: GET_BROKERAGE_OFFICES_DETAIL_REQUEST,
    payload: { brokerageOfficeId }
  };
};

export const getBrokerageOfficesList = (queryData) => {
  return {
    type: GET_BROKERAGE_OFFICES_LIST_REQUEST,
    payload: queryData
  };
};
