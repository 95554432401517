import { Dialog, DialogContent, IconButton, TextField, Tooltip } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BreadCrumb } from '../components/BreadCrumb';
import { Button } from '../components/Button';
import { SingleSelect } from '../components/DropDown/SingleSelect';
import { Label } from '../components/Label';
import { AutoCompleteSingleSelect } from '../components/DropDown/AutoCompleteSingleSelect';
import { getRoleBasedUsersApi } from '../redux/apis/admin';
import { addProperty } from '../redux/actions/property';
import { store } from '../redux/store';
import { manageProperty } from '../redux/slices/property';
import { getRealtorBrokerageOfficeApi, validatePropertyApi } from '../redux/apis/realtor';
import {
  BREAD_CRUMB_LABELS,
  DEBOUNCE_TIMEOUT,
  LABELS,
  MESSAGES,
  REALTOR_QUERY_DATA,
  ROLE,
  STAGE_LABELS,
  STATUS_ITEMS
} from '../constants/common';
import { ROUTE_LIST } from '../constants/routes';
import { debounce } from '../utils/helper';
import { addPropertySchema } from '../schemas/propertySchema';
import './../styles/inner-page-style.css';
import './../styles/form-box-layout-style.css';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';

export const CreateProperty = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [brokerageOfficeOptions, setBrokerageOfficeOptions] = useState([]);
  const [isValidatedAddress, setIsValidateAddress] = useState(false);
  const [validateLoading, setValidateLoading] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  const { isSucceed, isFailed } = useSelector((state) => state.apiStatus);
  const { propertyFlag: property } = useSelector((state) => state.property);
  const { role } = useSelector((state) => state.auth);
  const [realtorOptions, setRealtorOptions] = useState([]);
  const [realtorData, setRealtorData] = useState([]);
  const [realtorSearch, setRealtorSearch] = useState('');
  const [stageArray, setStageArray] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const breadcrumbsLinks = [
    {
      label: BREAD_CRUMB_LABELS.DASHBOARD,
      to: ROUTE_LIST.DASHBOARD
    },
    {
      label:
        role?.name === ROLE.REALTOR
          ? BREAD_CRUMB_LABELS.MY_PROPERTIES
          : BREAD_CRUMB_LABELS.PROPERTIES,
      to: role?.name === ROLE.REALTOR ? ROUTE_LIST.MY_PROPERTIES : ROUTE_LIST.PROPERTIES
    },
    {
      label: BREAD_CRUMB_LABELS.CREATE_PROPERTY,
      last: true
    }
  ];
  useEffect(() => {
    store.dispatch(manageProperty({ isPropertyAdded: false }));
  }, []);

  useEffect(() => {
    const stageItemsArr = [];
    Object.keys(STAGE_LABELS).map((item) => {
      stageItemsArr.push({
        id: STAGE_LABELS[item],
        name: STAGE_LABELS[item]
      });
    });
    stageItemsArr.sort((a, b) => a.name.localeCompare(b.name));
    setStageArray(stageItemsArr);
  }, [STAGE_LABELS]);

  useEffect(() => {
    if (role?.name === ROLE.ADMIN || role?.name === ROLE.SALES_MANAGER) {
      getRoleBasedUsersApi({
        ...REALTOR_QUERY_DATA,
        filter: 'salesManagerRealtors'
      }).then((res) => setRealtorData(res.data.data.userData));
    }

    if (role?.name === ROLE.REALTOR) {
      getRealtorBrokerageOfficeApi().then((res) => setBrokerageOfficeOptions(res.data.data));
    }
  }, [role]);

  useEffect(() => {
    if (isSucceed && isAdded && property?.isPropertyAdded) {
      role?.name === ROLE.REALTOR
        ? navigate(ROUTE_LIST.MY_PROPERTIES)
        : navigate(ROUTE_LIST.PROPERTIES);
    }

    if (isFailed && !property?.isPropertyAdded) {
      setFieldValue('address', '');
      setFieldValue('zipCode5', '');
      setFieldValue('city', '');
      setFieldValue('state', '');
    }
  }, [isAdded, isSucceed, property, isFailed]);

  const initialValues = {
    address: '',
    zipCode5: '',
    city: '',
    state: '',
    source: '',
    note: '',
    status: STATUS_ITEMS[0].id || '',
    stage: '',
    brokerageOffice: '',
    realtor: '',
    role: ''
  };

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    setFieldValue,
    setFieldTouched
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: addPropertySchema,
    onSubmit: (values) => {
      dispatch(addProperty({ ...values, zip5: values.zipCode5 }));
      if (!isFailed) setIsAdded(true);
    }
  });

  const validateAddressHandler = () => {
    // set error if address fields are empty
    setFieldTouched('address');
    setFieldTouched('city');
    setFieldTouched('state');
    if (values.address && values.city && values.state) {
      setValidateLoading(true);
      validatePropertyApi({
        ...values
      })
        .then((res) => {
          const validateStatusCode = JSON.stringify(res?.data?.code)?.includes('20');
          if (validateStatusCode) {
            const addressData = res.data.data;
            const { address, city, state, zip4, zip5 } = addressData;
            setFieldValue('address', address ?? '');
            setFieldValue('city', city ?? '');
            setFieldValue('state', state ?? '');
            setFieldValue('zipCode4', zip4 ?? '');
            setFieldValue('zipCode5', zip5 ?? '');
            setIsValidateAddress(true);
            setValidateLoading(false);
            setIsModalOpen(false);
          } else {
            setIsValidateAddress(false);
            setValidateLoading(false);
            setIsModalOpen(true);
            setModalMessage(res.data.message);
          }
        })
        .catch((err) => {
          console.log({ err });
          setIsValidateAddress(false);
          setValidateLoading(false);
          setIsModalOpen(true);
          setModalMessage(err.message);
        });
    }
  };

  useEffect(() => {
    // realtor options
    setRealtorOptions(
      realtorData?.map((realtor) => {
        return {
          value: realtor.id,
          label: realtor.firstName + ' ' + realtor.lastName
        };
      })
    );
  }, [realtorData]);

  // Search realtors based on timeout seconds
  const handleRealtorSearch = debounce((value) => {
    setRealtorSearch(value);
  }, DEBOUNCE_TIMEOUT);
  const realtorSearchHandler = () => {
    getRoleBasedUsersApi({
      ...REALTOR_QUERY_DATA,
      search: realtorSearch
    }).then((res) => setRealtorData(res.data.data.userData));
  };
  useEffect(() => {
    if (realtorSearch !== '' && realtorSearch !== 0) {
      realtorSearchHandler();
    }
  }, [realtorSearch]);

  useEffect(() => {
    setFieldValue('role', role?.name);
  }, [role]);

  useEffect(() => {
    if (brokerageOfficeOptions.length === 1) {
      setFieldValue('brokerageOffice', brokerageOfficeOptions[0].id);
    }
  }, [brokerageOfficeOptions]);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Dialog
        onClose={handleModalClose}
        aria-labelledby="customized-dialog-title"
        open={isModalOpen}>
        <IconButton
          aria-label="close"
          onClick={handleModalClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'grey'
          }}>
          <CloseIcon />
        </IconButton>
        <DialogContent
          sx={{ m: 0, px: 10, py: 'auto', color: 'red', display: 'flex', alignItems: 'center' }}
          dividers>
          <IconButton
            sx={{
              color: 'red'
            }}>
            <ErrorIcon />
          </IconButton>
          <div>{modalMessage}</div>
        </DialogContent>
      </Dialog>
      <div className="page-inner">
        <div className="page-content-block">
          <div className="inner-page-title-wrapper">
            <div className="title-left-block">
              <BreadCrumb links={breadcrumbsLinks} />
              <Label variant="h1" label={LABELS.CREATE_PROPERTY} />
            </div>
          </div>
          <div className="white-box shadow-box">
            <form onSubmit={handleSubmit}>
              <div className="form-row-wrapper">
                <div className="form-col has-required form-address-field">
                  <TextField
                    fullWidth
                    id="address"
                    label="Address"
                    placeholder="Address"
                    name="address"
                    className="form-group"
                    InputLabelProps={{ shrink: true }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address}
                    error={touched.address && Boolean(errors.address)}
                    helperText={touched.address && errors.address}
                  />
                </div>
                <div className="form-col has-required form-addresses ">
                  <TextField
                    fullWidth
                    id="city"
                    label="City"
                    placeholder="City"
                    name="city"
                    className="form-group"
                    InputLabelProps={{ shrink: true }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.city}
                    error={touched.city && Boolean(errors.city)}
                    helperText={touched.city && errors.city}
                  />
                </div>
                <div className="form-col has-required form-addresses ">
                  <TextField
                    fullWidth
                    id="state"
                    label="State"
                    placeholder="State"
                    name="state"
                    className="form-group"
                    InputLabelProps={{ shrink: true }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.state}
                    error={touched.state && Boolean(errors.state)}
                    helperText={touched.state && errors.state}
                  />
                </div>
                <div className="form-col form-addresses ">
                  <TextField
                    fullWidth
                    id="zipCode5"
                    label="Zip Code"
                    placeholder="Zip Code"
                    name="zipCode5"
                    className="form-group"
                    InputLabelProps={{ shrink: true }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.zipCode5}
                    error={touched.zipCode5 && Boolean(errors.zipCode5)}
                    helperText={touched.zipCode5 && errors.zipCode5}
                    disabled
                  />
                </div>
              </div>
              <div className="form-btn-wrapper">
                <Button
                  className="primary-btn"
                  name="Validate Address"
                  onClick={validateAddressHandler}
                  isLoading={validateLoading}
                />
              </div>
              <div className="form-row-wrapper">
                {(role?.name === ROLE.ADMIN || role?.name === ROLE.SALES_MANAGER) && (
                  <div
                    className={
                      touched.realtor && Boolean(errors.realtor)
                        ? 'form-col has-required error full-width'
                        : 'form-col has-required full-width'
                    }>
                    <AutoCompleteSingleSelect
                      placeholder="Realtor"
                      filterOptions={(options) => options}
                      options={realtorOptions}
                      label="Realtor"
                      classes={{ paper: 'custom-select-dropdown multiselect-dropdown' }}
                      name="realtor"
                      onChange={(event, value) => {
                        value
                          ? setFieldValue('realtor', value?.value)
                          : setFieldValue('realtor', '');
                        // Set brokerage office based on realtor
                        value
                          ? getRealtorBrokerageOfficeApi({ realtorId: value?.value }).then((res) =>
                              setBrokerageOfficeOptions(res.data.data)
                            )
                          : setFieldValue('brokerageOffice', '');
                      }}
                      onBlur={handleBlur}
                      value={values.realtor}
                      error={touched.realtor && Boolean(errors.realtor)}
                      helperText={touched.realtor && errors.realtor}
                      freeSolo
                      onInputChange={(e, value) => {
                        if (value === '') {
                          getRoleBasedUsersApi(REALTOR_QUERY_DATA).then((res) =>
                            setRealtorData(res.data.data.userData)
                          );
                        }
                        handleRealtorSearch(value);
                      }}
                    />
                  </div>
                )}
                <div className="form-col has-required">
                  <TextField
                    fullWidth
                    id="source"
                    label="MLS/Source"
                    placeholder="MLS/Source"
                    name="source"
                    className="form-group"
                    InputLabelProps={{ shrink: true }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.source}
                    error={touched.source && Boolean(errors.source)}
                    helperText={touched.source && errors.source}
                  />
                </div>
                <div className="form-col has-required">
                  <SingleSelect
                    id="stage"
                    placeholderText="Stage"
                    items={stageArray}
                    label="Stage"
                    MenuProps={{ classes: { paper: 'custom-select-dropdown' } }}
                    name="stage"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.stage}
                    error={touched.stage && Boolean(errors.stage)}
                    helperText={touched.stage && errors.stage}
                  />
                </div>
                <div className="form-col has-required full-width">
                  <TextField
                    fullWidth
                    id="note"
                    label="Latest Note"
                    placeholder="Latest Note"
                    name="note"
                    className="form-group"
                    InputLabelProps={{ shrink: true }}
                    multiline
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.note}
                    error={touched.note && Boolean(errors.note)}
                    helperText={touched.note && errors.note}
                  />
                </div>
                <div className="form-col has-required">
                  <SingleSelect
                    placeholderText="Status"
                    items={STATUS_ITEMS}
                    label="Status"
                    MenuProps={{ classes: { paper: 'custom-select-dropdown' } }}
                    name="status"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.status}
                    error={touched.status && Boolean(errors.status)}
                    helperText={touched.status && errors.status}
                  />
                </div>
                <div className="form-col has-required">
                  <SingleSelect
                    placeholderText="Brokerage Office"
                    items={brokerageOfficeOptions}
                    label="Brokerage Office"
                    MenuProps={{ classes: { paper: 'custom-select-dropdown' } }}
                    name="brokerageOffice"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.brokerageOffice}
                    error={touched.brokerageOffice && Boolean(errors.brokerageOffice)}
                    helperText={touched.brokerageOffice && errors.brokerageOffice}
                  />
                </div>
              </div>
              <div className="form-btn-wrapper">
                <Button
                  className="primary-btn border-btn"
                  name="Cancel"
                  onClick={() =>
                    role?.name === ROLE.REALTOR
                      ? navigate(ROUTE_LIST.MY_PROPERTIES)
                      : navigate(ROUTE_LIST.PROPERTIES)
                  }
                />
                <Tooltip title={!isValidatedAddress ? MESSAGES.VALIDATE_ADDRESS_TAG : ''} arrow>
                  <span>
                    <Button
                      className="primary-btn"
                      type="submit"
                      name="Create"
                      disabled={!isValidatedAddress}
                    />
                  </span>
                </Tooltip>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
