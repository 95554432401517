import { Dialog, DialogContent } from '@mui/material';
import PropTypes from 'prop-types';
import { LABELS } from '../../constants/common';
import { Label } from '../Label';
import { PropertyHistory } from '../../pages/Realtor/propertyHistory';
import { Tab } from '../Tab';
import { RelationshipHistory } from '../../pages/Realtor/relationshipHistory';

export const ViewHistoryDialog = ({
  open,
  handleClose,
  classes,
  dialogIcon,
  propertyData,
  ...restProps
}) => {
  const tabsData = [
    {
      label: LABELS.DATA_HISTORY,
      component: <PropertyHistory propertyId={propertyData?.id} />
    },
    {
      label: LABELS.RELATIONSHIP_HISTORY,
      component: <RelationshipHistory propertyId={propertyData?.id} />
    }
  ];
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="View-user-wrapper view-brokerage-wrapper custom-dialog"
      classes={classes}
      {...restProps}>
      <div className="dialog-content-wrapper">
        <div className="title-wrapper">
          <Label variant="h3" label={LABELS.VIEW_HISTORY} />
          <button className="close-icon" onClick={handleClose} name="close">
            <img src={dialogIcon} alt="close icon" />
          </button>
        </div>
        <DialogContent>
          <Tab tabItems={tabsData} variant={'fullWidth'} />
        </DialogContent>
      </div>
    </Dialog>
  );
};

ViewHistoryDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  classes: PropTypes.object,
  dialogIcon: PropTypes.string,
  propertyData: PropTypes.object
};
