import userPool from './userPool';

export const getUserIdToken = async () => {
  const currentUser = userPool.getCurrentUser();
  let token = '';
  if (currentUser) {
    currentUser.getSession((err, session) => {
      if (err) {
        return;
      } else {
        token = session.getIdToken().getJwtToken();
      }
    });
  }
  return token;
};
