import { toast } from 'react-toastify';
import api from '../../utils/api';
import { API_ROUTES } from '../../constants/apiRoutes';
import { MESSAGES, defaultQueryData } from '../../constants/common';
import userPool from '../../utils/userPool';
import { getApiRoute } from '../../utils/helper';

export const addUserApi = async (user) => {
  try {
    const response = await api.post(API_ROUTES.ADD_USER, {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email.toLowerCase(),
      roleId: user.role,
      status: user.status,
      phone: user.phone,
      IABS: user.IABS,
      brokerageOfficeIds: user.brokerageOfficeIds.map((id) => id.value)
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const changePasswordApi = async (userData) => {
  try {
    const response = await new Promise((resolve, reject) => {
      const user = userPool.getCurrentUser();
      user.getSession((err) => {
        if (err) {
          toast.error(err.message);
          reject(err);
        } else {
          user.changePassword(userData.oldPassword, userData.newPassword, function (err, result) {
            if (err) {
              toast.error(err.message);
              reject(err);
            } else {
              toast.success(MESSAGES.SUCCESSFULLY_CHANGED_PASSWORD);
              resolve(result);
            }
          });
        }
      });
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getUsersApi = async (queryData = defaultQueryData) => {
  try {
    const response = await api.get(API_ROUTES.GET_USERS, { params: queryData });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const userBulkActionApi = async (data) => {
  try {
    const response = await api.post(API_ROUTES.USER_BULK_ACTIONS, {
      action: data.action,
      userIds: data.userIds
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteUsersApi = async (id) => {
  try {
    const deleteUserApiRoute = getApiRoute(API_ROUTES.UPDATE_DELETE_USERS, id);
    const response = await api.delete(deleteUserApiRoute);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getUserRolesApi = async () => {
  try {
    const response = await api.get(API_ROUTES.GET_USER_ROLES);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getEditUserDetailsApi = async (userId) => {
  try {
    const updateUserApiRoute = getApiRoute(API_ROUTES.UPDATE_DELETE_USERS, userId);
    const response = await api.get(updateUserApiRoute);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateUserDetailsApi = async (user) => {
  try {
    const updateUserApiRoute = getApiRoute(API_ROUTES.UPDATE_DELETE_USERS, user.id);
    let payload = {
      firstName: user.firstName,
      lastName: user.lastName,
      phone: user.phone,
      roleId: user.role,
      status: user.status,
      IABS: user.IABS
    };
    // Conditionally add brokerageOfficeIds if the user's role is SALES_MANAGER (value = 2)
    if (user.role === 2) {
      payload = {
        ...payload,
        brokerageOfficeIds: user.blastPermissions?.map((id) => id.value)
      };
    }
    const response = await api.put(updateUserApiRoute, payload);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getSettingsApi = async () => {
  try {
    const response = await api.get(API_ROUTES.GET_SETTINGS);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateSettingsApi = async (settingData) => {
  try {
    const response = await api.put(API_ROUTES.GET_SETTINGS, {
      email_max_image_limit: settingData.imageLimit,
      property_tag_reminder_interval_in_days: settingData.propertyTag,
      property_tag_reminder_no_response_interval_in_days: settingData.propertyTagReminder
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getBrokerageOfficeApi = async (brokerageOfficeData) => {
  try {
    const response = await api.get(API_ROUTES.GET_BROKERAGE_OFFICES, {
      name: brokerageOfficeData?.name,
      logo: brokerageOfficeData?.imageUrl,
      salesManagerId: brokerageOfficeData?.salesManager,
      address: brokerageOfficeData?.address,
      zip: brokerageOfficeData?.zipCode,
      realtorIds: brokerageOfficeData?.realtors.map((realtor) => realtor.value),
      tagId: brokerageOfficeData?.keapTagId,
      isTexasBrokerageOffice: brokerageOfficeData?.isTexas,
      isMultifamilyAndCommercial: brokerageOfficeData?.isMultiOrCommercial
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const addBrokerageOfficeApi = async (brokerageOfficeData) => {
  try {
    const response = await api.post(API_ROUTES.CREATE_BROKERAGE_OFFICE, {
      name: brokerageOfficeData?.name,
      logo: brokerageOfficeData?.imageUrl,
      salesManagerId: brokerageOfficeData?.salesManager,
      address: brokerageOfficeData?.address,
      zip: brokerageOfficeData?.zipCode,
      realtorIds: brokerageOfficeData?.realtors.map((realtor) => realtor.value),
      tagId: brokerageOfficeData?.keapTagId,
      isTexasBrokerageOffice: brokerageOfficeData?.isTexas,
      isMultifamilyAndCommercial: brokerageOfficeData?.isMultiOrCommercial
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateBrokerageOfficeApi = async (brokerageOfficeData) => {
  try {
    const updateOfficeApiRoute = getApiRoute(
      API_ROUTES.UPDATE_BROKERAGE_OFFICES,
      brokerageOfficeData.id
    );
    const response = await api.put(updateOfficeApiRoute, {
      name: brokerageOfficeData.name,
      logo: brokerageOfficeData.imageUrl,
      salesManagerId: brokerageOfficeData.salesManager,
      address: brokerageOfficeData.address,
      zip: brokerageOfficeData.zipCode,
      realtorIds: brokerageOfficeData.realtors.map((realtor) => realtor.value),
      isPastLogoDeleted: brokerageOfficeData?.isPastLogoDeleted,
      tagId: brokerageOfficeData.keapTagId,
      isTexasBrokerageOffice: brokerageOfficeData.isTexas,
      isMultifamilyAndCommercial: brokerageOfficeData.isMultiOrCommercial
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteBrokerageOfficeApi = async (brokerageOfficeId) => {
  try {
    const deleteBrokerageOfficeApiRoute = getApiRoute(
      API_ROUTES.DELETE_BROKERAGE_OFFICE,
      brokerageOfficeId
    );
    const response = await api.delete(deleteBrokerageOfficeApiRoute);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getRoleBasedUsersApi = async (queryData = defaultQueryData) => {
  try {
    const response = await api.get(API_ROUTES.GET_ROLE_BASED_USER, { params: queryData });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const reassignPropertyTagApi = async ({ propertyId, realtorId }) => {
  try {
    const reassignTagApiRoute = getApiRoute(API_ROUTES.REASSIGNED_PROPERTY_TAG, propertyId);
    const response = await api.put(reassignTagApiRoute, {
      realtorId
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
