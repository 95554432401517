import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { BreadCrumb } from '../../components/BreadCrumb';
import { Button } from '../../components/Button';
import { Label } from '../../components/Label';
import { DataTable } from '../../components/DataTable';
import { ConfirmDialog } from '../../components/Popup/ConfirmDialog';
import { PageLoader } from '../../components/PageLoader';
import { getPropertyTagRequests, propertyTagRequestAction } from '../../redux/actions/realtor';
import {
  BREAD_CRUMB_LABELS,
  DEBOUNCE_TIMEOUT,
  defaultQueryData,
  DIALOG,
  LABELS,
  TAG_REQUEST_ACTION
} from '../../constants/common';
import { ROUTE_LIST } from '../../constants/routes';
import { acceptRequestIcon, rejectRequestIcon } from '../../constants/images';
import { debounce } from '../../utils/helper';
import './../../styles/inner-page-style.css';
import './../../styles/data-table-style.css';
import './../../styles/custom-dialog-style.css';

export const TagRequestList = () => {
  const dispatch = useDispatch();
  const [openAcceptDialog, setOpenAcceptDialog] = useState(false);
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [property, setProperty] = useState();
  const [search, setSearch] = useState('');
  const [queryData, setQueryData] = useState({ ...defaultQueryData });
  const { tagRequestData, paginationData } = useSelector((state) => state.realtor);
  const { isLoading } = useSelector((state) => state.apiStatus);

  const breadcrumbsLinks = [
    {
      label: BREAD_CRUMB_LABELS.DASHBOARD,
      to: ROUTE_LIST.DASHBOARD
    },
    {
      label: BREAD_CRUMB_LABELS.MY_PROPERTIES,
      to: ROUTE_LIST.MY_PROPERTIES
    },
    {
      label: BREAD_CRUMB_LABELS.TAG_REQUESTS,
      last: true
    }
  ];

  useEffect(() => {
    dispatch(getPropertyTagRequests());
  }, []);

  // Close dialogs
  const closeRequestDialog = () => {
    setOpenAcceptDialog(false);
    setOpenRejectDialog(false);
  };
  // Accept request for tag transfer
  const openAcceptRequestDialog = (params) => {
    setOpenAcceptDialog(true);
    setProperty(params.row);
  };
  const accceptRequestHandler = () => {
    dispatch(
      propertyTagRequestAction({ propertyId: property.id, tagAction: TAG_REQUEST_ACTION.ACCEPTED })
    );
    setOpenAcceptDialog(false);
  };
  const acceptButtonsList = [
    {
      name: 'Cancel',
      className: 'primary-btn border-btn',
      event: closeRequestDialog
    },
    {
      name: 'Confirm',
      className: 'primary-btn',
      event: accceptRequestHandler
    }
  ];

  // Reject request for tag transfer
  const openRejectRequestDialog = (params) => {
    setOpenRejectDialog(true);
    setProperty(params.row);
  };
  const rejectRequestHandler = () => {
    dispatch(
      propertyTagRequestAction({ propertyId: property.id, tagAction: TAG_REQUEST_ACTION.REJECTED })
    );
    setOpenRejectDialog(false);
  };
  const rejectButtonsList = [
    {
      name: 'Cancel',
      className: 'primary-btn border-btn',
      event: closeRequestDialog
    },
    {
      name: 'Confirm',
      className: 'primary-btn',
      event: rejectRequestHandler
    }
  ];

  const columns = [
    { field: 'realtorName', headerName: 'Realtor Name', flex: 1, minWidth: 160 },
    { field: 'brokerageOfficeName', headerName: 'Brokerage Office', flex: 1, minWidth: 160 },
    { field: 'address', headerName: 'Address', flex: 1, minWidth: 200, sortable: false },
    { field: 'city', headerName: 'City', flex: 1, minWidth: 120 },
    { field: 'state', headerName: 'State', flex: 1, minWidth: 120 },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      minWidth: 120,
      headerClassName: 'action-col',
      cellClassName: 'action-col',
      sortable: false,
      headerAlign: 'right',
      align: 'right',
      renderCell: (params) => {
        return (
          <div className="action-column-wrapper">
            <div className="action-column-inner">
              <Button
                title="Accept"
                onClick={() => openAcceptRequestDialog(params)}
                name={<img src={acceptRequestIcon} alt="accept-icon" />}
              />
            </div>
            <div className="action-column-inner">
              <Button
                title="Reject"
                onClick={() => openRejectRequestDialog(params)}
                name={<img src={rejectRequestIcon} alt="reject-icon" />}
              />
            </div>
          </div>
        );
      }
    }
  ];

  const paginationChangeHandler = (paginationModelData) => {
    const paginationQueryData = {
      ...queryData,
      page: paginationModelData.page + 1,
      perPage: paginationModelData.pageSize
    };
    setQueryData(paginationQueryData);
    dispatch(getPropertyTagRequests(paginationQueryData));
  };

  const sortingChangeHandler = (sortModelData) => {
    if (sortModelData && sortModelData.length > 0) {
      const sortQueryData = {
        ...queryData,
        sortField: sortModelData[0].field,
        sortDirection: sortModelData[0].sort
      };
      setQueryData(sortQueryData);
      dispatch(getPropertyTagRequests(sortQueryData));
    }
  };

  // Search users based on timeout seconds
  const handleSearch = debounce((value) => {
    if (value === '') {
      delete queryData.search;
      dispatch(getPropertyTagRequests(queryData));
    } else {
      setSearch(value);
    }
  }, DEBOUNCE_TIMEOUT);
  const searchHandler = () => {
    const searchQueryData = {
      ...queryData,
      search: search
    };
    setQueryData(searchQueryData);
    dispatch(getPropertyTagRequests(searchQueryData));
  };
  useEffect(() => {
    if (search !== '') {
      searchHandler();
    }
  }, [search]);

  return (
    <>
      <div className="page-inner">
        <div className="page-content-block">
          <div className="inner-page-title-wrapper">
            <div className="title-left-block">
              <BreadCrumb links={breadcrumbsLinks} />
              <Label variant="h1" label={LABELS.TAG_REQUESTS} />
            </div>
          </div>
          <div className="white-box shadow-box page-table-wrapper">
            {isLoading && <PageLoader />}
            <div className="filter-wrapper">
              <div className="filter-left-block">
                <TextField
                  id="search"
                  label="Search"
                  type="search"
                  placeholder="Search here..."
                  name="search"
                  className="form-group search-field"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => handleSearch(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
              </div>
            </div>
            <div className="table-wrapper no-checkbox">
              <DataTable
                handlePagination={paginationChangeHandler}
                total={paginationData && paginationData.total ? paginationData.total : 0}
                columns={columns}
                rows={tagRequestData}
                rowHeight={56}
                handleSorting={sortingChangeHandler}
                sortingMode="server"
                paginationMode="server"
              />
            </div>
          </div>
        </div>
      </div>
      <ConfirmDialog
        open={openAcceptDialog}
        onClose={closeRequestDialog}
        dialogIcon={acceptRequestIcon}
        dialogTitle={DIALOG.ACCEPT_TAG_REQUEST_DIALOG_TITLE}
        dialogBody={DIALOG.ACCEPT_TAG_REQUEST_DIALOG_BODY}
        buttons={acceptButtonsList}
      />
      <ConfirmDialog
        open={openRejectDialog}
        onClose={closeRequestDialog}
        dialogIcon={rejectRequestIcon}
        dialogTitle={DIALOG.REJECT_TAG_REQUEST_DIALOG_TITLE}
        dialogBody={DIALOG.REJECT_TAG_REQUEST_DIALOG_BODY}
        buttons={rejectButtonsList}
      />
    </>
  );
};
