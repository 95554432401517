import { createSlice } from '@reduxjs/toolkit';
import { getDateFromDateTime, getUserStatus } from '../../utils/helper';

const initialState = {};

const propertySlice = createSlice({
  name: 'property',
  initialState: initialState,
  reducers: {
    getProperty(state, action) {
      let propertiesData = action.payload.propertyData;
      const paginationData = action.payload.pagination;
      propertiesData = propertiesData.map((property) => {
        return {
          ...property,
          dateTagged: getDateFromDateTime(property.dateTagged),
          status: getUserStatus(property.status)
        };
      });
      return { ...state, propertiesData, paginationData };
    },
    manageProperty(state, action) {
      return { ...state, propertyFlag: action.payload };
    }
  }
});
export const { getProperty, manageProperty } = propertySlice.actions;
export default propertySlice.reducer;
