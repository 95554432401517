import { Dialog, DialogContent } from '@mui/material';
import PropTypes from 'prop-types';
import html2pdf from 'html2pdf.js';
import { Label } from '../../components/Label';
import { LABELS } from '../../constants/common';
import { printIcon } from '../../constants/images';

export const ViewMemberDialog = ({
  open,
  handleClose,
  classes,
  dialogIcon,
  memberData,
  ...restProps
}) => {
  async function downloadMemberDialogHandler() {
    try {
      const template = `
            <!Doctype html>
      <html lang="en">

      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Template</title>
      </head>

      <body>
          <div id="htmlContainer"
              style="height: 550px; width: 810px; font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;">
              <div style="display:flex;flex-direction: row;padding-top: 30px; flex-wrap:wrap;margin:0">
                  <div style="display: block;">
                      <div style="flex:0 0 50%;max-width:50%;padding:0 15px 30px">
                          <span style="font-size:16px;color:#979797;line-height:24px;margin-bottom: 5px;">Name</span>
                          <div>
                              <p style="font-size:18px;color:#212121;line-height:27px; width:360px; font-weight:500">#NAME#
                              </p>
                          </div>
                      </div>
                      <div style="flex:0 0 50%;max-width:50%;padding:0 15px 30px">
                          <span style="font-size:16px;color:#979797;line-height:24px;margin-bottom: 5px;">Email</span>
                          <div>
                              <p style="font-size:18px;color:#212121;line-height:27px; width:360px; font-weight:500">#EMAIL#
                              </p>
                          </div>
                      </div>
                      <div style="flex:0 0 50%;max-width:50%;padding:0 15px 30px">
                          <span style="font-size:16px;color:#979797;line-height:24px;margin-bottom: 5px;">Membership End
                              Date</span>
                          <div>
                              <p style="font-size:18px;color:#212121;line-height:27px; width:360px; font-weight:500">
                                  #END_DATE#</p>
                          </div>
                      </div>
                      <div style="flex:0 0 50%;max-width:50%;padding:0 15px 30px">
                          <span style="font-size:16px;color:#979797;line-height:24px;margin-bottom: 5px;">Subscriptions</span>
                          <div>
                              <p style="font-size:18px;color:#212121;line-height:27px; width:360px; font-weight:500">
                                  #SUBSCRIPTIONS#</p>
                          </div>
                      </div>
                      <div style="flex:0 0 50%;max-width:50%;padding:0 15px 30px">
                          <span style="font-size:16px;color:#979797;line-height:24px;margin-bottom: 5px;">Initial
                              Consultation</span>
                          <div>
                              <p style="font-size:18px;color:#212121;line-height:27px; width:360px; font-weight:500">
                                  #INITIAL_CONSULTATION#</p>
                          </div>
                      </div>
                  </div>
                  <div>
                      <div style="flex:0 0 50%;max-width:50%;padding:0 15px 30px">
                          <span style="font-size:16px;color:#979797;line-height:24px;margin-bottom: 5px;">Phone
                              Number</span>
                          <div>
                              <p style="font-size:18px;color:#212121;line-height:27px; width:360px; font-weight:500">
                                  #PHONE_NUMBER#
                              </p>
                          </div>
                      </div>
                      <div style="flex:0 0 50%;max-width:50%;padding:0 15px 30px">
                          <span style="font-size:16px;color:#979797;line-height:24px;margin-bottom: 5px;">Membership
                              Level</span>
                          <div>
                              <p style="font-size:18px;color:#212121;line-height:27px; width:360px; font-weight:500">
                                  #MEMBERSHIP_LEVEL#</p>
                          </div>
                      </div>
                      <div style="flex:0 0 50%;max-width:50%;padding:0 15px 30px">
                          <span style="font-size:16px;color:#979797;line-height:24px;margin-bottom: 5px;">Status</span>
                          <div style="margin-top: 20px;">
                              <span style="color: #ffffff; font-size:16px;line-height:22px; width:70px;border-radius: 4px; padding: 5px 10px; background-color: #075e45; font-weight:400">
                                  #STATUS#
                              </span>
                          </div>
                      </div>
                      <div style="flex:0 0 50%;max-width:50%;padding:0 15px 30px">
                          <span style="font-size:16px;color:#979797;line-height:24px;margin-bottom: 5px;">Education
                              Status</span>
                          <div>
                              <p style="font-size:18px;color:#212121;line-height:27px; width:360px; font-weight:500">
                                  #EDUCATION_STATUS#</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </body>

      </html>
            `;
      const valueFields = [
        {
          key: '#NAME#',
          value: `${memberData?.firstName} ${memberData?.lastName}`
        },
        {
          key: '#PHONE_NUMBER#',
          value: memberData?.phone ? memberData?.phone : '-'
        },
        {
          key: '#EMAIL#',
          value: memberData?.email
        },
        {
          key: '#END_DATE#',
          value: memberData?.membershipEndDate
        },
        {
          key: '#MEMBERSHIP_LEVEL#',
          value: memberData?.membershipType
        },
        {
          key: '#SUBSCRIPTIONS#',
          value: memberData?.brokeragesOffices
        },
        {
          key: '#EDUCATION_STATUS#',
          value: memberData?.educationStatus
        },
        {
          key: '#INITIAL_CONSULTATION#',
          value: memberData?.initialConsultation
        },
        {
          key: '#STATUS#',
          value: memberData?.status
        }
      ];
      let updatedTemplate = template;
      valueFields.forEach((replacement) => {
        updatedTemplate = updatedTemplate.replace(replacement.key, replacement.value);
      });
      const pdfOptions = {
        margin: 10,
        filename: 'Member-data.pdf',
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
      };
      const pdf = await html2pdf(updatedTemplate, pdfOptions);
      const pdfBlob = new Blob([pdf], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      URL.revokeObjectURL(pdfUrl);
    } catch (error) {
      throw new Error(error);
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="View-user-wrapper custom-dialog"
      classes={classes}
      {...restProps}>
      <div className="dialog-content-wrapper">
        <div className="title-wrapper">
          <Label variant="h3" label={LABELS.VIEW_MEMBER} />
          <button className="close-icon" onClick={handleClose} name="close">
            <img src={dialogIcon} alt="close icon" />
          </button>
        </div>
        <DialogContent>
          <div className="user-detail-wrapper">
            <div className="single-detail-wrapper">
              <span className="label">Name</span>
              <div className="detail">
                <p>{`${memberData?.firstName} ${memberData?.lastName}`}</p>
              </div>
            </div>
            <div className="single-detail-wrapper">
              <span className="label">Phone Number</span>
              <div className="detail">
                <p>{memberData?.phone}</p>
              </div>
            </div>
            <div className="single-detail-wrapper">
              <span className="label">Email</span>
              <div className="detail">
                <p>{memberData?.email}</p>
              </div>
            </div>
            <div className="single-detail-wrapper">
              <span className="label">Membership Level</span>
              <div className="detail">
                <p>{memberData?.membershipType}</p>
              </div>
            </div>
            <div className="single-detail-wrapper">
              <span className="label">Membership End Date</span>
              <div className="detail">
                <p>{memberData?.membershipEndDate}</p>
              </div>
            </div>
            <div className="single-detail-wrapper">
              <span className="label">Status</span>
              <div className="detail">
                <span className={`status-badge ${memberData?.status?.toLowerCase() || ''}`}>
                  {memberData?.status}
                </span>
              </div>
            </div>
            {memberData?.brokeragesOffices?.length !== 0 && (
              <div className="single-detail-wrapper">
                <span className="label">Subscriptions</span>
                <div className="detail">
                  {memberData?.brokeragesOffices.map((member, index) => {
                    return <p key={index}>{member}</p>;
                  })}
                </div>
              </div>
            )}
            <div className="single-detail-wrapper">
              <span className="label">Education Status</span>
              <div className="detail">
                <p>{memberData?.educationStatus}</p>
              </div>
            </div>
            <div className="single-detail-wrapper">
              <span className="label">Initial Consultation</span>
              <div className="detail">
                <p>{memberData?.initialConsultation}</p>
              </div>
            </div>
            <div className="right-block-inner view-member-print-button">
              <button
                className="primary-btn print-button"
                onClick={downloadMemberDialogHandler}
                id="printButton"
                name="print">
                <img src={printIcon} alt="print-icon" />
                <p>Print</p>
              </button>
            </div>
          </div>
        </DialogContent>
      </div>
    </Dialog>
  );
};

ViewMemberDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  classes: PropTypes.object,
  dialogIcon: PropTypes.string,
  memberData: PropTypes.object
};
