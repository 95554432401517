import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { dialogCloseIcon } from '../../../constants/images';
import { ImagePreviewDialog } from '../ImagePreviewDiaglog';

export const UploadedFileList = ({ propertyData }) => {
  const [imagePreviewOpen, setImagePreviewOpen] = useState(false);
  const [path, setPath] = useState('');

  const openImagePreviewDialog = (path) => {
    setImagePreviewOpen(true);
    setPath(path);
  };
  const closeImagePreviewDialog = () => {
    setImagePreviewOpen(false);
  };

  const files = propertyData?.file.map((file) => file);
  // Logic for uploaded files from email blast listing
  const uploadedFiles = files?.map((file) => {
    let fileName = file.name.split('/');
    fileName = fileName[fileName.length - 1];
    return {
      name: fileName,
      path: file
    };
  });
  return (
    <>
      <tr>
        <td height={15} className="outer-td-height" />
      </tr>
      <tr>
        <td
          style={{
            color: '#212121',
            fontFamily: '"Roboto", Verdana',
            fontSize: 14,
            fontWeight: 600,
            lineHeight: '24px',
            textAlign: 'left'
          }}>
          Uploaded Images
        </td>
      </tr>
      {uploadedFiles?.map((file, index) => {
        return (
          <Fragment key={index}>
            <tr>
              <td height={12} className="outer-td-height" />
            </tr>
            <tr>
              <td
                style={{
                  color: '#4DAB20',
                  fontFamily: '"Roboto", Verdana',
                  fontSize: 12,
                  fontWeight: 600,
                  lineHeight: '14px',
                  textAlign: 'left'
                }}>
                <Link
                  to="#"
                  onClick={() => openImagePreviewDialog(file.path || URL.createObjectURL(file))}
                  style={{
                    color: '#4DAB20',
                    textDecoration: 'underline'
                  }}
                  title="Sample House Image">
                  {file?.name}
                </Link>
              </td>
            </tr>
          </Fragment>
        );
      })}
      <tr>
        <td height={12} className="outer-td-height" />
      </tr>
      <ImagePreviewDialog
        open={imagePreviewOpen}
        dialogIcon={dialogCloseIcon}
        onClose={closeImagePreviewDialog}
        handleClose={closeImagePreviewDialog}
        path={path}
      />
    </>
  );
};

UploadedFileList.propTypes = {
  propertyData: PropTypes.object
};
