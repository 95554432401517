import { useState } from 'react';
import { useSelector } from 'react-redux';
import { AppBar, Toolbar } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useNavigate } from 'react-router-dom';
import { Avatar } from './Avatar';
import { ROUTE_LIST } from '../constants/routes';
import { siteHamburger, logoutIcon, profileIcon } from '../constants/images';
import './../styles/header-style.css';

export const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const adminData = useSelector((state) => state.auth);
  const fullName = `${adminData?.firstName} ${adminData?.lastName}`;

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleHamburgerClick = () => {
    if (window.innerWidth > 1023) {
      document.body.classList.toggle('sidebar-close');
      document.body.classList.remove('sidebar-open');
    } else {
      document.body.classList.toggle('sidebar-open');
      document.body.classList.remove('sidebar-close');
    }
  };

  const handleProfileClick = () => {
    navigate(ROUTE_LIST.PROFILE);
    setAnchorEl(null);
  };

  return (
    <AppBar position="fixed" className="site-header">
      <Toolbar>
        <div className="site-header-wrapper">
          <div className="hamburger-menu" onClick={handleHamburgerClick}>
            <img src={siteHamburger} alt="hamburger menu" />
          </div>
          <Avatar
            className="user-profile-menu-link user-img-btn"
            name={fullName}
            onClick={handleClick}
          />
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            className="user-profile-menu">
            <MenuItem className="user-profile-wrapper">
              <div className="user-profile">
                <Avatar className="user-profile-menu-link user-img-btn" name={fullName} />
                <div className="user-detail">
                  <p>{fullName}</p>
                  <span>{adminData?.role?.name}</span>
                </div>
              </div>
            </MenuItem>
            <MenuItem onClick={handleProfileClick}>
              <ListItemIcon>
                <img src={profileIcon} alt="profile-icon" />
              </ListItemIcon>
              Profile
            </MenuItem>
            <MenuItem onClick={() => navigate(ROUTE_LIST.LOGOUT)}>
              <ListItemIcon>
                <img src={logoutIcon} alt="profile-icon" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};
