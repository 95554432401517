import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
} from 'chart.js';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Doughnut, Bar } from 'react-chartjs-2';
import { Label } from '../components/Label';
import { PageLoader } from '../components/PageLoader';
import { getDashboardStatistics } from '../redux/actions/dashboard';
import {
  dashboardEmailIcon,
  dashboardMembersIcon,
  dashboardPropertyIcon
} from '../constants/images';
import { ALL_ROLES, LABELS } from '../constants/common';
import './../styles/dashboard-style.css';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title);

export const Dashboard = () => {
  const dispatch = useDispatch();
  const dashboardData = useSelector((state) => state.dashboard);
  const { isLoading } = useSelector((state) => state.apiStatus);

  useEffect(() => {
    dispatch(getDashboardStatistics());
  }, []);

  const doughnutChartData = {
    labels: ALL_ROLES,
    datasets: [
      {
        label: 'Total Members',
        data: [
          dashboardData?.adminCount,
          dashboardData?.realtorCount,
          dashboardData?.salesManagerCount
        ],
        backgroundColor: ['#799DE4', '#C4D3EF', '#EED958'],
        borderWidth: 0
      }
    ]
  };
  const donutChartOptions = {
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          color: '#000',
          font: {
            size: 14
          },
          boxWidth: 12,
          boxHeight: 12,
          padding: 20
        }
      }
    }
  };

  const barChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        ticks: {
          color: '#606060',
          fontSize: 30
        }
      },
      y: {
        grid: {
          display: true,
          color: '#F8F8F8'
        },
        ticks: {
          stepSize: 1,
          color: '#606060',
          fontSize: 30
        }
      }
    }
  };

  const monthArray = dashboardData?.emailBlasts?.map((blast) => blast.month.slice(0, 3));
  const blastDataArray = dashboardData?.emailBlasts?.map((blast) => blast?.count);

  const barChartData = {
    labels: monthArray,
    datasets: [
      {
        data: blastDataArray,
        backgroundColor: '#799DE4',
        barThickness: 12,
        borderRadius: 12
      }
    ]
  };

  return (
    <>
      <div className="page-inner dashboard-page-inner">
        <div className="page-content-block">
          {isLoading && <PageLoader />}
          <div className="inner-page-title-wrapper">
            <div className="title-left-block">
              <Label variant="h1" label={LABELS.DASHBOARD} />
            </div>
          </div>
          <div className="dashboard-content-wrapper">
            <div className="dashboard-content">
              <div className="white-box shadow-box">
                <div className="dashboard-info">
                  <h2>{dashboardData?.propertyCount}</h2>
                  <p>Properties</p>
                </div>
                <div className="image-wrapper">
                  <img src={dashboardPropertyIcon} alt="email icon" />
                </div>
              </div>
            </div>
            <div className="dashboard-content">
              <div className="white-box shadow-box">
                <div className="dashboard-info">
                  <h2>{dashboardData?.emailBlastCount}</h2>
                  <p>Email Blasts</p>
                </div>
                <div className="image-wrapper">
                  <img src={dashboardEmailIcon} alt="email icon" />
                </div>
              </div>
            </div>
            <div className="dashboard-content">
              <div className="white-box shadow-box">
                <div className="dashboard-info">
                  <h2>{dashboardData?.memberCount}</h2>
                  <p>Members</p>
                </div>
                <div className="image-wrapper">
                  <img src={dashboardMembersIcon} alt="email icon" />
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-chart-wrapper">
            <div className="two-col">
              <div className="white-box shadow-box">
                <h3>Email Blasts</h3>
                <div className="left-block-inner">
                  <Bar options={barChartOptions} data={barChartData} />
                </div>
              </div>
            </div>
            <div className="two-col">
              <div className="white-box shadow-box">
                <h3>Members</h3>
                <div className="right-block-inner">
                  <Doughnut options={donutChartOptions} data={doughnutChartData} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
