import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';

export const SingleSelect = ({
  name,
  label,
  value,
  helperText = '',
  onChange,
  onBlur,
  inputProps,
  classes,
  menuProps,
  variant = 'standard',
  icon,
  items,
  size = 'small',
  error = false,
  placeholderText,
  ...restProps
}) => {
  const handleChange = (event) => {
    if (onChange) {
      onChange(event);
    }
  };
  return (
    <div className="form-group select-group">
      <FormControl size={size} variant={variant} fullWidth error={error}>
        <InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>
        <Select
          label={label}
          value={value ?? ''}
          id="outlined-start-adornment"
          labelId="demo-simple-select-outlined-label"
          onChange={handleChange}
          onBlur={onBlur}
          inputProps={inputProps}
          classes={classes}
          variant={variant}
          IconComponent={icon}
          name={name}
          size={size}
          displayEmpty
          MenuProps={menuProps}
          {...restProps}>
          <MenuItem value="" disabled selected className="placeholder-item">
            <span className="placeholder-text">{placeholderText}</span>
          </MenuItem>
          {items?.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error>{helperText}</FormHelperText>
      </FormControl>
    </div>
  );
};

SingleSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  inputProps: PropTypes.object,
  classes: PropTypes.object,
  menuProps: PropTypes.object,
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  icon: PropTypes.string,
  items: PropTypes.array,
  size: PropTypes.string,
  error: PropTypes.bool,
  placeholderText: PropTypes.string
};
