export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const VERIFY_OTP_REQUEST = 'VERIFY_OTP_REQUEST';
export const RESEND_OTP_REQUEST = 'RESEND_OTP_REQUEST';
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const FIRST_LOGIN_CHANGE_PASSWORD_REQUEST = 'FIRST_LOGIN_CHANGE_PASSWORD_REQUEST';
export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST';
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const USER_BULK_ACTION_REQUEST = 'USER_BULK_ACTION_REQUEST';
export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const GET_USER_ROLES_REQUEST = 'GET_USER_ROLES_REQUEST';
export const SET_LOGGED_IN_USERINFO_REQUEST = 'SET_LOGGED_IN_USERINFO_REQUEST';
export const GET_EDIT_USER_DETAILS_REQUEST = 'GET_EDIT_USER_DETAILS_REQUEST';
export const UPDATE_USER_DETAILS_REQUEST = 'UPDATE_USER_DETAILS_REQUEST';
export const GET_SETTINGS_REQUEST = 'GET_SETTINGS_REQUEST';
export const UPDATE_SETTINGS_REQUEST = 'UPDATE_SETTINGS_REQUEST';
export const ADD_BROKERAGE_OFFICE_REQUEST = 'ADD_BROKERAGE_OFFICE_REQUEST';
export const GET_BROKERAGE_OFFICES_REQUEST = 'GET_BROKERAGE_OFFICES_REQUEST';
export const GET_BROKERAGE_OFFICE_REALTORS_REQUEST = 'GET_BROKERAGE_OFFICE_REALTORS_REQUEST';
export const GET_BROKERAGE_OFFICE_BLAST_PERMISSIONS = 'GET_BROKERAGE_OFFICE_BLAST_PERMISSIONS';
export const GET_BROKERAGE_OFFICES_DETAIL_REQUEST = 'GET_BROKERAGE_OFFICES_DETAIL_REQUEST';
export const UPDATE_BROKERAGE_OFFICE_REQUEST = 'UPDATE_BROKERAGE_OFFICE_REQUEST';
export const DELETE_BROKERAGE_OFFICE_REQUEST = 'DELETE_BROKERAGE_OFFICE_REQUEST';
export const GET_MEMBER_REQUEST = 'GET_MEMBER_REQUEST';
export const GET_PROPERTIES_REQUEST = 'GET_PROPERTIES_REQUEST';
export const CREATE_PROPERTY_REQUEST = 'CREATE_PROPERTY_REQUEST';
export const MY_PROPERTY_REQUEST = 'MY_PROPERTY_REQUEST';
export const EDIT_PROPERTY_REQUEST = 'EDIT_PROPERTY_REQUEST';
export const DELETE_PROPERTY_REQUEST = 'DELETE_PROPERTY_REQUEST';
export const ADD_PROPERTY_TAG_REQUEST = 'ADD_PROPERTY_TAG_REQUEST';
export const GET_PROPERTY_HISTORY_REQUEST = 'GET_PROPERTY_HISTORY_REQUEST';
export const GET_PROPERTIES_TAG_REQUEST = 'GET_PROPERTIES_TAG_REQUEST';
export const PROPERTY_TAG_ACTION_REQUEST = 'PROPERTY_TAG_ACTION_REQUEST';
export const GET_RELATIONSHIP_HISTORY_REQUEST = 'GET_RELATIONSHIP_HISTORY_REQUEST';
export const REASSIGN_PROPERTY_TAG_REQUEST = 'REASSIGN_PROPERTY_TAG_REQUEST';
export const GET_PROPERTY_NOTIFICATION_REQUEST = 'GET_PROPERTY_NOTIFICATION_REQUEST';
export const PROPERTY_NOTIFICATION_ACTION_REQUEST = 'PROPERTY_NOTIFICATION_ACTION_REQUEST';
export const GET_DASHBOARD_DATA_REQUEST = 'GET_DASHBOARD_DATA_REQUEST';
export const CREATE_EMAIL_BLAST_REQUEST = 'CREATE_EMAIL_BLAST_REQUEST';
export const GET_EMAIL_BLAST_REQUEST = 'GET_EMAIL_BLAST_REQUEST';
export const GET_DRAFT_EMAIL_BLAST_LIST_REQUEST = 'GET_DRAFT_EMAIL_BLAST_LIST_REQUEST';
export const SAVE_DRAFT_EMAIL_BLAST_REQUEST = 'SAVE_DRAFT_EMAIL_BLAST_REQUEST';
export const EDIT_DRAFT_EMAIL_BLAST_REQUEST = 'EDIT_DRAFT_EMAIL_BLAST_REQUEST';
export const DELETE_DRAFT_EMAIL_BLAST_REQUEST = 'DELETE_DRAFT_EMAIL_BLAST_REQUEST';
export const GET_BROKERAGE_OFFICES_LIST_REQUEST = 'GET_BROKERAGE_OFFICES_LIST_REQUEST';
