import CryptoJS from 'crypto-js';
import { debounce as lodashDebounce } from 'lodash';
import { uploadFileApi } from '../redux/apis/commonApi';
import { RESPONSE_STATUS_CODES, ROLE, STATUS_LABELS } from '../constants/common';

/**
 * @param menu: To check whether the provided menu has child menu or not.
 * @returns Returns true if the menu has child menu else false.
 */
export const hasChildren = (menu) => {
  let response = true;
  const { subMenu } = menu;
  if (!subMenu || !Array.isArray(subMenu) || subMenu.length === 0) {
    response = false;
  }
  return response;
};

/**
 *
 * @param menuObj Provide the link from menu if its consists of sub menu then returns the link from first array element.
 * @returns The link for react router dom for navigation.
 */
export const provideLinkFromSubMenu = (menuObj) => {
  const isSubMenuExist = hasChildren(menuObj);
  if (!isSubMenuExist) {
    return menuObj?.link || '';
  } else {
    return provideLinkFromSubMenu(menuObj.subMenu[0]);
  }
};

/**
 * Get data from local storage
 */
export const getDataFromLocalstorage = (STORAGE_KEY) => {
  const data = localStorage.getItem(STORAGE_KEY);
  if (data) return JSON.parse(data);
  return { role: null };
};

/**
 * Set data into local storage
 */
export const setDataInLocalstorage = (STORAGE_KEY, data) => {
  return localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
};

/**
 * Encrypt data
 */
export const encryptData = (data) => {
  return CryptoJS.AES.encrypt(
    JSON.stringify(data),
    process.env.REACT_APP_CRYPTO_SECRET_KEY
  ).toString();
};

/**
 * Decrypt data
 */
export const decryptData = (data) => {
  const bytes = CryptoJS.AES.decrypt(data, process.env.REACT_APP_CRYPTO_SECRET_KEY);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

/**
 * Generate initials from first name and last name
 */
export const generateInitials = (name) => {
  const nameArr = name.split(' ');
  return nameArr[0].charAt(0).toUpperCase() + nameArr[1].charAt(0).toUpperCase();
};

/**
 * Get user role
 */
export const getUserRole = (role) => {
  switch (role) {
    case 1:
      return ROLE.ADMIN;
    case 2:
      return ROLE.SALES_MANAGER;
    case 3:
      return ROLE.REALTOR;
    default:
      return '-';
  }
};

/**
 * Get user status
 */
export const getUserStatus = (status) => {
  switch (status) {
    case 0:
      return STATUS_LABELS.INACTIVE;
    case 1:
      return STATUS_LABELS.ACTIVE;
    default:
      return '-';
  }
};

/**
 * Debounce on search field
 */
export const debounce = (callback, timeout = 300) => {
  return lodashDebounce(callback, timeout);
};

/**
 * Get API route
 */
export const getApiRoute = (apiRoute, value) => {
  return apiRoute.replace(':id', value);
};

/**
 * Extract date from date time
 */
export const getDateFromDateTime = (dateTime) => {
  const date = new Date(dateTime).toLocaleDateString();
  return date;
};

/**
 *
 * Upload multiple files
 */
export const uploadFiles = async (presignedUrls, files) => {
  let fileNameArr = [];

  for (let i = 0; i < presignedUrls.length; i++) {
    const presignedUrl = presignedUrls[i].split('?')[0];
    const isUpload = await uploadFileApi({
      file: files[i],
      url: presignedUrls[i]
    });

    if (isUpload?.status === RESPONSE_STATUS_CODES.OK) {
      fileNameArr.push(presignedUrl);
    }
  }
  return fileNameArr;
};

export const checkImageDimmensions = (value) => {
  if (value[0] instanceof File) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(value[0]);
      reader.onload = function (value) {
        const img = new Image();
        img.src = value.target.result;
        img.onload = function () {
          resolve(this.height === 75 && this.width === 225);
        };
      };
    });
  }
};

export const formatCurrency = (price) => {
  try {
    if (price !== '') {
      const nativeValue = price.split(',').join('');
      return new Intl.NumberFormat().format(Number(nativeValue));
    } else return '';
  } catch (error) {
    return false;
  }
};

export const parseCurrency = (currencyString) => {
  try {
    // Remove commas and any other non-numeric characters
    const numericString = currencyString.replace(/[^0-9.]/g, '');
    // Parse the numeric string as a integer
    const numericValue = parseInt(numericString);
    return numericValue;
  } catch (error) {
    return false;
  }
};
