export const CRYPTO_STORAGE_KEY = 'Asdf@#$';
export const SESSION_STORAGE_KEY = 'session';
export const OTP_LENGTH = 6;

export const ROLE = {
  ADMIN: 'Admin',
  SALES_MANAGER: 'Sales manager',
  REALTOR: 'Realtor'
};

export const STORAGE_KEY = '!m$@uth';

export const STATUS_LABELS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive'
};
export const STAGE_LABELS = {
  TAGGED: 'Tagged',
  WORKING: 'Working',
  SUBMITTED: 'Submitted',
  OFFER_ACCEPTED: 'Offer Accepted',
  ADDENDUM: 'Addendum',
  OPTION_PENDING: 'Option Pending',
  PENDING: 'Pending',
  CLOSED: 'Closed',
  DECLINED: 'Declined',
  BACKED_OUT: 'Backed Out'
};

export const RESPONSE_STATUS_CODES = {
  OK: 200,
  SESSION_EXPIRED: 419,
  TOKEN_EXPIRED: 401,
  FORBIDDEN_ERROR: 403
};

export const API_ERROR_MESSAGES = {
  401: 'Unauthorized',
  403: 'Forbidden Error',
  404: 'Not found',
  500: 'Internal server error',
  503: 'Service unavilable',
  default: 'Something went wrong !'
};

export const LABELS = {
  FORGOT_PASSWORD: 'Forgot Password',
  FORGOT_YOUR_PASSWORD: 'Forgot your password?',
  BACK_TO_SIGN: 'Back to Sign In',
  SIGN_IN: 'Sign In',
  CREATE_USER: 'Create User',
  EDIT_USER: 'Edit User',
  RESET_PASSWORD: 'Reset Password',
  CHANGE_PASSWORD: 'Change Password',
  MY_PROFILE: 'My Profile',
  SETTINGS: 'Settings',
  CREATE_BROKERAGE_OFFICE: 'Create Brokerage Office',
  EDIT_BROKERAGE_OFFICE: 'Edit Brokerage Office',
  BROKERAGE_OFFICES: 'Brokerage Offices',
  ASSIGNED_REALTORS: 'Assigned Realtors',
  ASSIGNED_BROKERAGE_OFFICES: 'Assigned Brokerage Offices',
  CREATE_PROPERTY: 'Create Property',
  MY_PROPERTIES: 'My Properties',
  VIEW_PROPERTY: 'View Property',
  PROPERTIES: 'Properties',
  MEMBERS: 'Members',
  VIEW_MEMBER: 'View Member',
  EDIT_PROPERTY: 'Edit Property',
  TAG_REQUESTS: 'Tag Requests',
  VIEW_HISTORY: 'View History',
  RELATIONSHIP_HISTORY: 'Relationship History',
  DATA_HISTORY: 'Data History',
  REASSIGN_TAG: 'Reassign Property Tag',
  NOTIFICATIONS: 'Notifications',
  DASHBOARD: 'Dashboard',
  EMAIL_BLAST: 'Email Blasts',
  MULTI_COMMERCIAL_EMAIL_BLAST: 'Multi-Commercial Email Blasts',
  CREATE_EMAIL_BLAST: 'Create Email Blast',
  CLONE_EMAIL_BLAST: 'Clone Email Blast',
  DRAFT_EMAIL_BLAST: 'Draft Email Blasts',
  CREATE_MULTI_COMMERCIAL_EMAIL_BLAST: 'Create Multi-Commercial Email Blast',
  CLONE_MULTI_COMMERCIAL_EMAIL_BLAST: 'Clone Multi-Commercial Email Blast'
};

export const BREAD_CRUMB_LABELS = {
  DASHBOARD: 'Dashboard',
  USERS: 'Users',
  CREATE_USER: 'Create User',
  EDIT_USER: 'Edit User',
  BROKERAGE_OFFICE: 'Brokerage Offices',
  CREATE_BROKERAGE_OFFICE: 'Create Brokerage Office',
  EDIT_BROKERAGE_OFFICE: 'Edit Brokerage Office',
  PROPERTIES: 'Properties',
  CREATE_PROPERTY: 'Create Property',
  MY_PROPERTIES: 'My Properties',
  EDIT_PROPERTY: 'Edit Property',
  TAG_REQUESTS: 'Tag Requests',
  NOTIFICATIONS: 'Notifications',
  EMAIL_BLAST: 'Email Blasts',
  MULTI_COMMERCIAL_EMAIL_BLAST: 'Multi-Commercial Email Blasts',
  CREATE_EMAIL_BLAST: 'Create Email Blast',
  CLONE_EMAIL_BLAST: 'Clone Email Blast',
  DRAFT_EMAIL_BLAST: 'Draft Email Blasts',
  CREATE_MULTI_COMMERCIAL_EMAIL_BLAST: 'Create Multi-Commercial Email Blast',
  CLONE_MULTI_COMMERCIAL_EMAIL_BLAST: 'Clone Multi-Commercial Email Blast'
};

export const MESSAGES = {
  SUCCESSFULLY_RESEND_CODE: 'Successfully resend code',
  SUCCESSFULLY_SEND_MAIL_FOR_RESET_PASSWORD: 'Successfully send mail for reset password',
  SUCCESSFULLY_CHANGED_PASSWORD: 'Successfully changed password',
  SUCCESSFULLY_UPDATED_PROFILE: 'Profile updated successfully',
  SUCCESSFULLY_UPDATED_USER_DETAILS: 'User details updated successfully',
  SUCCESSFULLY_DELETED_USER_DETAILS: 'Successfully deleted user details',
  PLEASE_SELECT_ANY_ROW: 'Please select any row',
  SUCCESSFULLY_CREATED_USER: 'Successfully created user',
  SUCCESSFULLY_CHANGED_SETTING: 'Successfully changed settings',
  SUCCESSFULLY_CREATED_BROKERAGE_OFFICE: 'Successfully created brokerage office',
  PLEASE_ENTER_CORRECT_OTP: 'Please enter correct otp',
  SUCCESSFULLY_UPDATED_BROKERAGE_OFFICE: 'Successfully updated brokerage office',
  SUCCESSFULLY_DELETED_BROKERAGE_OFFICE_DETAILS: 'Successfully deleted brokerage office details',
  SUCCESSFULLY_CREATED_PROPERTY: 'Successfully created property',
  SUCCESSFULLY_UPDATED_PROPERTY: 'Successfully updated property',
  SUCCESSFULLY_DELETED_PROPERTY: 'Successfully deleted property',
  VALIDATE_ADDRESS_TAG: 'Please validate address',
  SUCCESSFULLY_SEND_TAG_REQUEST: 'Property tag request sent',
  ACCEPTED_TAG_REQUEST: 'You have accepted tag request',
  REJECTED_TAG_REQUEST: 'You have rejected tag request',
  SUCCESSFULLY_ASSIGN_PROPERTY_TAG: 'Successfully reassigned property tag',
  SUCCESSFULLY_RETAIN_PROPERTY: 'You have successfully retain property',
  SUCCESSFULLY_RELEASE_PROPERTY: 'You have successfully release property',
  SUCCESSFULLY_SENT_AN_EMAIL_BLAST: 'Email blasts sent successfully',
  SUCCESSFULLY_DRAFT_EMAIL_BLAST: 'You have successfully saved email as draft',
  BROKERAGE_OFFICE_IS_NECESSARY: 'Please add Brokerage office',
  SUCCESSFULLY_DELETED_DRAFT_EMAIL_BLAST: 'Successfully deleted Draft'
};

export const STATUS_ITEMS = [
  {
    id: 1,
    name: STATUS_LABELS.ACTIVE
  },
  {
    id: 0,
    name: STATUS_LABELS.INACTIVE
  }
];

export const defaultQueryData = {
  page: 1,
  perPage: 10
};

export const memberPaginationQueryData = {
  page: 1,
  perPage: 1
};

export const DEBOUNCE_TIMEOUT = 1000;

export const DIALOG = {
  DELETE_DIALOG_TITLE: 'Delete User',
  DELETE_BROKERAGE_OFFICE_DIALOG_TITLE: 'Delete Brokerage Office',
  DELETE_PROPERTY_DIALOG_TITLE: 'Delete Property',
  DELETE_DIALOG_BODY: 'Are you sure, You want to delete ?',
  TAG_TRANSFER_REQUEST_DIALOG_TITLE: 'Request For Property Tag',
  TAG_TRANSFER_REQUEST_DIALOG_BODY: 'Are you sure want to send request for property tag ?',
  ACCEPT_TAG_REQUEST_DIALOG_TITLE: 'Accept Request',
  ACCEPT_TAG_REQUEST_DIALOG_BODY: 'Are you sure want to accept Request ?',
  REJECT_TAG_REQUEST_DIALOG_TITLE: 'Reject Request',
  REJECT_TAG_REQUEST_DIALOG_BODY: 'Are you sure want to reject Request ?',
  REASSIGN_PROPERTY_TAG_DIALOG_TITLE: 'Reassign Property Tag',
  RELEASE_TAG_DIALOG_TITLE: 'Release Tag',
  RELEASE_TAG_DIALOG_BODY: 'Are you sure want to release property tag ?',
  SEND_EMAIL_BLAST_TITLE: 'Send Email Blast',
  SEND_EMAIL_BLAST_BODY: 'Are you sure want to send an email blast ?',
  DELETE_DRAFT_DIALOG_TITLE: 'Delete Draft'
};

export const ALL_FILTERS = 'all';

export const ALL_ROLES = [ROLE.ADMIN, ROLE.REALTOR, ROLE.SALES_MANAGER];

export const IABS_ALLOWED_ROLES = [ROLE.REALTOR, ROLE.SALES_MANAGER];

export const OTHER_BLAST_PERMISSION_BROKERAGE_OFFICES = [ROLE.SALES_MANAGER];

export const REGEX = {
  websiteValidation:
    // https://stackoverflow.com/questions/18568244/url-validation-regex-url-just-valid-with-http
    // eslint-disable-next-line no-useless-escape
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
};

export const REALTOR_QUERY_DATA = {
  perPage: 50,
  roleId: 3
};

export const SALES_MANAGER_QUERY_DATA = {
  perPage: 50,
  roleId: 2
};

export const IMAGE_FILE_TYPE = {
  BROKERAGE_OFFICE_LOGO: 'brokerageOfficeLogo',
  EMAIL_BLAST_FILES: 'emailBlastFiles'
};

export const TAG_REQUEST_ACTION = {
  REJECTED: 'REJECTED',
  ACCEPTED: 'ACCEPTED'
};

export const TAG_REQUEST_ICON = {
  SHOW: 1,
  HIDE: 0
};

export const MEMBER_STATUS_ITEMS = [
  {
    id: STATUS_LABELS.ACTIVE,
    name: STATUS_LABELS.ACTIVE
  },
  {
    id: STATUS_LABELS.INACTIVE,
    name: STATUS_LABELS.INACTIVE
  }
];

export const PROPERTY_TAG_ACTION = {
  CREATED: 0,
  REQUESTED_FOR_TAG_TRANSFER: 1,
  TAG_TRANSFER_REQUEST_COMPLETED: 2,
  TAG_REMINDER_SENT: 3,
  TAG_RETAIN: 4,
  TAG_RELEASE: 5
};

export const REMINDER_ACTIONS = {
  RETAIN: 'TAG_RETAIN',
  RELEASE: 'TAG_RELEASE'
};

export const EMAIL_BLAST_PAGE = {
  CREATE_EMAIL_BLAST: 'CREATE_EMAIL_BLAST',
  CLONE_EMAIL_BLAST: 'CLONE_EMAIL_BLAST',
  EDIT_EMAIL_DRAFT: 'EDIT_EMAIL_DRAFT'
};

export const ESTIMATE_OUT_OF_POCKET_TYPE = {
  HARD: 1,
  CONV: 0
};

export const ESTIMATE_OUT_OF_POCKET = {
  HARD: 'Hard',
  CONV: 'Conv'
};

export const EMAIL_BLAST_TYPE = {
  DRAFT: 0,
  SENT: 1
};

export const BROKERAGE_OFFICE_TYPE = {
  SINGLE_FAMILY: 0,
  MULTI_FAMILY_AND_COMMERCIAL: 1
};

// Editor description text box
export const formats = [
  'header',
  'size',
  'align',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'code-block',
  'script',
  'color',
  'background',
  'list',
  'bullet',
  'indent',
  'link'
];

export const modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }],
    [{ size: ['small', false, 'large', 'huge'] }],
    [{ align: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
    [{ script: 'super' }, { script: 'sub' }],
    [{ color: [] }, { background: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['link'],
    ['clean']
  ]
};
