import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from './Button';
import {
  uploadIcon,
  dialogCloseIcon,
  imageListIcon,
  viewAction,
  deleteAction
} from '../constants/images';
import { ImagePreviewDialog } from './Popup/ImagePreviewDiaglog';
import { FormHelperText } from '@mui/material';

export const FileUploader = ({
  imagePreviewOpen,
  setImagePreviewOpen,
  handleChange,
  selectedFile,
  files,
  multiple,
  label,
  handleDelete,
  helperText,
  error,
  ...restProps
}) => {
  // drag state
  const [dragActive, setDragActive] = useState(false);
  const [path, setPath] = useState('');

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const openImagePreviewDialog = (path) => {
    setImagePreviewOpen(true);
    setPath(path);
  };
  const closeImagePreviewDialog = () => {
    setImagePreviewOpen(false);
  };

  return (
    <>
      <div className="file-upload-wrapper" onDragEnter={handleDrag}>
        <label
          id="label-file-upload"
          htmlFor="input-file-upload"
          className={dragActive ? 'drag-active' : ''}>
          {label}
        </label>
        <div className="file-upload-outer">
          <input
            type="file"
            {...restProps}
            id="input-file-upload"
            multiple={multiple}
            onChange={(e) => {
              handleChange(e);
            }}
          />
          <div className="file-upload-icon">
            <em>
              <img src={uploadIcon} alt="upload icon" />
            </em>
            <p>
              Drag and Drop or <span>Browse</span>
            </p>
          </div>
        </div>
        <div className="file-upload-inner">
          <div>
            {selectedFile ? (
              <>
                <div className="image-outer-wrapper">
                  <span className="blue-title">Uploaded Images</span>
                  {files.length !== 0 &&
                    files.map((file, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div className="image-wrapper">
                            <em>
                              <img src={imageListIcon} alt="Image list icon" />
                            </em>
                            <span className="image-name">{file?.name}</span>
                            <div className="action-wrapper">
                              <Button
                                name={<img src={viewAction} alt="view-icon" />}
                                title="View"
                                onClick={() =>
                                  openImagePreviewDialog(
                                    file?.pathName || URL.createObjectURL(file)
                                  )
                                }
                              />
                              <Button
                                name={<img src={deleteAction} alt="delete-icon" />}
                                onClick={() => handleDelete(file?.name)}
                                title="Delete"
                              />
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    })}
                </div>
              </>
            ) : null}
            <FormHelperText error={error}>{helperText}</FormHelperText>
          </div>
        </div>
      </div>
      <ImagePreviewDialog
        open={imagePreviewOpen}
        dialogIcon={dialogCloseIcon}
        onClose={closeImagePreviewDialog}
        handleClose={closeImagePreviewDialog}
        path={path}
      />
    </>
  );
};

FileUploader.propTypes = {
  imagePreviewOpen: PropTypes.bool,
  handleChange: PropTypes.func,
  setImagePreviewOpen: PropTypes.func,
  selectedFile: PropTypes.any,
  files: PropTypes.any,
  multiple: PropTypes.bool,
  label: PropTypes.string,
  handleDelete: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string
};
