import { API_ROUTES } from '../../constants/apiRoutes';
import api from '../../utils/api';

export const getDashboardApi = async () => {
  try {
    const response = await api.get(API_ROUTES.GET_DASHBOARD_DATA);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
