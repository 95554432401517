import { Dashboard } from '../pages/dashboard';
import { Logout } from '../pages/Auth/logout';
import { Profile } from '../pages/profile';
import { AddUser } from '../pages/Admin/addUser';
import { EditUser } from '../pages/Admin/editUser';
import { Settings } from '../pages/Admin/setting';
import { UserList } from '../pages/Admin/userList';
import { EmailBlastList } from '../pages/emailBlastList';
import { CreateAndCloneEmailBlast } from '../pages/Realtor/CreateAndCloneEmailBlast';
import { CreateBrokerageOffice } from '../pages/Admin/createBrokerageOffice';
import { BrokerageOfficesList } from '../pages/brokerageOfficesList';
import { EditBrokerageOffice } from '../pages/Admin/editBrokerageOffice';
import { CreateProperty } from '../pages/createProperty';
import { MyPropertyList } from '../pages/Realtor/myPropertyList';
import { PropertyList } from '../pages/propertyList';
import { EditProperty } from '../pages/editProperty';
import { MemberList } from '../pages/memberList';
import { TagRequestList } from '../pages/Realtor/tagRequestList';
import { GetPropertyNotification } from '../pages/Realtor/getPropertyNotification';
import { ALL_ROLES, EMAIL_BLAST_PAGE, ROLE } from './common';
import { ROUTE_LIST } from './routes';
import { EmailDraftList } from '../pages/Realtor/emailDraftList';

export const PRIVATE_ROUTES = [
  {
    path: ROUTE_LIST.LOGOUT,
    component: <Logout />,
    role: ALL_ROLES
  },
  {
    path: ROUTE_LIST.DASHBOARD,
    component: <Dashboard />,
    role: ALL_ROLES
  },
  {
    path: ROUTE_LIST.PROFILE,
    component: <Profile />,
    role: ALL_ROLES
  },
  {
    path: ROUTE_LIST.ADD_USER,
    component: <AddUser />,
    role: [ROLE.ADMIN]
  },
  {
    path: ROUTE_LIST.EDIT_USER,
    component: <EditUser />,
    role: [ROLE.ADMIN]
  },
  {
    path: ROUTE_LIST.SETTINGS,
    component: <Settings />,
    role: [ROLE.ADMIN]
  },
  {
    path: ROUTE_LIST.USER_LIST,
    component: <UserList />,
    role: [ROLE.ADMIN]
  },
  {
    path: ROUTE_LIST.EMAIL_BLAST,
    component: <EmailBlastList />,
    role: ALL_ROLES
  },
  {
    path: ROUTE_LIST.DRAFT_EMAIL_BLAST,
    component: <EmailDraftList />,
    role: [ROLE.REALTOR, ROLE.SALES_MANAGER]
  },
  {
    path: ROUTE_LIST.EDIT_DRAFTED_EMAIL_BLAST,
    component: <CreateAndCloneEmailBlast page={EMAIL_BLAST_PAGE.EDIT_EMAIL_DRAFT} />,
    role: [ROLE.REALTOR, ROLE.SALES_MANAGER]
  },
  {
    path: ROUTE_LIST.CREATE_EMAIL_BLAST,
    component: <CreateAndCloneEmailBlast page={EMAIL_BLAST_PAGE.CREATE_EMAIL_BLAST} />,
    role: [ROLE.REALTOR, ROLE.SALES_MANAGER]
  },
  {
    path: ROUTE_LIST.CREATE_BROKERAGE_OFFICE,
    component: <CreateBrokerageOffice />,
    role: [ROLE.ADMIN]
  },
  {
    path: ROUTE_LIST.BROKERAGE_OFFICE,
    component: <BrokerageOfficesList />,
    role: ALL_ROLES
  },
  {
    path: ROUTE_LIST.EDIT_BROKERAGE_OFFICE,
    component: <EditBrokerageOffice />,
    role: [ROLE.ADMIN]
  },
  {
    path: ROUTE_LIST.PROPERTIES,
    component: <PropertyList />,
    role: ALL_ROLES
  },
  {
    path: ROUTE_LIST.CREATE_PROPERTY,
    component: <CreateProperty />,
    role: ALL_ROLES
  },
  {
    path: ROUTE_LIST.MY_PROPERTIES,
    component: <MyPropertyList />,
    role: [ROLE.REALTOR]
  },
  {
    path: ROUTE_LIST.MEMBERS,
    component: <MemberList />,
    role: ALL_ROLES
  },
  {
    path: ROUTE_LIST.EDIT_PROPERTY,
    component: <EditProperty />,
    role: ALL_ROLES
  },
  {
    path: ROUTE_LIST.VIEW_TAG_REQUEST,
    component: <TagRequestList />,
    role: [ROLE.REALTOR]
  },
  {
    path: ROUTE_LIST.GET_NOTIFICATIONS,
    component: <GetPropertyNotification />,
    role: [ROLE.REALTOR]
  },
  {
    path: ROUTE_LIST.CLONE_EMAIL_BLAST,
    component: <CreateAndCloneEmailBlast page={EMAIL_BLAST_PAGE.CLONE_EMAIL_BLAST} />,
    role: [ROLE.REALTOR, ROLE.SALES_MANAGER]
  }
];
