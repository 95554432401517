import { ROUTE_LIST } from './routes';

import {
  dashboardIcon,
  dashboardIconWhite,
  userIcon,
  userIconWhite,
  buildingIcon,
  buildingIconWhite,
  memberIcon,
  memberIconWhite,
  emailBlastIcon,
  emailBlastIconWhite,
  settingIcon,
  settingIconWhite,
  propertyIcon,
  propertyIconWhite
} from './images';
import { ALL_ROLES, ROLE } from './common';

export const SIDE_BAR_MENU_LIST = [
  {
    name: 'Dashboard',
    icon: dashboardIcon,
    iconWhite: dashboardIconWhite,
    link: ROUTE_LIST.DASHBOARD,
    role: ALL_ROLES,
    disable: false
  },
  {
    name: 'Email Blasts',
    icon: emailBlastIcon,
    iconWhite: emailBlastIconWhite,
    link: ROUTE_LIST.EMAIL_BLAST,
    subMenu: [
      {
        name: 'All Emails',
        link: ROUTE_LIST.EMAIL_BLAST,
        isChild: true,
        disable: false
      },
      {
        name: 'Drafted Emails',
        link: ROUTE_LIST.DRAFT_EMAIL_BLAST,
        isChild: true,
        disable: false
      }
    ],
    role: [ROLE.REALTOR, ROLE.SALES_MANAGER],
    disable: false
  },
  {
    name: 'Email Blasts',
    icon: emailBlastIcon,
    iconWhite: emailBlastIconWhite,
    link: ROUTE_LIST.EMAIL_BLAST,
    role: [ROLE.ADMIN],
    disable: false
  },
  {
    name: 'Users',
    icon: userIcon,
    iconWhite: userIconWhite,
    link: ROUTE_LIST.USER_LIST,
    role: [ROLE.ADMIN],
    disable: false
  },
  {
    name: 'Brokerage Offices',
    icon: buildingIcon,
    iconWhite: buildingIconWhite,
    link: ROUTE_LIST.BROKERAGE_OFFICE,
    role: ALL_ROLES,
    disable: false
  },
  {
    name: 'Properties',
    icon: propertyIcon,
    iconWhite: propertyIconWhite,
    link: ROUTE_LIST.PROPERTIES,
    subMenu: [
      {
        name: 'All Properties',
        link: ROUTE_LIST.PROPERTIES,
        isChild: true,
        disable: false
      },
      {
        name: 'My Properties',
        link: ROUTE_LIST.MY_PROPERTIES,
        isChild: true,
        disable: false
      }
    ],
    role: [ROLE.REALTOR],
    disable: false
  },
  {
    name: 'Properties',
    icon: propertyIcon,
    iconWhite: propertyIconWhite,
    link: ROUTE_LIST.PROPERTIES,
    role: [ROLE.ADMIN, ROLE.SALES_MANAGER],
    disable: false
  },
  {
    name: 'Members',
    icon: memberIcon,
    iconWhite: memberIconWhite,
    link: ROUTE_LIST.MEMBERS,
    role: ALL_ROLES,
    disable: false
  },
  {
    name: 'Settings',
    icon: settingIcon,
    iconWhite: settingIconWhite,
    link: ROUTE_LIST.SETTINGS,
    role: [ROLE.ADMIN],
    disable: false
  }
];
