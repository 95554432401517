import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../components/Button';
import { TextField } from '../../components/TextField';
import { Label } from '../../components/Label';
import { Logo } from '../../components/Logo';
import { forgotPassword } from '../../redux/actions/auth';
import { forgotPasswordSchema } from '../../schemas/authSchema';
import { ROUTE_LIST } from '../../constants/routes';
import { LABELS } from '../../constants/common';
import './../../styles/login-style.css';

export const ForgotPassword = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.apiStatus);

  const initialValues = {
    email: ''
  };

  const { values, errors, handleChange, handleBlur, handleSubmit, touched } = useFormik({
    initialValues: initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values) => {
      dispatch(forgotPassword(values.email));
    }
  });

  return (
    <div>
      <div className="login-page verify-otp-page">
        <div className="login-wrapper">
          <div className="login-block">
            <div className="logo-wrapper">
              <Link to="#" className="logo">
                <Logo />
              </Link>
            </div>
            <Label variant="h1" label={LABELS.FORGOT_PASSWORD} />
            <p>Enter your email and we will send you instructions to reset your password</p>
            <form onSubmit={handleSubmit}>
              <div className="form-wrapper white-box">
                <TextField
                  fullWidth
                  id="email"
                  label="Email"
                  placeholder="Email Address"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  className="form-group has-required"
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className="link-wrapper">
                <Link to={ROUTE_LIST.LOGIN} className="blue-link">
                  {LABELS.BACK_TO_SIGN}
                </Link>
              </div>
              <Button
                className="blue-btn"
                type="submit"
                name="Send Reset Link"
                disabled={isLoading}
                isLoading={isLoading}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
