import { API_ROUTES } from '../../constants/apiRoutes';
import { defaultQueryData } from '../../constants/common';
import api from '../../utils/api';
import { getApiRoute } from '../../utils/helper';

export const getPropertiesApi = async (queryData = defaultQueryData) => {
  try {
    const response = await api.get(API_ROUTES.GET_PROPERTIES, { params: queryData });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getPropertyDetailApi = async (propertyId) => {
  try {
    const getPropertyDetailRoute = getApiRoute(API_ROUTES.GET_PROPERTY_DETAIL, propertyId);
    const response = await api.get(getPropertyDetailRoute);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getRealtorPropertiesApi = async () => {
  try {
    const response = await api.get(API_ROUTES.GET_REALTOR_PROPERTY_LIST);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const addPropertyApi = async (propertyData) => {
  try {
    const response = await api.post(API_ROUTES.CREATE_PROPERTY, {
      address: propertyData.address,
      city: propertyData.city,
      state: propertyData.state,
      zip4: propertyData.zipCode4,
      zip5: propertyData.zipCode5,
      stage: propertyData.stage,
      MLS: propertyData.source,
      brokerageOfficeId: propertyData.brokerageOffice,
      note: propertyData.note,
      status: propertyData.status,
      realtorId: propertyData.realtor
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const editPropertyApi = async (propertyData) => {
  try {
    const updatePropertyApiRoute = getApiRoute(API_ROUTES.UPDATE_PROPERTY, propertyData.id);
    const response = await api.put(updatePropertyApiRoute, {
      address: propertyData.address,
      city: propertyData.city,
      state: propertyData.state,
      zip5: propertyData.zipCode5,
      stage: propertyData.stage,
      MLS: propertyData.source,
      brokerageOfficeId: propertyData.brokerageOffice,
      note: propertyData.note,
      status: propertyData.status
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const deletePropertyApi = async (property) => {
  try {
    const deletePropertyApiRoute = getApiRoute(API_ROUTES.DELETE_PROPERTY, property.id);
    const response = await api.delete(deletePropertyApiRoute);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
