import { Autocomplete, Checkbox, FormHelperText, InputLabel } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import PropTypes from 'prop-types';
import { TextField } from '../TextField';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const AutoCompleteMultiSelect = ({
  options,
  className,
  label,
  placeholder,
  classes,
  value,
  onChange,
  name,
  helperText,
  error = false,
  filterOptions,
  ...restProps
}) => {
  return (
    <div className="form-group">
      <InputLabel id="multi-select-outlined-label">{label}</InputLabel>
      <Autocomplete
        multiple
        filterOptions={filterOptions} // For set options based on search server side
        value={value}
        options={options}
        disableCloseOnSelect
        getOptionLabel={(option) => option.label}
        onChange={onChange}
        classes={classes}
        isOptionEqualToValue={(option, value) => {
          return option.value === value.value;
        }}
        {...restProps}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.label}
          </li>
        )}
        className={className}
        renderInput={(params) => <TextField {...params} placeholder={placeholder} name={name} />}
      />
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </div>
  );
};

AutoCompleteMultiSelect.propTypes = {
  options: PropTypes.array.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  classes: PropTypes.object,
  name: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  filterOptions: PropTypes.func
};
