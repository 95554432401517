import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from '../../components/DataTable';
import { PageLoader } from '../../components/PageLoader';
import { getRelationshipHistory } from '../../redux/actions/realtor';
import { defaultQueryData } from '../../constants/common';
import '../../styles/inner-page-style.css';
import '../../styles/data-table-style.css';
import '../../styles/custom-dialog-style.css';

export const RelationshipHistory = ({ propertyId }) => {
  const dispatch = useDispatch();
  const [queryData, setQueryData] = useState({ ...defaultQueryData });
  const { relationshipHistoryData, paginationData } = useSelector((state) => state.realtor);
  const { isLoading } = useSelector((state) => state.apiStatus);

  useEffect(() => {
    dispatch(getRelationshipHistory(queryData, propertyId));
  }, []);
  const columns = [
    { field: 'requesteeName', headerName: 'Old Realtor', flex: 1, minWidth: 150, sortable: false },
    {
      field: 'requesterName',
      headerName: 'New Realtor',
      flex: 1,
      minWidth: 150,
      sortable: false
    },
    { field: 'updatedAt', headerName: 'Tagged Date', flex: 1, minWidth: 150, sortable: false }
  ];

  const paginationChangeHandler = (paginationModelData) => {
    const paginationQueryData = {
      ...queryData,
      page: paginationModelData.page + 1,
      perPage: paginationModelData.pageSize
    };
    setQueryData(paginationQueryData);
    dispatch(getRelationshipHistory(paginationQueryData, propertyId));
  };

  return (
    <div className="white-box shadow-box page-table-wrapper">
      {isLoading && <PageLoader />}
      <div className="table-wrapper">
        <DataTable
          handlePagination={paginationChangeHandler}
          total={paginationData && paginationData.total ? paginationData.total : 0}
          columns={columns}
          rows={relationshipHistoryData}
          rowHeight={56}
          paginationMode="server"
        />
      </div>
    </div>
  );
};

RelationshipHistory.propTypes = {
  propertyId: PropTypes.number
};
