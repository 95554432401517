import { TextField, Tooltip } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { BreadCrumb } from '../components/BreadCrumb';
import { Button } from '../components/Button';
import { SingleSelect } from '../components/DropDown/SingleSelect';
import { Label } from '../components/Label';
import { PageLoader } from '../components/PageLoader';
import { editProperty } from '../redux/actions/property';
import { getRealtorBrokerageOfficeApi, validatePropertyApi } from '../redux/apis/realtor';
import { getPropertyDetailApi } from '../redux/apis/property';
import { store } from '../redux/store';
import { manageProperty } from '../redux/slices/property';
import {
  BREAD_CRUMB_LABELS,
  LABELS,
  MESSAGES,
  ROLE,
  STAGE_LABELS,
  STATUS_ITEMS
} from '../constants/common';
import { ROUTE_LIST } from '../constants/routes';
import { addPropertySchema } from '../schemas/propertySchema';
import './../styles/inner-page-style.css';
import './../styles/form-box-layout-style.css';

export const EditProperty = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [brokerageOfficeOptions, setBrokerageOfficeOptions] = useState([]);
  const [isValidatedAddress, setIsValidateAddress] = useState(true);
  const [validateLoading, setValidateLoading] = useState(false);
  const [propertyData, setPropertyData] = useState();
  const { isLoading, isSucceed } = useSelector((state) => state.apiStatus);
  const [isEdited, setIsEdited] = useState(false);
  const { propertyFlag: property } = useSelector((state) => state.property);
  const { role } = useSelector((state) => state.auth);
  const [stageArray, setStageArray] = useState([]);

  const breadcrumbsLinks = [
    {
      label: BREAD_CRUMB_LABELS.DASHBOARD,
      to: ROUTE_LIST.DASHBOARD
    },
    {
      label:
        role?.name === ROLE.REALTOR
          ? BREAD_CRUMB_LABELS.MY_PROPERTIES
          : BREAD_CRUMB_LABELS.PROPERTIES,
      to: role?.name === ROLE.REALTOR ? ROUTE_LIST.MY_PROPERTIES : ROUTE_LIST.PROPERTIES
    },
    {
      label: BREAD_CRUMB_LABELS.EDIT_PROPERTY,
      last: true
    }
  ];

  useEffect(() => {
    // set edited flag false initially
    store.dispatch(manageProperty({ isPropertyEdited: false }));
    (async () => {
      const BrokerageOfficeRes = await getRealtorBrokerageOfficeApi();
      setBrokerageOfficeOptions(BrokerageOfficeRes.data.data);
      const propertyRes = await getPropertyDetailApi(id);
      setPropertyData(propertyRes.data.data);
    })();
  }, []);

  useEffect(() => {
    const stageItemsArr = [];
    Object.keys(STAGE_LABELS).map((item) => {
      stageItemsArr.push({
        id: STAGE_LABELS[item],
        name: STAGE_LABELS[item]
      });
    });
    stageItemsArr.sort((a, b) => a.name.localeCompare(b.name));
    setStageArray(stageItemsArr);
  }, [STAGE_LABELS]);

  useEffect(() => {
    if (isSucceed && isEdited && property?.isPropertyEdited) {
      role?.name === ROLE.REALTOR
        ? navigate(ROUTE_LIST.MY_PROPERTIES)
        : navigate(ROUTE_LIST.PROPERTIES);
    }
  }, [isSucceed, isEdited, property]);

  const initialValues = {
    address: propertyData?.address || '',
    zipCode5: propertyData?.zip5 || '',
    city: propertyData?.city || '',
    source: propertyData?.MLS || '',
    state: propertyData?.state || '',
    note: propertyData?.note || '',
    // remove or condition because if status is inactive then autoselect no option as 0 value is for inactive
    status: propertyData?.status,
    brokerageOffice: propertyData?.brokerageOfficeId || '',
    stage: propertyData?.stage || ''
  };
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    setFieldValue,
    setFieldTouched
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: addPropertySchema,
    onSubmit: (values) => {
      dispatch(
        editProperty({
          ...values,
          id: id
        })
      );
      setIsEdited(true);
    }
  });

  const validateAddressHandler = () => {
    // set error if address fields are empty
    setFieldTouched('address');
    setFieldTouched('city');
    setFieldTouched('state');
    if (values.address && values.city && values.state) {
      setValidateLoading(true);
      validatePropertyApi({
        ...values
      })
        .then((res) => {
          const { address, city, state, zip5 } = res.data.data;
          setFieldValue('address', address);
          setFieldValue('city', city);
          setFieldValue('state', state);
          setFieldValue('zipCode5', zip5);
          setIsValidateAddress(true);
          setValidateLoading(false);
        })
        .catch(() => {
          setIsValidateAddress(false);
          setValidateLoading(false);
        });
    }
  };

  return (
    <>
      <div className="page-inner">
        <div className="page-content-block">
          <div className="inner-page-title-wrapper">
            <div className="title-left-block">
              <BreadCrumb links={breadcrumbsLinks} />
              <Label variant="h1" label={LABELS.EDIT_PROPERTY} />
            </div>
          </div>
          <div className="white-box shadow-box">
            {isLoading && !propertyData && <PageLoader />}
            <form onSubmit={handleSubmit}>
              <div className="form-row-wrapper">
                <div className="form-col has-required form-address-field">
                  <TextField
                    fullWidth
                    id="address"
                    label="Address"
                    placeholder="Address"
                    name="address"
                    className="form-group"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => {
                      handleChange(e);
                      if (e.target.value !== propertyData.address) {
                        setIsValidateAddress(false);
                      } else {
                        setIsValidateAddress(true);
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.address}
                    error={touched.address && Boolean(errors.address)}
                    helperText={touched.address && errors.address}
                  />
                </div>
                <div className="form-col has-required form-addresses">
                  <TextField
                    fullWidth
                    id="city"
                    label="City"
                    placeholder="City"
                    name="city"
                    className="form-group"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => {
                      handleChange(e);
                      if (e.target.value !== propertyData.city) {
                        setIsValidateAddress(false);
                      } else {
                        setIsValidateAddress(true);
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.city}
                    error={touched.city && Boolean(errors.city)}
                    helperText={touched.city && errors.city}
                  />
                </div>
                <div className="form-col has-required form-addresses">
                  <TextField
                    fullWidth
                    id="state"
                    label="State"
                    placeholder="State"
                    name="state"
                    className="form-group"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => {
                      handleChange(e);
                      if (e.target.value !== propertyData.state) {
                        setIsValidateAddress(false);
                      } else {
                        setIsValidateAddress(true);
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.state}
                    error={touched.state && Boolean(errors.state)}
                    helperText={touched.state && errors.state}
                  />
                </div>
                <div className="form-col form-addresses">
                  <TextField
                    fullWidth
                    id="zipCode5"
                    label="Zip Code"
                    placeholder="Zip Code"
                    name="zipCode5"
                    className="form-group"
                    InputLabelProps={{ shrink: true }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled
                    value={values.zipCode5}
                    error={touched.zipCode5 && Boolean(errors.zipCode5)}
                    helperText={touched.zipCode5 && errors.zipCode5}
                  />
                </div>
              </div>
              <div className="form-btn-wrapper">
                <Button
                  className="primary-btn"
                  name="Validate Address"
                  onClick={validateAddressHandler}
                  isLoading={validateLoading}
                />
              </div>
              <div className="form-row-wrapper">
                <div className="form-col has-required">
                  <TextField
                    fullWidth
                    id="source"
                    label="MLS/Source"
                    placeholder="MLS/Source"
                    name="source"
                    className="form-group"
                    InputLabelProps={{ shrink: true }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.source}
                    error={touched.source && Boolean(errors.source)}
                    helperText={touched.source && errors.source}
                  />
                </div>
                <div className="form-col has-required">
                  <SingleSelect
                    id="stage"
                    className="form-group"
                    placeholderText="Stage"
                    InputLabelProps={{ shrink: true }}
                    items={stageArray}
                    label="Stage"
                    MenuProps={{ classes: { paper: 'custom-select-dropdown' } }}
                    name="stage"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.stage}
                    error={touched.stage && Boolean(errors.stage)}
                    helperText={touched.stage && errors.stage}
                  />
                </div>
                <div className="form-col has-required full-width">
                  <TextField
                    fullWidth
                    id="note"
                    label="Latest Note"
                    placeholder="Latest Note"
                    name="note"
                    className="form-group"
                    InputLabelProps={{ shrink: true }}
                    multiline
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.note}
                    error={touched.note && Boolean(errors.note)}
                    helperText={touched.note && errors.note}
                  />
                </div>
                <div className="form-col has-required">
                  <SingleSelect
                    placeholderText="Status"
                    items={STATUS_ITEMS}
                    label="Status"
                    MenuProps={{ classes: { paper: 'custom-select-dropdown' } }}
                    name="status"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.status}
                    error={touched.status && Boolean(errors.status)}
                    helperText={touched.status && errors.status}
                  />
                </div>
                <div className="form-col has-required">
                  <SingleSelect
                    placeholderText="Brokerage Office"
                    items={brokerageOfficeOptions}
                    label="Brokerage Office"
                    MenuProps={{ classes: { paper: 'custom-select-dropdown' } }}
                    name="brokerageOffice"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.brokerageOffice}
                    error={touched.brokerageOffice && Boolean(errors.brokerageOffice)}
                    helperText={touched.brokerageOffice && errors.brokerageOffice}
                    disabled={role?.name !== ROLE.REALTOR}
                  />
                </div>
              </div>
              <div className="form-btn-wrapper">
                <Button
                  className="primary-btn border-btn"
                  name="Cancel"
                  onClick={() =>
                    role?.name === ROLE.REALTOR
                      ? navigate(ROUTE_LIST.MY_PROPERTIES)
                      : navigate(ROUTE_LIST.PROPERTIES)
                  }
                />
                <Tooltip title={!isValidatedAddress ? MESSAGES.VALIDATE_ADDRESS_TAG : ''} arrow>
                  <span>
                    <Button
                      className="primary-btn"
                      type="submit"
                      name="+ Save"
                      disabled={!isValidatedAddress}
                    />
                  </span>
                </Tooltip>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
