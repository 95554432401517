// Assign the empty path string if the prefix path value is undefined.
const PREFIX = process.env.REACT_APP_PREFIX_ROUTE ? `/${process.env.REACT_APP_PREFIX_ROUTE}` : '';

// Public routes
export const ROUTE_LIST = {
  LOGIN: `${PREFIX}/login`,
  FORGOT_PASSWORD: `${PREFIX}/forgot-password`,
  RESET_PASSWORD: `${PREFIX}/reset-password`,
  VERIFY_OTP: `${PREFIX}/verify-otp`,
  CHANGE_PASSWORD: `${PREFIX}/change-password`,
  LOGOUT: `${PREFIX}/logout`,
  DASHBOARD: `${PREFIX}/dashboard`,
  PROFILE: `${PREFIX}/profile`,
  USER_LIST: `${PREFIX}/users`,
  ADD_USER: `${PREFIX}/users/create`,
  EDIT_USER: `${PREFIX}/users/edit/:id`,
  SETTINGS: `${PREFIX}/settings`,
  EMAIL_BLAST: `${PREFIX}/email-blast`,
  CREATE_EMAIL_BLAST: `${PREFIX}/email-blast/create`,
  MEMBERS: `${PREFIX}/members`,
  BROKERAGE_OFFICE: `${PREFIX}/brokerage-offices`,
  CREATE_BROKERAGE_OFFICE: `${PREFIX}/brokerage-offices/create`,
  EDIT_BROKERAGE_OFFICE: `${PREFIX}/brokerage-offices/edit/:id`,
  PROPERTIES: `${PREFIX}/properties`,
  MY_PROPERTIES: `${PREFIX}/my-properties`,
  CREATE_PROPERTY: `${PREFIX}/my-properties/create`,
  EDIT_PROPERTY: `${PREFIX}/my-properties/edit/:id`,
  VIEW_TAG_REQUEST: `${PREFIX}/my-properties/tag-requests`,
  GET_NOTIFICATIONS: `${PREFIX}/my-properties/notifications`,
  CLONE_EMAIL_BLAST: `${PREFIX}/email-blast/clone/:id`,
  DRAFT_EMAIL_BLAST: `${PREFIX}/email-draft`,
  EDIT_DRAFTED_EMAIL_BLAST: `${PREFIX}/email-blast/draft/:id`
};
