import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { DataTable } from '../components/DataTable';
import { ConfirmDialog } from '../components/Popup/ConfirmDialog';
import { ViewBrokerageOfficeDialog } from '../components/Popup/ViewBrokerageOfficeDialog';
import { Label } from '../components/Label';
import { BreadCrumb } from '../components/BreadCrumb';
import { PageLoader } from '../components/PageLoader';
import { Button } from '../components/Button';
import { deleteBrokerageOffice } from '../redux/actions/admin';
import { getBrokerageOffices } from '../redux/actions/brokerageOffice';
import { apiStatusClear } from '../redux/slices/apiStatus';
import { ROUTE_LIST } from '../constants/routes';
import { DEBOUNCE_TIMEOUT, defaultQueryData, DIALOG, LABELS, ROLE } from '../constants/common';
import {
  dialogDeleteIcon,
  dialogCloseIcon,
  viewAction,
  editAction,
  deleteAction
} from '../constants/images';
import { debounce } from '../utils/helper';
import './../styles/inner-page-style.css';
import './../styles/data-table-style.css';
import './../styles/custom-dialog-style.css';

export const BrokerageOfficesList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [brokerageOffice, setBrokerageOffice] = useState();
  const [openDialog, setOpen] = useState(false);
  const [viewBrokerageOfficeOpen, setViewBrokerageOfficeOpen] = useState(false);
  const { brokerageOfficeData, paginationData } = useSelector((state) => state.brokerageOffice);
  const { isLoading } = useSelector((state) => state.apiStatus);
  const { role } = useSelector((state) => state.auth);
  const [queryData, setQueryData] = useState({ ...defaultQueryData });

  const openDeleteDialog = (params) => {
    setOpen(true);
    setBrokerageOffice(params);
  };
  const closeDeleteDialog = () => {
    setOpen(false);
  };

  const deleteBrokerageOfficeHandler = () => {
    dispatch(deleteBrokerageOffice({ brokerageOfficeId: brokerageOffice.id, queryData }));
    setOpen(false);
  };

  const buttonsList = [
    {
      name: 'Cancel',
      className: 'primary-btn border-btn',
      event: closeDeleteDialog
    },
    {
      name: 'Delete',
      className: 'primary-btn',
      event: deleteBrokerageOfficeHandler
    }
  ];

  const openViewBrokerageOfficeDialog = (params) => {
    setBrokerageOffice(params.row);
    setViewBrokerageOfficeOpen(true);
  };
  const closeViewBrokerageOfficeDialog = () => {
    setViewBrokerageOfficeOpen(false);
  };

  useEffect(() => {
    dispatch(getBrokerageOffices(queryData));
  }, []);

  const sortingChangeHandler = (sortModelData) => {
    if (sortModelData && sortModelData.length > 0) {
      const sortQueryData = {
        ...queryData,
        sortField: sortModelData[0].field,
        sortDirection: sortModelData[0].sort
      };
      setQueryData(sortQueryData);
      dispatch(getBrokerageOffices(sortQueryData));
    }
  };

  const paginationChangeHandler = (paginationModelData) => {
    const paginationQueryData = {
      ...queryData,
      page: paginationModelData.page + 1,
      perPage: paginationModelData.pageSize
    };
    setQueryData(paginationQueryData);
    dispatch(getBrokerageOffices(paginationQueryData));
  };

  const editActionHandler = (params) => {
    navigate(`${ROUTE_LIST.BROKERAGE_OFFICE}/edit/${params.id}`);
  };

  const columns = [
    { field: 'name', headerName: 'Name', flex: 1, minWidth: 700 },
    {
      field: 'salesManagerName',
      headerName: 'Sales Manager',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <>
            <span className="user-fname">
              {params.row.salesManagerFirstName} {params.row.salesManagerLastName}
            </span>
          </>
        );
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      minWidth: 150,
      headerClassName: 'action-col',
      cellClassName: 'action-col',
      sortable: false,
      headerAlign: 'right',
      align: 'right',
      renderCell: (params) => {
        return (
          <div className="action-column-wrapper">
            <div className="action-column-inner">
              <Button
                title="View"
                className="view-icon"
                onClick={() => openViewBrokerageOfficeDialog(params)}
                name={<img src={viewAction} alt="view-icon" />}
              />
            </div>
            {role?.name === ROLE.ADMIN && (
              <>
                <div className="action-column-inner">
                  <Button
                    title="Edit"
                    className="edit-icon"
                    onClick={() => editActionHandler(params)}
                    name={<img src={editAction} alt="edit-icon" />}
                  />
                </div>
                <div className="action-column-inner">
                  <Button
                    title="Delete"
                    className="delete-icon"
                    onClick={() => openDeleteDialog(params)}
                    name={<img src={deleteAction} alt="delete-icon" />}
                  />
                </div>
              </>
            )}
          </div>
        );
      }
    }
  ];

  const breadcrumbsLinks = [
    {
      label: 'Dashboard',
      to: ROUTE_LIST.DASHBOARD
    },
    {
      label: 'Brokerage Offices',
      last: true
    }
  ];

  const handleCreateBrokerageOffice = () => {
    dispatch(apiStatusClear());
  };

  // Search brokerage offices based on timeout seconds
  const handleSearch = debounce((value) => {
    if (value === '') {
      delete queryData.search;
      dispatch(getBrokerageOffices(queryData));
    } else {
      setSearch(value);
    }
  }, DEBOUNCE_TIMEOUT);
  const searchHandler = () => {
    const searchQueryData = {
      ...queryData,
      search: search
    };
    setQueryData(searchQueryData);
    dispatch(getBrokerageOffices(searchQueryData));
  };
  useEffect(() => {
    if (search !== '') {
      searchHandler();
    }
  }, [search]);

  return (
    <>
      <div className="page-inner">
        <div className="page-content-block">
          <div className="inner-page-title-wrapper">
            <div className="title-left-block">
              <BreadCrumb links={breadcrumbsLinks} />
              <Label variant="h1" label={LABELS.BROKERAGE_OFFICES} />
            </div>
            {role?.name === ROLE.ADMIN && (
              <div className="title-right-block">
                <div className="right-block-inner">
                  <Link
                    to={ROUTE_LIST.CREATE_BROKERAGE_OFFICE}
                    onClick={handleCreateBrokerageOffice}
                    className="primary-btn"
                    title="Create New Brokerage Office">
                    + Create New Brokerage Office
                  </Link>
                </div>
              </div>
            )}
          </div>
          <div className="white-box shadow-box page-table-wrapper">
            {isLoading && <PageLoader />}
            <div className="filter-wrapper">
              <div className="filter-left-block">
                <TextField
                  id="search"
                  label="Search"
                  type="search"
                  placeholder="Search here..."
                  name="search"
                  className="form-group search-field"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => handleSearch(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
              </div>
            </div>
            <div className="table-wrapper no-checkbox">
              <DataTable
                handlePagination={paginationChangeHandler}
                total={paginationData && paginationData.total ? paginationData.total : 0}
                columns={columns}
                rows={brokerageOfficeData}
                rowHeight={56}
                handleSorting={sortingChangeHandler}
                sortingMode="server"
                paginationMode="server"
                onRowSelectionModelChange={(selectedRow) => {
                  setRowSelectionModel(selectedRow);
                }}
                rowSelectionModel={rowSelectionModel}
              />
            </div>
          </div>
        </div>
      </div>
      <ConfirmDialog
        open={openDialog}
        onClose={closeDeleteDialog}
        dialogIcon={dialogDeleteIcon}
        dialogTitle={DIALOG.DELETE_BROKERAGE_OFFICE_DIALOG_TITLE}
        dialogBody={DIALOG.DELETE_DIALOG_BODY}
        buttons={buttonsList}
      />
      <ViewBrokerageOfficeDialog
        open={viewBrokerageOfficeOpen}
        dialogIcon={dialogCloseIcon}
        onClose={closeViewBrokerageOfficeDialog}
        handleClose={closeViewBrokerageOfficeDialog}
        brokerageOfficeData={brokerageOffice}
      />
    </>
  );
};
