import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { InputAdornment } from '@mui/material';
import { Label } from '../../components/Label';
import { BreadCrumb } from '../../components/BreadCrumb';
import { TextField } from '../../components/TextField';
import { Button } from '../../components/Button';
import { PageLoader } from '../../components/PageLoader';
import { getSettings, updateSettings } from '../../redux/actions/admin';
import { LABELS } from '../../constants/common';
import { ROUTE_LIST } from '../../constants/routes';
import { adminSettingSchema } from '../../schemas/adminSchema';
import './../../styles/inner-page-style.css';
import './../../styles/form-box-layout-style.css';
import './../../styles/settings-style.css';

export const Settings = () => {
  const dispatch = useDispatch();
  const { settingsData } = useSelector((state) => state.admin);
  const { isLoading } = useSelector((state) => state.apiStatus);
  const [isSettingChanged, setSettingChanged] = useState(false);
  const breadcrumbsLinks = [
    {
      label: 'Dashboard',
      to: ROUTE_LIST.DASHBOARD
    },
    {
      label: 'Settings',
      last: true
    }
  ];

  useEffect(() => {
    if (!settingsData) dispatch(getSettings());
  }, []);

  // for converting into name and value object
  const settingObj = settingsData?.reduce((obj, item) => {
    obj[item.name] = item.value;
    return obj;
  }, {});

  const initialValues = {
    imageLimit: settingObj?.email_max_image_limit || '',
    propertyTag: settingObj?.property_tag_reminder_interval_in_days || '',
    propertyTagReminder: settingObj?.property_tag_reminder_no_response_interval_in_days || ''
  };

  const { values, errors, handleChange, handleBlur, handleSubmit, touched, resetForm } = useFormik({
    initialValues: initialValues,
    validationSchema: adminSettingSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(updateSettings(values));
      setSettingChanged(true);
    }
  });

  return (
    <div className="page-inner">
      <div className="page-content-block settings-page">
        <div className="inner-page-title-wrapper">
          <div className="title-left-block">
            <BreadCrumb links={breadcrumbsLinks} />
            <Label variant="h1" label={LABELS.SETTINGS} />
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="white-box shadow-box setting-page">
            {isLoading && !isSettingChanged && <PageLoader />}
            <div className="setting-option">
              <div className="setting-option-inner">
                <div className="option-info">
                  <span className="blue-text">Email max image limit</span>
                  <p>Manage maximum number of images to be sent along with email</p>
                </div>
                <div className="option-field">
                  <TextField
                    name="imageLimit"
                    fullWidth
                    type="number"
                    id="emailimage"
                    placeholder="0"
                    className="form-group"
                    InputLabelProps={{ shrink: true }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.imageLimit}
                    error={touched.imageLimit && Boolean(errors.imageLimit)}
                    helperText={touched.imageLimit && errors.imageLimit}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="white-box shadow-box setting-page">
            <div className="setting-option">
              <div className="setting-option-inner">
                <div className="option-info">
                  <span className="blue-text">Property tag reminder interval</span>
                  <p>Manage default reminder time frame</p>
                </div>
                <div className="option-field">
                  <TextField
                    fullWidth
                    type="number"
                    id="propertytag"
                    placeholder="0"
                    name="propertyTag"
                    className="form-group suffix"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">Days</InputAdornment>
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.propertyTag}
                    error={touched.propertyTag && Boolean(errors.propertyTag)}
                    helperText={touched.propertyTag && errors.propertyTag}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="white-box shadow-box setting-page">
            <div className="setting-option">
              <div className="setting-option-inner">
                <div className="option-info">
                  <span className="blue-text">Property tag reminder no response interval </span>
                  <p>Manage reminder time frame for no response interval</p>
                </div>
                <div className="option-field">
                  <TextField
                    fullWidth
                    type="number"
                    id="propertyTagReminder"
                    placeholder="0"
                    name="propertyTagReminder"
                    className="form-group suffix"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">Days</InputAdornment>
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.propertyTagReminder}
                    error={touched.propertyTagReminder && Boolean(errors.propertyTagReminder)}
                    helperText={touched.propertyTagReminder && errors.propertyTagReminder}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form-btn-wrapper setting-page-btn">
            <Button className="primary-btn border-btn" name="Cancel" onClick={resetForm} />
            <Button
              className="primary-btn"
              type="submit"
              name="Save"
              disabled={isLoading && isSettingChanged}
              isLoading={isLoading && isSettingChanged}
            />
          </div>
        </form>
      </div>
    </div>
  );
};
