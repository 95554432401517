import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { InputAdornment } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Button } from '../../components/Button';
import { TextField } from '../../components/TextField';
import { Label } from '../../components/Label';
import { Logo } from '../../components/Logo';
import { firstLoginChangePassword } from '../../redux/actions/auth';
import { LABELS } from '../../constants/common';
import { ROUTE_LIST } from '../../constants/routes';
import { resetPasswordSchema } from '../../schemas/authSchema';
import './../../styles/login-style.css';

export const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showconfirmPassword, setShowconfirmPassword] = useState(false);
  const { isLoading, isSucceed } = useSelector((state) => state.apiStatus);

  useEffect(() => {
    if (isSucceed) navigate(ROUTE_LIST.DASHBOARD);
  }, [isSucceed]);

  const initialValues = {
    password: '',
    confirmPassword: ''
  };

  const { values, errors, handleChange, handleBlur, handleSubmit, touched } = useFormik({
    initialValues: initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: (values) => {
      dispatch(firstLoginChangePassword(values));
    }
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowconfirmPassword = () => setShowconfirmPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownconfirmPassword = (event) => {
    event.preventDefault();
  };
  return (
    <div>
      <div className="login-page reset-password-page">
        <div className="login-wrapper">
          <div className="login-block">
            <div className="logo-wrapper">
              <Link to="#" className="logo">
                <Logo />
              </Link>
            </div>
            <Label variant="h1" label={LABELS.CHANGE_PASSWORD} />
            <form onSubmit={handleSubmit}>
              <div className="form-wrapper white-box">
                <TextField
                  fullWidth
                  name="password"
                  label="New Password"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  placeholder="New Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  className="form-group password-wrapper has-required"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <TextField
                  fullWidth
                  name="confirmPassword"
                  label="Confirm New Password"
                  type={showconfirmPassword ? 'text' : 'password'}
                  id="confirmPassword"
                  placeholder="Confirm New Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirmPassword}
                  error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  className="form-group password-wrapper has-required"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowconfirmPassword}
                          onMouseDown={handleMouseDownconfirmPassword}
                          edge="end">
                          {showconfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </div>
              <div className="btn-wrapper">
                <Button
                  className="blue-btn"
                  type="submit"
                  name={LABELS.CHANGE_PASSWORD}
                  disabled={isLoading}
                  isLoading={isLoading}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
