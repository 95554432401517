import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  MESSAGES,
  REMINDER_ACTIONS,
  RESPONSE_STATUS_CODES,
  TAG_REQUEST_ACTION
} from '../../constants/common';
import {
  ADD_PROPERTY_TAG_REQUEST,
  GET_PROPERTY_HISTORY_REQUEST,
  MY_PROPERTY_REQUEST,
  GET_PROPERTIES_TAG_REQUEST,
  PROPERTY_TAG_ACTION_REQUEST,
  GET_RELATIONSHIP_HISTORY_REQUEST,
  CREATE_EMAIL_BLAST_REQUEST,
  GET_PROPERTY_NOTIFICATION_REQUEST,
  PROPERTY_NOTIFICATION_ACTION_REQUEST,
  SAVE_DRAFT_EMAIL_BLAST_REQUEST,
  EDIT_DRAFT_EMAIL_BLAST_REQUEST,
  DELETE_DRAFT_EMAIL_BLAST_REQUEST
} from '../../constants/redux';
import { getPropertiesApi } from '../apis/property';
import {
  addPropertyTagRequestApi,
  getPropertyHistoryApi,
  myPropertiesApi,
  getPropertyTagRequestApi,
  propertyTagRequestActionApi,
  getRelationshipHistoryApi,
  addEmailBlastApi,
  getPropertyNotificationApi,
  propertyTagNotificationActionApi,
  draftEmailBlastApi,
  editDraftEmailBlastApi,
  deleteDraftEmailBlastApi
} from '../apis/realtor';
import { apiFail } from '../slices/apiStatus';
import { getProperty } from '../slices/property';
import {
  getPropertyHistory,
  getPropertyNotification,
  getRelationshipHistory,
  manageEmailBlast,
  myProperty,
  propertyTagRequest
} from '../slices/realtor';
import { getDraftEmailBlastApi } from '../apis/emailBlast';
import { getDraftEmailBlast } from '../slices/emailBlast';

function* myPropertiesSaga(action) {
  try {
    const response = yield call(myPropertiesApi, action.payload);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      yield put(myProperty(result));
    }
  } catch (err) {
    yield put(apiFail());
  }
}

function* addPropertyTagRequestSaga(action) {
  try {
    const addRequestRes = yield call(addPropertyTagRequestApi, action.payload);
    if (addRequestRes?.status === RESPONSE_STATUS_CODES.OK) {
      toast.success(MESSAGES.SUCCESSFULLY_SEND_TAG_REQUEST);
      const response = yield call(getPropertiesApi);
      if (response?.status === RESPONSE_STATUS_CODES.OK) {
        const result = response.data.data;
        yield put(getProperty(result));
      }
    }
  } catch (err) {
    yield put(apiFail());
  }
}

function* getPropertyHistorySaga(action) {
  try {
    const { propertyId, queryData } = action.payload;
    const response = yield call(getPropertyHistoryApi, { queryData, propertyId });
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      yield put(getPropertyHistory(result));
    }
  } catch (err) {
    yield put(apiFail());
  }
}

function* getPropertyTagRequestSaga(action) {
  try {
    const response = yield call(getPropertyTagRequestApi, action.payload);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      yield put(propertyTagRequest(result));
    }
  } catch (err) {
    yield put(apiFail());
  }
}

function* propertyTagRequestActionSaga(action) {
  try {
    const actionData = yield call(propertyTagRequestActionApi, action.payload);
    if (actionData?.status === RESPONSE_STATUS_CODES.OK) {
      action.payload.tagAction === TAG_REQUEST_ACTION.ACCEPTED
        ? toast.success(MESSAGES.ACCEPTED_TAG_REQUEST)
        : toast.success(MESSAGES.REJECTED_TAG_REQUEST);
      const response = yield call(getPropertyTagRequestApi);
      if (response?.status === RESPONSE_STATUS_CODES.OK) {
        const result = response.data.data;
        yield put(propertyTagRequest(result));
      }
    }
  } catch (err) {
    yield put(apiFail());
  }
}

function* getRelationshipHistorySaga(action) {
  try {
    const { propertyId, queryData } = action.payload;
    const response = yield call(getRelationshipHistoryApi, { queryData, propertyId });
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      yield put(getRelationshipHistory(result));
    }
  } catch (err) {
    yield put(apiFail());
  }
}

function* getPropertyNotificationSaga(action) {
  try {
    const response = yield call(getPropertyNotificationApi, action.payload.queryData);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      yield put(getPropertyNotification(result));
    }
  } catch (err) {
    yield put(apiFail());
  }
}

function* propertyTagNotificationActionSaga(action) {
  try {
    const actionResponse = yield call(propertyTagNotificationActionApi, action.payload);
    if (actionResponse?.status === RESPONSE_STATUS_CODES.OK) {
      const response = yield call(getPropertyNotificationApi);
      if (response?.status === RESPONSE_STATUS_CODES.OK) {
        const result = response.data.data;
        yield put(getPropertyNotification(result));
        action.payload.action === REMINDER_ACTIONS.RETAIN
          ? toast.success(MESSAGES.SUCCESSFULLY_RETAIN_PROPERTY)
          : toast.success(MESSAGES.SUCCESSFULLY_RELEASE_PROPERTY);
      }
    }
  } catch (err) {
    yield put(apiFail());
  }
}

function* addEmailBlastSaga(action) {
  try {
    const response = yield call(addEmailBlastApi, action.payload);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      yield put(manageEmailBlast({ isEmailBlastAdded: true }));
      toast.success(MESSAGES.SUCCESSFULLY_SENT_AN_EMAIL_BLAST);
    }
  } catch (err) {
    yield put(apiFail());
  }
}
function* draftEmailBlastSaga(action) {
  try {
    const response = yield call(draftEmailBlastApi, action.payload);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      yield put(manageEmailBlast({ isEmailBlastDraftAdded: true }));
      toast.success(MESSAGES.SUCCESSFULLY_DRAFT_EMAIL_BLAST);
    }
  } catch (err) {
    yield put(apiFail());
  }
}

function* editDraftEmailBlastSaga(action) {
  try {
    const response = yield call(editDraftEmailBlastApi, action.payload);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      yield put(manageEmailBlast({ isEmailBlastDraftAdded: true }));
      toast.success(MESSAGES.SUCCESSFULLY_DRAFT_EMAIL_BLAST);
    }
  } catch (err) {
    yield put(apiFail());
  }
}

function* deleteDraftEmailBlastSaga(action) {
  try {
    const { draft } = action.payload;
    const DraftData = yield call(deleteDraftEmailBlastApi, draft);
    if (DraftData?.status === RESPONSE_STATUS_CODES.OK) {
      const response = yield call(getDraftEmailBlastApi);
      if (response?.status === RESPONSE_STATUS_CODES.OK) {
        const result = response.data.data;
        yield put(getDraftEmailBlast(result));
      }
      toast.success(MESSAGES.SUCCESSFULLY_DELETED_DRAFT_EMAIL_BLAST);
      return response;
    }
  } catch (err) {
    yield put(apiFail());
  }
}

function* watcherRealtorSaga() {
  yield takeLatest(MY_PROPERTY_REQUEST, myPropertiesSaga);
  yield takeLatest(ADD_PROPERTY_TAG_REQUEST, addPropertyTagRequestSaga);
  yield takeLatest(GET_PROPERTY_HISTORY_REQUEST, getPropertyHistorySaga);
  yield takeLatest(GET_PROPERTIES_TAG_REQUEST, getPropertyTagRequestSaga);
  yield takeLatest(PROPERTY_TAG_ACTION_REQUEST, propertyTagRequestActionSaga);
  yield takeLatest(GET_RELATIONSHIP_HISTORY_REQUEST, getRelationshipHistorySaga);
  yield takeLatest(GET_PROPERTY_NOTIFICATION_REQUEST, getPropertyNotificationSaga);
  yield takeLatest(PROPERTY_NOTIFICATION_ACTION_REQUEST, propertyTagNotificationActionSaga);
  yield takeLatest(CREATE_EMAIL_BLAST_REQUEST, addEmailBlastSaga);
  yield takeLatest(SAVE_DRAFT_EMAIL_BLAST_REQUEST, draftEmailBlastSaga);
  yield takeLatest(EDIT_DRAFT_EMAIL_BLAST_REQUEST, editDraftEmailBlastSaga);
  yield takeLatest(DELETE_DRAFT_EMAIL_BLAST_REQUEST, deleteDraftEmailBlastSaga);
}

export default watcherRealtorSaga;
