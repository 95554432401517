import React from 'react';
import PropTypes from 'prop-types';
import { formats, modules } from '../constants/common';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export const TextEditor = ({ value, onEditorChange, id, name, className }) => {
  return (
    <>
      <ReactQuill
        id={id}
        name={name}
        theme="snow"
        placeholder="Write your description here..."
        modules={modules}
        formats={formats}
        value={value}
        onChange={onEditorChange}
        className={className}
      />
    </>
  );
};

TextEditor.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  onEditorChange: PropTypes.func
};
