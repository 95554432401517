import { API_ROUTES } from '../../constants/apiRoutes';
import { EMAIL_BLAST_TYPE, defaultQueryData } from '../../constants/common';
import api from '../../utils/api';
import { getApiRoute } from '../../utils/helper';

export const getEmailBlastApi = async (queryData = defaultQueryData) => {
  try {
    const response = await api.get(API_ROUTES.GET_EMAIL_BLAST, {
      params: { type: EMAIL_BLAST_TYPE.SENT, ...queryData }
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getDraftEmailBlastApi = async (queryData = defaultQueryData) => {
  try {
    const response = await api.get(API_ROUTES.GET_EMAIL_BLAST, {
      params: { type: EMAIL_BLAST_TYPE.DRAFT, ...queryData }
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getEmailBlastDetailApi = async (emailBlastId) => {
  try {
    const getEmailBlastApiRoute = getApiRoute(API_ROUTES.GET_EMAIL_BLAST_DETAIL, emailBlastId);
    const response = await api.get(getEmailBlastApiRoute);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
