import { Breadcrumbs } from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export const BreadCrumb = ({ links }) => {
  return (
    <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs-wrapper">
      {links.map((link, index) => {
        if (link.last) {
          return <span key={index}>{link.label}</span>;
        } else {
          return (
            <Link key={index} to={link.to}>
              {link.label}
            </Link>
          );
        }
      })}
    </Breadcrumbs>
  );
};

BreadCrumb.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      to: PropTypes.string,
      last: PropTypes.bool
    })
  )
};
