import { Collapse, List, ListItemButton, ListItemText, ListItem } from '@mui/material';
import { Link, NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Logo } from './Logo';
import { SIDE_BAR_MENU_LIST } from '../constants/sideBarMenu';
import { hasChildren, provideLinkFromSubMenu } from '../utils/helper';
import { closeIcon } from '../constants/images';
import './../styles/sidebar-style.css';

const MenuItem = ({
  menu,
  menuOpen,
  setMenuOpen,
  setSubMenuOpen,
  subMenuOpen,
  currentIndex,
  dropdownIndex,
  setDropdownIndex
}) => {
  return hasChildren(menu) ? (
    <MultiLevel
      menu={menu}
      menuOpen={menuOpen}
      setMenuOpen={setMenuOpen}
      subMenuOpen={subMenuOpen}
      setSubMenuOpen={setSubMenuOpen}
      setDropdownIndex={setDropdownIndex}
      dropdownIndex={dropdownIndex}
      currentIndex={currentIndex}
    />
  ) : (
    <SingleLevel
      menu={menu}
      menuOpen={menuOpen}
      setMenuOpen={setMenuOpen}
      setDropdownIndex={setDropdownIndex}
      dropdownIndex={dropdownIndex}
      currentIndex={currentIndex}
    />
  );
};

const disableMenuHandler = (event, menu) => {
  // TODO: Remove disable menu handler when sidebar functionality completed
  if (menu.disable) event.preventDefault();
};

const SingleLevel = ({ menu, setMenuOpen, currentIndex, dropdownIndex, setDropdownIndex }) => {
  const handleSingleClick = () => {
    disableMenuHandler(event, menu);
    menu.isChild ? setMenuOpen(true) : setMenuOpen(false);
    if (currentIndex === dropdownIndex) {
      setMenuOpen(false);
    } else {
      setDropdownIndex(null);
    }
  };
  return (
    <ListItem>
      <NavLink to={menu.link} onClick={handleSingleClick}>
        <ListItemButton disabled={menu.disable}>
          <em>
            <img src={menu.icon} alt={menu.icon} className="normal-icon" />
            <img src={menu.iconWhite} alt={menu.icon} className="white-icon" />
          </em>
          <ListItemText primary={menu.name} />
        </ListItemButton>
      </NavLink>
    </ListItem>
  );
};

const MultiLevel = ({
  menu,
  menuOpen,
  setMenuOpen,
  subMenuOpen,
  setSubMenuOpen,
  currentIndex,
  dropdownIndex,
  setDropdownIndex
}) => {
  const { subMenu } = menu;
  const [firstSubMenu] = subMenu;
  // Get the router link if exist of sub menu recursively call the function to get the router link.
  const link = provideLinkFromSubMenu(firstSubMenu);

  const handleClick = () => {
    if (menu.disable) return;
    if (currentIndex === dropdownIndex) {
      setMenuOpen((prev) => !prev);
      setSubMenuOpen((prev) => !prev); // Toggle submenu open/close
      setMenuOpen(false); // Close other open menus
    } else {
      setMenuOpen((prev) => !prev);
      setSubMenuOpen(true); // Toggle submenu open/close
      setMenuOpen(false); // Close other open menus
      setDropdownIndex(currentIndex);
    }
  };

  return (
    <>
      {/*  Display parent menu. */}
      <ListItem
        onClick={handleClick}
        className={`${subMenuOpen && dropdownIndex === currentIndex ? 'submenu-open' : ''}`}>
        <NavLink
          to={link}
          onClick={(event) => {
            disableMenuHandler(event, menu);
          }}>
          <ListItemButton disabled={menu.disable}>
            <em>
              <img src={menu.icon} alt={menu.icon} className="normal-icon" />
              <img src={menu.iconWhite} alt={menu.icon} className="white-icon" />
            </em>
            <ListItemText primary={menu.name} />
          </ListItemButton>
          <span className="arrow-icon"></span>
        </NavLink>
      </ListItem>

      {/* Render child list as sub menu. */}
      <Collapse in={subMenuOpen && dropdownIndex === currentIndex} timeout="auto" unmountOnExit>
        <List disablePadding>
          {subMenu.map((child) => (
            <MenuItem key={child.name} menu={child} menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
          ))}
        </List>
      </Collapse>
    </>
  );
};

export const Sidebar = () => {
  const [authSidebarMenuItems, setAuthSidebarMenuItems] = useState([]);
  const { isLoggedIn, role } = useSelector((state) => state.auth);
  const [menuOpen, setMenuOpen] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [dropdownIndex, setDropdownIndex] = useState(0);

  useEffect(() => {
    if (isLoggedIn && role) {
      const userRole = role?.name;
      const accessibleMenuItems = SIDE_BAR_MENU_LIST.filter((menuItem) => {
        if (menuItem.role.includes(userRole)) return menuItem;
      });
      setAuthSidebarMenuItems(accessibleMenuItems);
    }
  }, [isLoggedIn, role]);

  // Return the logo and sidebar.
  const handlecloseClick = () => {
    document.body.classList.toggle('sidebar-open');
    document.body.classList.remove('sidebar-close');
  };
  return (
    <>
      <aside>
        <div className="sidebar-logo">
          <Link to="/" title="Life Styles Realty INC">
            <Logo />
          </Link>
          <span className="close-sidebar" onClick={handlecloseClick}>
            <img src={closeIcon} alt="close" />
          </span>
        </div>
        <div className="sidebar-links-wrapper">
          <List>
            {authSidebarMenuItems.map((menu, index) => (
              <MenuItem
                key={menu.name}
                menu={menu}
                menuOpen={menuOpen}
                subMenuOpen={subMenuOpen}
                setSubMenuOpen={setSubMenuOpen}
                setMenuOpen={setMenuOpen}
                setDropdownIndex={setDropdownIndex}
                dropdownIndex={dropdownIndex}
                currentIndex={index}
              />
            ))}
          </List>
        </div>
        <p className="footer-copy-right">
          Copyright {new Date().getFullYear()}
          <Link className="blue-link" to="https://lifestylesrealtyinc.com/" target="_blank">
            Lifestyles Realty, Inc.
          </Link>
        </p>
      </aside>
    </>
  );
};

// Common validation of provided Prop types.
const propTypesOfSidebarMenuObj = {
  menu: PropTypes.shape({
    name: PropTypes.string.isRequired,
    link: PropTypes.string,
    icon: PropTypes.string,
    iconWhite: PropTypes.string,
    subMenu: PropTypes.array,
    disable: PropTypes.bool,
    isChild: PropTypes.bool
  }),
  menuOpen: PropTypes.bool,
  setMenuOpen: PropTypes.func,
  subMenuOpen: PropTypes.bool,
  setSubMenuOpen: PropTypes.func,
  setDropdownIndex: PropTypes.func,
  dropdownIndex: PropTypes.number,
  currentIndex: PropTypes.number
};

MenuItem.propTypes = propTypesOfSidebarMenuObj;
MultiLevel.propTypes = propTypesOfSidebarMenuObj;
SingleLevel.propTypes = propTypesOfSidebarMenuObj;
