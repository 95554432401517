import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import { DataTable } from '../components/DataTable';
import { Label } from '../components/Label';
import { PageLoader } from '../components/PageLoader';
import { getBrokerageOfficeRealtors } from '../redux/actions/brokerageOffice';
import { DEBOUNCE_TIMEOUT, defaultQueryData, LABELS } from '../constants/common';
import { debounce } from '../utils/helper';
import './../styles/inner-page-style.css';
import './../styles/data-table-style.css';
import './../styles/custom-dialog-style.css';

export const BrokerageOfficeRealtorsList = ({ brokerageOfficeId }) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const { brokerageOfficeRealtorsData, realtorsPaginationData } = useSelector(
    (state) => state.brokerageOffice
  );
  const { isLoading } = useSelector((state) => state.apiStatus);
  const [queryData, setQueryData] = useState({ ...defaultQueryData });

  useEffect(() => {
    dispatch(getBrokerageOfficeRealtors(queryData, brokerageOfficeId));
  }, []);

  const sortingChangeHandler = (sortModelData) => {
    if (sortModelData && sortModelData.length > 0) {
      const sortQueryData = {
        ...queryData,
        sortField: sortModelData[0].field,
        sortDirection: sortModelData[0].sort
      };
      setQueryData(sortQueryData);
      dispatch(getBrokerageOfficeRealtors(sortQueryData, brokerageOfficeId));
    }
  };

  const paginationChangeHandler = (paginationModelData) => {
    const paginationQueryData = {
      ...queryData,
      page: paginationModelData.page + 1,
      perPage: paginationModelData.pageSize
    };
    setQueryData(paginationQueryData);
    dispatch(getBrokerageOfficeRealtors(paginationQueryData, brokerageOfficeId));
  };

  const columns = [
    {
      field: 'firstName',
      headerName: 'First name',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <>
            <span className="user-fname">{params.row.firstName}</span>
          </>
        );
      }
    },
    { field: 'lastName', headerName: 'Last name', flex: 1, minWidth: 150 }
  ];

  // Search brokerage office's realtors based on timeout seconds
  const handleSearch = debounce((value) => {
    if (value === '') {
      delete queryData.search;
      dispatch(getBrokerageOfficeRealtors(queryData, brokerageOfficeId));
    } else {
      setSearch(value);
    }
  }, DEBOUNCE_TIMEOUT);
  const searchHandler = () => {
    const searchQueryData = {
      ...queryData,
      search: search
    };
    setQueryData(searchQueryData);
    dispatch(getBrokerageOfficeRealtors(searchQueryData, brokerageOfficeId));
  };
  useEffect(() => {
    if (search !== '') {
      searchHandler();
    }
  }, [search]);

  return (
    <>
      <div className="page-inner">
        <div className="page-content-block">
          <div className="inner-page-title-wrapper">
            <div className="title-left-block">
              <Label variant="h4" label={LABELS.ASSIGNED_REALTORS} />
            </div>
          </div>
          <div className="white-box shadow-box page-table-wrapper">
            {isLoading && <PageLoader />}
            <div className="filter-wrapper">
              <div className="filter-left-block">
                <TextField
                  id="search"
                  label="Search"
                  type="search"
                  placeholder="Search here..."
                  name="search"
                  className="form-group search-field"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => handleSearch(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
              </div>
            </div>
            <div className="table-wrapper">
              <DataTable
                handlePagination={paginationChangeHandler}
                total={
                  realtorsPaginationData && realtorsPaginationData.total
                    ? realtorsPaginationData.total
                    : 0
                }
                columns={columns}
                rows={brokerageOfficeRealtorsData}
                rowHeight={56}
                handleSorting={sortingChangeHandler}
                sortingMode="server"
                paginationMode="server"
                onRowSelectionModelChange={(selectedRow) => {
                  setRowSelectionModel(selectedRow);
                }}
                rowSelectionModel={rowSelectionModel}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

BrokerageOfficeRealtorsList.propTypes = {
  brokerageOfficeId: PropTypes.number
};
