import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { InputAdornment } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Label } from '../../components/Label';
import { Logo } from '../../components/Logo';
import { Button } from '../../components/Button';
import { TextField } from '../../components/TextField';
import { login } from '../../redux/actions/auth';
import { apiStatusClear } from '../../redux/slices/apiStatus';
import { loginSchema } from '../../schemas/authSchema';
import { ROUTE_LIST } from '../../constants/routes';
import { LABELS } from '../../constants/common';
import userPool from '../../utils/userPool';
import './../../styles/login-style.css';

export const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { isLoading, isFailed } = useSelector((state) => state.apiStatus);

  useEffect(() => {
    // If user already logged in then navigate to the dashboard
    const user = userPool.getCurrentUser();
    if (user) {
      navigate(ROUTE_LIST.DASHBOARD);
    }
  }, []);

  const initialValues = {
    email: '',
    password: ''
  };
  const { values, errors, handleChange, handleBlur, handleSubmit, touched, resetForm } = useFormik({
    initialValues: initialValues,
    validationSchema: loginSchema,
    onSubmit: (values) => {
      dispatch(
        login({
          email: values.email,
          password: values.password
        })
      );
    }
  });

  useEffect(() => {
    if (isFailed) {
      resetForm();
      dispatch(apiStatusClear());
    }
  }, [isFailed]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <div>
      <div className="login-page">
        <div className="login-wrapper">
          <div className="login-block">
            <div className="logo-wrapper">
              <Link to="#" className="logo">
                <Logo />
              </Link>
            </div>
            <Label variant="h1" label={LABELS.SIGN_IN} />
            <form onSubmit={handleSubmit}>
              <div className="form-wrapper white-box">
                <TextField
                  fullWidth
                  id="email"
                  label="Email"
                  placeholder="Email Address"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  className="form-group has-required"
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  placeholder="Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  className="form-group password-wrapper has-required"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </div>
              <div className="checkbox-wrapper">
                <Link to={ROUTE_LIST.FORGOT_PASSWORD} className="blue-link">
                  {LABELS.FORGOT_YOUR_PASSWORD}
                </Link>
              </div>
              <Button
                className="blue-btn"
                type="submit"
                name="Sign In"
                disabled={isLoading}
                isLoading={isLoading}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
