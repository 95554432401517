import { Avatar as MuiAvatar } from '@mui/material';
import PropTypes from 'prop-types';
import { generateInitials } from '../utils/helper';

export const Avatar = ({ name, className, ...restProps }) => {
  const initials = generateInitials(name);
  return (
    <MuiAvatar className={className} {...restProps}>
      {initials}
    </MuiAvatar>
  );
};

Avatar.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string
};
