import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Label } from '../components/Label';
import { BreadCrumb } from '../components/BreadCrumb';
import { DataTable } from '../components/DataTable';
import { Button } from '../components/Button';
import { EmailPreviewDialog } from '../components/Popup/EmailPreviewDialog';
import { PageLoader } from '../components/PageLoader';
import { OpenFileInNewTab } from '../components/FileDownload';
import { SingleSelect } from '../components/DropDown/SingleSelect';
import { getRealtorBrokerageOfficeApi } from '../redux/apis/realtor';
import { getEmailBlasts } from '../redux/actions/emailBlast';
import { viewAction, dialogCloseIcon, cloneIcon, pdfIcon } from '../constants/images';
import { ROUTE_LIST } from '../constants/routes';
import {
  ALL_FILTERS,
  BREAD_CRUMB_LABELS,
  BROKERAGE_OFFICE_TYPE,
  DEBOUNCE_TIMEOUT,
  defaultQueryData,
  LABELS,
  ROLE
} from '../constants/common';
import { debounce, getApiRoute } from '../utils/helper';
import { MultiCommercialEmailPreviewDialog } from '../components/Popup/MultiCommercialEmailPreviewDialg';

export const EmailBlastList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { role } = useSelector((state) => state.auth);
  const [emailPreviewOpen, setEmailPreviewOpen] = useState(false);
  const { emailBlastData, paginationData } = useSelector((state) => state.emailBlast);
  const [queryData, setQueryData] = useState({ ...defaultQueryData });
  const { isLoading } = useSelector((state) => state.apiStatus);
  const [search, setSearch] = useState('');
  const [property, setProperty] = useState();
  const all_offices = {
    id: ALL_FILTERS,
    name: 'All Brokerage Offices'
  };
  const [brokerageOfficeOptions, setBrokerageOfficeOptions] = useState([all_offices]);
  const [brokerageOffice, setBrokerageOffice] = useState(ALL_FILTERS);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [isMultiCommercialOffice, setIsMultiCommercialOffice] = useState(false);
  const [isTexasOffice, setIsTexasOffice] = useState(false);

  const breadcrumbsLinks = [
    {
      label: BREAD_CRUMB_LABELS.DASHBOARD,
      to: ROUTE_LIST.DASHBOARD
    },
    {
      label: BREAD_CRUMB_LABELS.EMAIL_BLAST,
      last: true
    }
  ];

  useEffect(() => {
    dispatch(getEmailBlasts());
    getRealtorBrokerageOfficeApi().then((res) =>
      setBrokerageOfficeOptions([all_offices, ...res.data.data])
    );
    setIsInitialRender(false);
  }, []);

  // Email preview dialog
  const openEmailPreviewDialog = (params) => {
    setEmailPreviewOpen(true);
    if (params.brokerageOfficeType === BROKERAGE_OFFICE_TYPE.MULTI_FAMILY_AND_COMMERCIAL) {
      setIsMultiCommercialOffice(true);
      setIsTexasOffice(false);
      setProperty(params);
    } else if (
      params.brokerageOfficeType === BROKERAGE_OFFICE_TYPE.SINGLE_FAMILY &&
      params.isTexasBrokerageOffice === 1
    ) {
      setIsMultiCommercialOffice(false);
      setIsTexasOffice(true);
      setProperty(params);
    } else if (
      params.brokerageOfficeType === BROKERAGE_OFFICE_TYPE.SINGLE_FAMILY &&
      params.isTexasBrokerageOffice === 0
    ) {
      setIsMultiCommercialOffice(false);
      setIsTexasOffice(false);
      setProperty(params);
    }
  };
  const closeEmailPreviewDialog = () => {
    setEmailPreviewOpen(false);
  };

  const cloneActionHandler = (params) => {
    const cloneBlastRoute = getApiRoute(ROUTE_LIST.CLONE_EMAIL_BLAST, params.id);
    navigate(cloneBlastRoute);
  };

  const columns = [
    { field: 'brokerageOfficeName', headerName: 'Brokerage Office', flex: 1, minWidth: 280 },
    {
      field: 'subjectLine',
      headerName: 'Subject Line',
      flex: 1,
      minWidth: 280,
      sortable: false
    },
    {
      field: 'pdfLink',
      headerName: 'PDF Link',
      flex: 1,
      minWidth: 180,
      headerClassName: 'has-link',
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="pdf-wrapper">
            <OpenFileInNewTab
              fileUrl={params.row.IABS}
              fileName={
                <em>
                  <img src={pdfIcon} alt="pdf icon" />
                  View PDF
                </em>
              }
            />
          </div>
        );
      }
    },
    {
      field: 'updatedAt',
      headerName: 'Created',
      flex: 1,
      minWidth: 230
    },
    {
      field: 'type',
      headerName: 'Brokerage office type',
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: (params) => {
        const currentItem = params.row; // Access the current row data
        const type =
          currentItem.brokerageOfficeType === 1 ? 'Multi-Commercial Family' : 'Single Family';
        return <div style={{ fontSize: '14px' }}>{type}</div>;
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      minWidth: 150,
      maxWidth: 150,
      headerClassName: 'action-col',
      cellClassName: 'action-col',
      sortable: false,
      headerAlign: 'right',
      align: 'right',
      renderCell: (params) => {
        return (
          <div className="action-column-wrapper">
            <div className="action-column-inner">
              <Button
                className="view-icon"
                name={<img src={viewAction} alt="view-icon" />}
                title="View"
                onClick={() => openEmailPreviewDialog(params.row)}
              />
            </div>
            {role?.name !== ROLE.ADMIN && (
              <>
                <div className="action-column-inner">
                  <Button
                    onClick={() => cloneActionHandler(params)}
                    className="edit-icon"
                    name={<img src={cloneIcon} alt="clone-icon" />}
                    title="Clone"
                  />
                </div>
              </>
            )}
          </div>
        );
      }
    }
  ];

  const sortingChangeHandler = (sortModelData) => {
    if (sortModelData && sortModelData.length > 0) {
      const sortQueryData = {
        ...queryData,
        sortField: sortModelData[0].field,
        sortDirection: sortModelData[0].sort
      };
      setQueryData(sortQueryData);
      dispatch(getEmailBlasts(sortQueryData));
    }
  };

  const paginationChangeHandler = (paginationModelData) => {
    const paginationQueryData = {
      ...queryData,
      page: paginationModelData.page + 1,
      perPage: paginationModelData.pageSize
    };
    setQueryData(paginationQueryData);
    dispatch(getEmailBlasts(paginationQueryData));
  };

  // Search users based on timeout seconds
  const handleSearch = debounce((value) => {
    if (value === '') {
      delete queryData.search;
      dispatch(getEmailBlasts(queryData));
    } else {
      setSearch(value);
    }
  }, DEBOUNCE_TIMEOUT);
  const searchHandler = () => {
    const searchQueryData = {
      ...queryData,
      search: search
    };
    setQueryData(searchQueryData);
    dispatch(getEmailBlasts(searchQueryData));
  };
  useEffect(() => {
    if (search !== '') {
      searchHandler();
    }
  }, [search]);

  // brokerage office filter
  const brokerageOfficeChangeHandler = () => {
    const brokerageOfficeQueryData = {
      ...queryData,
      brokerageOfficeId: brokerageOffice
    };
    setQueryData(brokerageOfficeQueryData);
    dispatch(getEmailBlasts(brokerageOfficeQueryData));
  };
  useEffect(() => {
    if (brokerageOffice !== '' && brokerageOffice !== ALL_FILTERS) {
      brokerageOfficeChangeHandler();
    } else if (brokerageOffice === ALL_FILTERS && !isInitialRender) {
      // delete brokerage office and fetch data for all brokerage office
      delete queryData.brokerageOfficeId;
      dispatch(getEmailBlasts(queryData));
    }
  }, [brokerageOffice]);

  return (
    <>
      <div className="page-inner email-blast-page-inner">
        <div className="page-content-block">
          <div className="inner-page-title-wrapper">
            <div className="title-left-block">
              <BreadCrumb links={breadcrumbsLinks} />
              <Label variant="h1" label={LABELS.EMAIL_BLAST} />
            </div>
            {role?.name !== ROLE.ADMIN && (
              <div className="title-right-block">
                <div className="right-block-inner">
                  <Link
                    to={ROUTE_LIST.CREATE_EMAIL_BLAST}
                    className="primary-btn"
                    title={'Create New Email Blast'}>
                    Create New Email Blast
                  </Link>
                </div>
              </div>
            )}
          </div>
          <div className="white-box shadow-box page-table-wrapper">
            {isLoading && <PageLoader />}
            <div className="filter-wrapper">
              <div className="filter-left-block">
                <TextField
                  id="search"
                  label="Search"
                  type="search"
                  placeholder="Search here..."
                  name="search"
                  className="form-group search-field"
                  onChange={(e) => handleSearch(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
              </div>
              <div className="filter-right-block">
                <div className="dropdown-wrapper has-large-dropdown">
                  <SingleSelect
                    placeholderText="Brokerage Office"
                    items={brokerageOfficeOptions}
                    label="Brokerage Office"
                    className="form-group search-field"
                    MenuProps={{ classes: { paper: 'custom-select-dropdown' } }}
                    name="brokerageOffice"
                    onChange={(e) => setBrokerageOffice(e.target.value)}
                    value={brokerageOffice}
                  />
                </div>
              </div>
            </div>
            <div className="table-wrapper no-checkbox">
              <DataTable
                columns={columns}
                rows={emailBlastData}
                rowHeight={56}
                handleSorting={sortingChangeHandler}
                sortingMode="server"
                paginationMode="server"
                handlePagination={paginationChangeHandler}
                total={paginationData && paginationData.total ? paginationData.total : 0}
              />
            </div>
          </div>
        </div>
      </div>
      {isMultiCommercialOffice ? (
        <MultiCommercialEmailPreviewDialog
          open={emailPreviewOpen}
          dialogIcon={dialogCloseIcon}
          onClose={closeEmailPreviewDialog}
          handleClose={closeEmailPreviewDialog}
          propertyData={{
            ...property,
            editorDescription: property.description
          }}
        />
      ) : (
        <EmailPreviewDialog
          open={emailPreviewOpen}
          dialogIcon={dialogCloseIcon}
          onClose={closeEmailPreviewDialog}
          handleClose={closeEmailPreviewDialog}
          propertyData={{
            ...property,
            officeExist: isTexasOffice
          }}
        />
      )}
    </>
  );
};
