import { createSlice } from '@reduxjs/toolkit';
import userPool from '../../utils/userPool';

const initialState = {
  isLoggedIn: false
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    verifyOtp(state, action) {
      const authData = { ...state, ...action.payload, isLoggedIn: true };
      return authData;
    },
    logout() {
      const cognitoUser = userPool.getCurrentUser();
      if (cognitoUser != null) {
        cognitoUser.signOut();
        localStorage.clear();
      }
    },
    getProfile(state, action) {
      const profileData = { ...state, ...action.payload };
      return profileData;
    },
    setUser(state) {
      const authData = { ...state, isLoggedIn: true };
      return authData;
    }
  }
});
export const { logout, verifyOtp, setUser, getProfile } = authSlice.actions;
export default authSlice.reducer;
