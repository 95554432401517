import * as Yup from 'yup';

export const loginSchema = Yup.object({
  email: Yup.string().email().required().label('Email'),
  password: Yup.string().min(8).max(20).required().label('Password')
});

export const forgotPasswordSchema = Yup.object({
  email: Yup.string().email().required().label('Email')
});

export const resetPasswordSchema = Yup.object({
  password: Yup.string().min(8).max(20).required().label('Password'),
  confirmPassword: Yup.string()
    .required()
    .label('Confirm password')
    .oneOf([Yup.ref('password'), null], 'Password must match')
});
