import { call, put, takeLatest } from 'redux-saga/effects';
import { apiFail } from '../slices/apiStatus';
import { getMembers } from '../slices/member';
import { getMembersApi } from '../apis/member';
import { RESPONSE_STATUS_CODES } from '../../constants/common';
import { GET_MEMBER_REQUEST } from '../../constants/redux';

function* getMembersSaga(action) {
  try {
    const response = yield call(getMembersApi, action.payload);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      yield put(getMembers(result));
    }
  } catch (err) {
    yield put(apiFail());
  }
}

function* watcherMemberSaga() {
  yield takeLatest(GET_MEMBER_REQUEST, getMembersSaga);
}

export default watcherMemberSaga;
