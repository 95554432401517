import { FormControl, FormHelperText } from '@mui/material';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-number-input';

export const PhoneNumberInput = ({
  className,
  name,
  label,
  value,
  helperText = '',
  onChange,
  onBlur,
  error = false,
  placeholderText,
  size = 'small',
  variant = 'standard',
  ...restProps
}) => {
  const handlePhoneInputChange = (event) => {
    if (onChange && event) {
      onChange('phone', event);
    } else {
      onChange('phone', '');
    }
  };
  return (
    <>
      <FormControl
        size={size}
        variant={variant}
        fullWidth
        error={error}
        className="phone-input-wrapper">
        <label className="phone-input-label">{label}</label>
        <PhoneInput
          placeholder={placeholderText}
          name={name}
          onChange={handlePhoneInputChange}
          onBlur={onBlur}
          value={value}
          className={className}
          international
          {...restProps}
        />
        <FormHelperText error>{helperText}</FormHelperText>
      </FormControl>
    </>
  );
};

PhoneNumberInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.bool,
  placeholderText: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard'])
};
