import { createSlice } from '@reduxjs/toolkit';
import { getDateFromDateTime, getUserStatus } from '../../utils/helper';

const initialState = {};

const realtorSlice = createSlice({
  name: 'realtor',
  initialState: initialState,
  reducers: {
    myProperty(state, action) {
      let myPropertiesData = action.payload.propertyData;
      const paginationData = action.payload.pagination;
      myPropertiesData = myPropertiesData.map((property) => {
        return {
          ...property,
          dateTagged: getDateFromDateTime(property.dateTagged),
          status: getUserStatus(property.status)
        };
      });
      return { ...state, myPropertiesData, paginationData };
    },
    propertyTagRequest(state, action) {
      const tagRequestData = action.payload.tagTransferRequestData;
      const paginationData = action.payload.pagination;
      return { ...state, tagRequestData, paginationData };
    },
    getPropertyHistory(state, action) {
      let propertyHistoryData = action.payload.propertyHistoryData;
      const paginationData = action.payload.pagination;
      propertyHistoryData = propertyHistoryData.map((history) => {
        if (history.fieldName === 'BrokerageOffice') {
          return {
            ...history,
            oldValue: history.oldBrokerageOffice.name,
            newValue: history.newBrokerageOffice.name,
            createdAt: getDateFromDateTime(history.updatedAt)
          };
        } else {
          return {
            ...history,
            createdAt: getDateFromDateTime(history.updatedAt)
          };
        }
      });
      return { ...state, propertyHistoryData, paginationData };
    },
    getRelationshipHistory(state, action) {
      let relationshipHistoryData = action.payload.relationshipHistoryData;
      const paginationData = action.payload.pagination;
      relationshipHistoryData = relationshipHistoryData.map((history) => {
        return {
          ...history,
          updatedAt: getDateFromDateTime(history.updatedAt)
        };
      });
      return { ...state, relationshipHistoryData, paginationData };
    },
    getPropertyNotification(state, action) {
      const notificationData = action.payload.notificationData;
      const paginationData = action.payload.pagination;
      return { ...state, notificationData, paginationData };
    },
    manageEmailBlast(state, action) {
      return { ...state, emailBlast: action.payload };
    }
  }
});

export const {
  myProperty,
  propertyTagRequest,
  getPropertyHistory,
  getRelationshipHistory,
  getPropertyNotification,
  manageEmailBlast
} = realtorSlice.actions;
export default realtorSlice.reducer;
