import { API_ROUTES } from '../../constants/apiRoutes';
import api from '../../utils/api';

export const generatePresignedUrlApi = async ({ file, imageType }) => {
  try {
    const response = await api.post(API_ROUTES.GENERATE_PRESIGNED_URL, {
      fileName: file.name,
      contentType: file.type,
      type: imageType
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const uploadFileApi = async ({ file, url }) => {
  try {
    const response = await api.put(url, file, {
      headers: { 'Content-Type': file.type }
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const generatePresignedUrlsApi = async ({ files, imageType }) => {
  try {
    const fileArr = files.map((file) => {
      return {
        fileName: file.name,
        contentType: file.type,
        type: imageType
      };
    });
    const response = await api.post(API_ROUTES.GENERATE_PRESIGNED_URLS, {
      files: fileArr
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
