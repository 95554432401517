import {
  FIRST_LOGIN_CHANGE_PASSWORD_REQUEST,
  FORGOT_PASSWORD_REQUEST,
  GET_PROFILE_REQUEST,
  LOGIN_REQUEST,
  RESEND_OTP_REQUEST,
  RESET_PASSWORD_REQUEST,
  SET_LOGGED_IN_USERINFO_REQUEST,
  UPDATE_PROFILE_REQUEST,
  VERIFY_OTP_REQUEST
} from '../../constants/redux';

export const login = (userData) => {
  return {
    type: LOGIN_REQUEST,
    payload: userData
  };
};

export const verifyOtp = (userData) => {
  return {
    type: VERIFY_OTP_REQUEST,
    payload: userData
  };
};

export const resendOtp = () => {
  return {
    type: RESEND_OTP_REQUEST
  };
};

export const forgotPassword = (email) => {
  return {
    type: FORGOT_PASSWORD_REQUEST,
    payload: email
  };
};

export const resetPassword = (credentials) => {
  return {
    type: RESET_PASSWORD_REQUEST,
    payload: credentials
  };
};

export const firstLoginChangePassword = (credentials) => {
  return {
    type: FIRST_LOGIN_CHANGE_PASSWORD_REQUEST,
    payload: credentials
  };
};

export const setUserInfo = () => {
  return {
    type: SET_LOGGED_IN_USERINFO_REQUEST
  };
};

export const getProfile = () => {
  return {
    type: GET_PROFILE_REQUEST
  };
};

export const updateProfile = (userData) => {
  return {
    type: UPDATE_PROFILE_REQUEST,
    payload: userData
  };
};
