import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { SingleSelect } from '../../components/DropDown/SingleSelect';
import { Label } from '../../components/Label';
import { TextField } from '../../components/TextField';
import { Button } from '../../components/Button';
import { BreadCrumb } from '../../components/BreadCrumb';
import { PhoneNumberInput } from '../../components/PhoneNumberInput';
import { PageLoader } from '../../components/PageLoader';
import { getEditUserDetails, getUserRoles, updateUserDetails } from '../../redux/actions/admin';
import {
  BREAD_CRUMB_LABELS,
  OTHER_BLAST_PERMISSION_BROKERAGE_OFFICES,
  DEBOUNCE_TIMEOUT,
  IABS_ALLOWED_ROLES,
  LABELS,
  STATUS_ITEMS
} from '../../constants/common';
import { ROUTE_LIST } from '../../constants/routes';
import { addUserSchema } from '../../schemas/adminSchema';
import { debounce, getUserRole } from '../../utils/helper';
import 'react-phone-number-input/style.css';
import './../../styles/inner-page-style.css';
import './../../styles/form-box-layout-style.css';
import { AutoCompleteMultiSelect } from '../../components/DropDown/AutoCompleteMultiSelect';
import { getBrokerageOfficesListApi } from '../../redux/apis/brokerageOffice';

export const EditUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isSucceed } = useSelector((state) => state.apiStatus);
  const { userRoles } = useSelector((state) => state.admin);
  const { editUserData } = useSelector((state) => state.admin);
  const { isLoading } = useSelector((state) => state.apiStatus);
  const [isUserEdited, SetIsUserEdited] = useState(false);
  const { id } = useParams();
  const [brokerageOfficeData, setBrokerageOfficeData] = useState([]);
  const [brokerageOfficeSearch, setBrokerageOfficeSearch] = useState('');

  const breadcrumbsLinks = [
    {
      label: BREAD_CRUMB_LABELS.DASHBOARD,
      to: ROUTE_LIST.DASHBOARD
    },
    {
      label: BREAD_CRUMB_LABELS.USERS,
      to: ROUTE_LIST.USER_LIST
    },
    {
      label: BREAD_CRUMB_LABELS.EDIT_USER,
      last: true
    }
  ];

  useEffect(() => {
    if (isSucceed && isUserEdited) navigate(ROUTE_LIST.USER_LIST);
  }, [isSucceed, isUserEdited]);

  useEffect(() => {
    // dispatch user roles
    if (!userRoles) dispatch(getUserRoles());
    // dispatch edit user data
    dispatch(getEditUserDetails(id));
  }, []);

  useEffect(() => {
    // Get brokerage office lists for options
    getBrokerageOfficesListApi().then((res) => {
      const responseData = res.data.data;
      const brokerageArray = [];
      responseData.forEach(({ id, name }) => {
        brokerageArray.push({
          value: id,
          label: name
        });
      });
      setBrokerageOfficeData(brokerageArray);
    });
  }, []);

  const handleBrokerageOfficeSearch = debounce((value) => {
    setBrokerageOfficeSearch(value);
  }, DEBOUNCE_TIMEOUT);

  const brokerageOfficeSearchHandler = () => {
    getBrokerageOfficesListApi({
      search: brokerageOfficeSearch
    }).then((res) => {
      let resData = res.data.data;
      setBrokerageOfficeData(resData.map((data) => ({ label: data.name, value: data.id })));
    });
  };

  useEffect(() => {
    if (brokerageOfficeSearch !== '' && brokerageOfficeSearch !== 0) {
      brokerageOfficeSearchHandler();
    }
  }, [brokerageOfficeSearch]);

  const initialValues = {
    firstName: _.get(editUserData, 'firstName', ''),
    lastName: _.get(editUserData, 'lastName', ''),
    email: _.get(editUserData, 'email', ''),
    phone: _.get(editUserData, 'phone', ''),
    role: userRoles ? _.get(editUserData, 'roleId', '') : '',
    status: _.get(editUserData, 'status', ''),
    IABS: _.get(editUserData, 'IABS', ''),
    brokerageOfficeIds: _.get(editUserData, 'blastPermissions', [])
  };
  const { values, errors, handleChange, handleBlur, handleSubmit, touched, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema: addUserSchema,
      enableReinitialize: true,
      onSubmit: (values) => {
        dispatch(
          updateUserDetails({
            id,
            firstName: _.get(values, 'firstName', ''),
            lastName: _.get(values, 'lastName', ''),
            phone: _.get(values, 'phone', ''),
            role: _.get(values, 'role', ''),
            status: _.get(values, 'status', ''),
            IABS: IABS_ALLOWED_ROLES.includes(getUserRole(values.role))
              ? _.get(values, 'IABS', '')
              : '',
            blastPermissions: OTHER_BLAST_PERMISSION_BROKERAGE_OFFICES.includes(
              getUserRole(values.role)
            )
              ? _.get(values, 'brokerageOfficeIds', [])
              : []
          })
        );
        SetIsUserEdited(true);
      }
    });

  // Validate phone number based on country code
  useMemo(() => {
    if (!isValidPhoneNumber(values.phone)) {
      errors.phone = 'Please enter valid phone number';
    } else {
      delete errors.phone;
    }
  }, [values.phone, errors]);

  return (
    <>
      <div className="page-inner">
        <div className="page-content-block">
          <div className="inner-page-title-wrapper">
            <div className="title-left-block">
              <BreadCrumb links={breadcrumbsLinks} />
              <Label variant="h1" label={LABELS.EDIT_USER} />
            </div>
          </div>
          <div className="white-box shadow-box">
            {isLoading && <PageLoader />}
            <form onSubmit={handleSubmit}>
              <div className="form-row-wrapper">
                <div className="form-col has-required">
                  <TextField
                    fullWidth
                    id="fname"
                    label="First Name"
                    placeholder="First Name"
                    name="firstName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                    error={touched.firstName && Boolean(errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                    className="form-group"
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                <div className="form-col has-required">
                  <TextField
                    fullWidth
                    id="lname"
                    label="Last Name"
                    placeholder="Last Name"
                    name="lastName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                    error={touched.lastName && Boolean(errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                    className="form-group"
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                <div className="form-col has-required">
                  <TextField
                    fullWidth
                    id="email"
                    label="Email"
                    placeholder="Email Address"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    className="form-group"
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                </div>
                <div className="form-col has-required">
                  <PhoneNumberInput
                    placeholderText="Phone Number"
                    name="phone"
                    label="Phone"
                    onChange={setFieldValue}
                    onBlur={handleBlur}
                    value={values.phone}
                    className={
                      touched.phone && Boolean(errors.phone) ? 'form-group error' : 'form-group'
                    }
                    helperText={touched.phone && errors.phone}
                    rules={{ required: true }}
                  />
                </div>
                <div className="form-col has-required">
                  <SingleSelect
                    disabled
                    placeholderText="Role"
                    items={userRoles ?? []}
                    label="Role"
                    MenuProps={{ classes: { paper: 'custom-select-dropdown' } }}
                    name="role"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.role}
                    error={touched.role && Boolean(errors.role)}
                    helperText={touched.role && errors.role}
                  />
                </div>
                <div className="form-col has-required">
                  <SingleSelect
                    placeholderText="Status"
                    items={STATUS_ITEMS}
                    label="Status"
                    MenuProps={{ classes: { paper: 'custom-select-dropdown' } }}
                    name="status"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.status}
                    error={touched.status && Boolean(errors.status)}
                    helperText={touched.status && errors.status}
                  />
                </div>
                {IABS_ALLOWED_ROLES.includes(getUserRole(values.role)) && (
                  <div className="form-col has-required">
                    <TextField
                      fullWidth
                      id="iabs"
                      label="IABS"
                      placeholder="IABS"
                      name="IABS"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.IABS}
                      error={touched.IABS && Boolean(errors.IABS)}
                      helperText={touched.IABS && errors.IABS}
                      className="form-group"
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                )}
                {OTHER_BLAST_PERMISSION_BROKERAGE_OFFICES.includes(getUserRole(values.role)) && (
                  <div
                    className={
                      touched.brokerageOfficeIds && Boolean(errors.brokerageOfficeIds)
                        ? 'form-col error has-large-dropdown'
                        : 'form-col has-large-dropdown'
                    }>
                    <AutoCompleteMultiSelect
                      freeSolo
                      filterOptions={(options) => options}
                      name="brokerageOfficeIds"
                      className="multi-select"
                      options={brokerageOfficeData}
                      label="Other Blast Permissions"
                      classes={{ paper: 'custom-select-dropdown multiselect-dropdown' }}
                      placeholder={values.brokerageOfficeIds?.length > 0 ? '' : 'Brokerage Offices'}
                      onChange={(event, value) => {
                        setFieldValue('brokerageOfficeIds', value);
                      }}
                      onBlur={handleBlur}
                      value={values.brokerageOfficeIds}
                      error={touched.brokerageOfficeIds && Boolean(errors.brokerageOfficeIds)}
                      helperText={touched.brokerageOfficeIds && errors.brokerageOfficeIds}
                      onInputChange={(e, value, reason) => {
                        handleBrokerageOfficeSearch(e.target.value);
                        if (reason === 'reset') {
                          getBrokerageOfficesListApi().then((res) => {
                            setBrokerageOfficeData(
                              res.data.data.map((data) => ({
                                label: data.name,
                                value: data.id
                              }))
                            );
                          });
                        }
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="form-btn-wrapper">
                <Button
                  className="primary-btn border-btn"
                  name="Cancel"
                  onClick={() => navigate(ROUTE_LIST.USER_LIST)}
                />
                <Button className="primary-btn" type="submit" name="Save" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
