import { all } from 'redux-saga/effects';
import watcherAdminSaga from './admin';
import watcherBrokerageOfficeSaga from './brokerageOffice';
import watcherAuthSaga from './auth';
import watcherPropertySaga from './property';
import watcherRealtorSaga from './realtor';
import watcherMemberSaga from './member';
import watcherDashboardSaga from './dashboard';
import watcherEmailBlastSaga from './emailBlast';

export default function* rootSaga() {
  yield all([
    watcherAuthSaga(),
    watcherAdminSaga(),
    watcherBrokerageOfficeSaga(),
    watcherPropertySaga(),
    watcherRealtorSaga(),
    watcherMemberSaga(),
    watcherDashboardSaga(),
    watcherEmailBlastSaga()
  ]);
}
