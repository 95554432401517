import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { BreadCrumb } from '../../components/BreadCrumb';
import { ConfirmDialog } from '../../components/Popup/ConfirmDialog';
import { Label } from '../../components/Label';
import { DataTable } from '../../components/DataTable';
import { PageLoader } from '../../components/PageLoader';
import { Button } from '../../components/Button';
import {
  getPropertyNotification,
  propertyTagNotificationAction
} from '../../redux/actions/realtor';
import {
  BREAD_CRUMB_LABELS,
  defaultQueryData,
  DIALOG,
  LABELS,
  REMINDER_ACTIONS
} from '../../constants/common';
import { ROUTE_LIST } from '../../constants/routes';
import { tagTransferBlueIcon } from '../../constants/images';
import './../../styles/inner-page-style.css';
import './../../styles/data-table-style.css';
import './../../styles/custom-dialog-style.css';

export const GetPropertyNotification = () => {
  const dispatch = useDispatch();
  const [openDialog, setOpen] = useState(false);
  const { notificationData, paginationData } = useSelector((state) => state.realtor);
  const [queryData, setQueryData] = useState({ ...defaultQueryData });
  const { isLoading } = useSelector((state) => state.apiStatus);
  const [propertyId, setPropertyId] = useState();
  const breadcrumbsLinks = [
    {
      label: BREAD_CRUMB_LABELS.DASHBOARD,
      to: ROUTE_LIST.DASHBOARD
    },
    {
      label: BREAD_CRUMB_LABELS.MY_PROPERTIES,
      to: ROUTE_LIST.MY_PROPERTIES
    },
    {
      label: BREAD_CRUMB_LABELS.NOTIFICATIONS,
      last: true
    }
  ];

  useEffect(() => {
    dispatch(getPropertyNotification(queryData));
  }, []);

  // Release property Dialog
  const actionHandler = ({ id, action }) => {
    dispatch(propertyTagNotificationAction({ propertyId: id, action }));
    setOpen(false);
  };
  const openReleaseTagDialog = (params) => {
    setOpen(true);
    setPropertyId(params.row.id);
  };
  const closeReleaseTagDialog = () => {
    setOpen(false);
  };
  const releaseTagHandler = () => {
    actionHandler({ action: REMINDER_ACTIONS.RELEASE, id: propertyId });
    setOpen(false);
  };
  const buttonsList = [
    {
      name: 'Cancel',
      className: 'primary-btn border-btn',
      event: closeReleaseTagDialog
    },
    {
      name: 'Release',
      className: 'primary-btn',
      event: releaseTagHandler
    }
  ];

  const columns = [
    { field: 'brokerageOfficeName', headerName: 'Brokerage Office', flex: 1, minWidth: 160 },
    { field: 'address', headerName: 'Address', flex: 1, minWidth: 200, sortable: false },
    { field: 'city', headerName: 'State', flex: 1, minWidth: 140 },
    { field: 'state', headerName: 'City', flex: 1, minWidth: 100 },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="notification-action">
            <Button
              className={'status-badge active'}
              name={'Retain'}
              onClick={() => actionHandler({ id: params.id, action: REMINDER_ACTIONS.RETAIN })}
            />
            <Button
              className={'status-badge inactive'}
              name={'Release'}
              onClick={() => openReleaseTagDialog(params)}
            />
          </div>
        );
      }
    }
  ];

  const paginationChangeHandler = (paginationModelData) => {
    const paginationQueryData = {
      ...queryData,
      page: paginationModelData.page + 1,
      perPage: paginationModelData.pageSize
    };
    setQueryData(paginationQueryData);
    dispatch(getPropertyNotification(paginationQueryData));
  };

  const sortingChangeHandler = (sortModelData) => {
    if (sortModelData && sortModelData.length > 0) {
      const sortQueryData = {
        ...queryData,
        sortField: sortModelData[0].field,
        sortDirection: sortModelData[0].sort
      };
      setQueryData(sortQueryData);
      dispatch(getPropertyNotification(sortQueryData));
    }
  };

  return (
    <>
      <div className="page-inner">
        <div className="page-content-block">
          <div className="inner-page-title-wrapper">
            <div className="title-left-block">
              <BreadCrumb links={breadcrumbsLinks} />
              <Label variant="h1" label={LABELS.NOTIFICATIONS} />
            </div>
          </div>
          <div className="white-box shadow-box page-table-wrapper">
            {isLoading && <PageLoader />}
            <div className="table-wrapper no-checkbox">
              <DataTable
                handlePagination={paginationChangeHandler}
                total={paginationData && paginationData.total ? paginationData.total : 0}
                columns={columns}
                rows={notificationData}
                rowHeight={56}
                handleSorting={sortingChangeHandler}
                sortingMode="server"
                paginationMode="server"
              />
            </div>
          </div>
        </div>
      </div>
      <ConfirmDialog
        open={openDialog}
        onClose={closeReleaseTagDialog}
        dialogIcon={tagTransferBlueIcon}
        dialogTitle={DIALOG.RELEASE_TAG_DIALOG_TITLE}
        dialogBody={DIALOG.RELEASE_TAG_DIALOG_BODY}
        buttons={buttonsList}
      />
    </>
  );
};
