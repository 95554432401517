import { Dialog, DialogContent } from '@mui/material';
import PropTypes from 'prop-types';

export const ImagePreviewDialog = ({
  open,
  handleClose,
  classes,
  dialogIcon,
  path,
  ...restProps
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="View-user-wrapper custom-dialog image-preview-dialogue"
      classes={classes}
      {...restProps}>
      <div className="dialog-content-wrapper">
        <DialogContent>
          <div className="image-content-wrapper">
            <div className="image-wrapper">
              <img src={path} alt="placeholder image" />
            </div>
            <button className="close-icon" onClick={handleClose} name="close">
              <img src={dialogIcon} alt="close icon" />
            </button>
          </div>
        </DialogContent>
      </div>
    </Dialog>
  );
};

ImagePreviewDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  classes: PropTypes.object,
  dialogIcon: PropTypes.string,
  path: PropTypes.string
};
