import {
  CREATE_PROPERTY_REQUEST,
  DELETE_PROPERTY_REQUEST,
  EDIT_PROPERTY_REQUEST,
  GET_PROPERTIES_REQUEST
} from '../../constants/redux';

export const getProperties = (queryData) => {
  return {
    type: GET_PROPERTIES_REQUEST,
    payload: queryData
  };
};

export const addProperty = (propertyData) => {
  return {
    type: CREATE_PROPERTY_REQUEST,
    payload: propertyData
  };
};

export const editProperty = (queryData) => {
  return {
    type: EDIT_PROPERTY_REQUEST,
    payload: queryData
  };
};

export const deleteProperty = (propertyData) => {
  return {
    type: DELETE_PROPERTY_REQUEST,
    payload: propertyData
  };
};
