import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import PrivateRoutes from './components/PrivateRoute';
import { PageLoader } from './components/PageLoader';
import { setUserInfo } from './redux/actions/auth';
import { ROUTE_LIST } from './constants/routes';
import { PRIVATE_ROUTES } from './constants/privateRoutes';
import { Login } from './pages/Auth/login';
import { ResetPassword } from './pages/Auth/resetPassword';
import { ForgotPassword } from './pages/Auth/forgotPassword';
import { VerifyOtp } from './pages/Auth/verifyOtp';
import { ChangePassword } from './pages/Auth/changePassword';
import userPool from './utils/userPool';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

const App = () => {
  const [authRoutes, setAuthRoutes] = useState([]);
  const { isLoggedIn, role } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    const user = userPool.getCurrentUser();
    if (user) {
      dispatch(setUserInfo());
    }
  }, []);

  useEffect(() => {
    if (role && isLoggedIn) {
      const userRole = role?.name;
      const accessibleRoutes = PRIVATE_ROUTES.filter((route) => {
        if (route.role.includes(userRole)) return route;
      });
      setAuthRoutes(accessibleRoutes);
    }
  }, [isLoggedIn, role]);

  return (
    <>
      <Router>
        <Routes>
          {/****** PUBLIC ROUTES ******/}
          <Route path={ROUTE_LIST.LOGIN} element={<Login />} />
          <Route path={ROUTE_LIST.FORGOT_PASSWORD} element={<ForgotPassword />} />
          <Route path={ROUTE_LIST.RESET_PASSWORD} element={<ResetPassword />} />
          <Route path={ROUTE_LIST.VERIFY_OTP} element={<VerifyOtp />} />
          <Route path={ROUTE_LIST.CHANGE_PASSWORD} element={<ChangePassword />} />

          {/****** PRIVATE ROUTES ******/}
          <Route element={<PrivateRoutes />}>
            {authRoutes.map((privateRoute, index) => {
              return (
                <Route path={privateRoute.path} element={privateRoute.component} key={index} />
              );
            })}
            <Route path="/" element={<Navigate to={ROUTE_LIST.DASHBOARD} />} />
            <Route path="*" element={<PageLoader />} />
          </Route>
        </Routes>
      </Router>
      <ToastContainer
        autoClose={5000}
        className="toast-notification"
        closeOnClick={false}
        position={toast.POSITION.BOTTOM_RIGHT}
        hideProgressBar={true}
      />
    </>
  );
};

export default App;
