import { Autocomplete, FormHelperText, InputLabel } from '@mui/material';
import PropTypes from 'prop-types';
import { TextField } from '../TextField';

export const AutoCompleteSingleSelect = ({
  options = [],
  value,
  onChange,
  label,
  placeholder,
  className,
  error,
  helperText,
  name,
  filterOptions,
  ...restProps
}) => {
  const findOptionByValue = (value) => {
    return options.find((option) => option.value === value) || null;
  };

  const selectedOption = findOptionByValue(value);
  return (
    <div className="form-group">
      <InputLabel id="simple-select-outlined-label">{label}</InputLabel>
      <Autocomplete
        getOptionLabel={(option) => option.label}
        options={options}
        filterOptions={filterOptions} // For set options based on search server side
        value={selectedOption}
        onChange={onChange}
        className={className}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        {...restProps}
        renderInput={(params) => <TextField {...params} placeholder={placeholder} name={name} />}
      />
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </div>
  );
};

AutoCompleteSingleSelect.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  name: PropTypes.string,
  filterOptions: PropTypes.func
};
