import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import { MESSAGES, RESPONSE_STATUS_CODES, ROLE } from '../../constants/common';
import {
  CREATE_PROPERTY_REQUEST,
  DELETE_PROPERTY_REQUEST,
  EDIT_PROPERTY_REQUEST,
  GET_PROPERTIES_REQUEST
} from '../../constants/redux';
import {
  addPropertyApi,
  deletePropertyApi,
  editPropertyApi,
  getPropertiesApi
} from '../apis/property';
import { myPropertiesApi } from '../apis/realtor';
import { apiFail } from '../slices/apiStatus';
import { getProperty, manageProperty } from '../slices/property';
import { myProperty } from '../slices/realtor';

function* getPropertiesSaga(action) {
  try {
    const response = yield call(getPropertiesApi, action.payload);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      yield put(getProperty(result));
    }
  } catch (err) {
    yield put(apiFail());
  }
}

function* addPropertySaga(action) {
  try {
    const propertyData = yield call(addPropertyApi, action.payload);
    if (propertyData?.status === RESPONSE_STATUS_CODES.OK) {
      yield put(manageProperty({ isPropertyAdded: true }));
      toast.success(MESSAGES.SUCCESSFULLY_CREATED_PROPERTY);
    }
  } catch (err) {
    yield put(apiFail());
  }
}

function* editPropertySaga(action) {
  try {
    const response = yield call(editPropertyApi, action.payload);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      yield put(manageProperty({ isPropertyEdited: true }));
      toast.success(MESSAGES.SUCCESSFULLY_UPDATED_PROPERTY);
    }
  } catch (err) {
    yield put(apiFail());
  }
}

function* deletePropertySaga(action) {
  try {
    const { property, queryData } = action.payload;
    const propertyData = yield call(deletePropertyApi, property);
    if (propertyData?.status === RESPONSE_STATUS_CODES.OK) {
      toast.success(MESSAGES.SUCCESSFULLY_DELETED_PROPERTY);
      // if role is realtor then redirect to my properties else redirect to property
      if (property?.role && property?.role?.name === ROLE.REALTOR) {
        const response = yield call(myPropertiesApi, queryData);
        if (response?.status === RESPONSE_STATUS_CODES.OK) {
          const result = response.data.data;
          yield put(myProperty(result));
        }
      } else {
        const response = yield call(getPropertiesApi, queryData);
        if (response?.status === RESPONSE_STATUS_CODES.OK) {
          const result = response.data.data;
          yield put(getProperty(result));
        }
      }
    }
  } catch (err) {
    yield put(apiFail());
  }
}

function* watcherPropertySaga() {
  yield takeLatest(CREATE_PROPERTY_REQUEST, addPropertySaga);
  yield takeLatest(GET_PROPERTIES_REQUEST, getPropertiesSaga);
  yield takeLatest(EDIT_PROPERTY_REQUEST, editPropertySaga);
  yield takeLatest(DELETE_PROPERTY_REQUEST, deletePropertySaga);
}

export default watcherPropertySaga;
