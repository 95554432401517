import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getBrokerageOfficeBlastPermissionsApi,
  getBrokerageOfficeDetailApi,
  getBrokerageOfficeRealtorsApi,
  getBrokerageOfficesApi
} from '../apis/brokerageOffice';
import { apiFail } from '../slices/apiStatus';
import {
  getBrokerageOfficeBlastPermissions,
  getBrokerageOfficeRealtors,
  getBrokerageOffices
} from '../slices/brokerageOffice';
import { RESPONSE_STATUS_CODES } from '../../constants/common';
import {
  GET_BROKERAGE_OFFICES_DETAIL_REQUEST,
  GET_BROKERAGE_OFFICES_REQUEST,
  GET_BROKERAGE_OFFICE_BLAST_PERMISSIONS,
  GET_BROKERAGE_OFFICE_REALTORS_REQUEST
} from '../../constants/redux';

function* getBrokerageOfficesSaga(action) {
  try {
    const response = yield call(getBrokerageOfficesApi, action.payload);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      yield put(getBrokerageOffices(result));
    }
  } catch (err) {
    yield put(apiFail());
  }
}

function* getBrokerageOfficeRealtorsSaga(action) {
  try {
    const response = yield call(getBrokerageOfficeRealtorsApi, action.payload);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      yield put(getBrokerageOfficeRealtors(result));
    }
  } catch (err) {
    yield put(apiFail());
  }
}

function* getBrokerageOfficeDetailSaga(action) {
  try {
    yield call(getBrokerageOfficeDetailApi, action.payload);
  } catch (err) {
    yield put(apiFail());
  }
}

function* getBrokerageOfficeBlastPermissionsSaga(action) {
  try {
    const response = yield call(getBrokerageOfficeBlastPermissionsApi, action.payload);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      yield put(getBrokerageOfficeBlastPermissions(result));
    }
  } catch (err) {
    yield put(apiFail());
  }
}

function* watcherBrokerageOfficeSaga() {
  yield takeLatest(GET_BROKERAGE_OFFICES_REQUEST, getBrokerageOfficesSaga);
  yield takeLatest(GET_BROKERAGE_OFFICE_REALTORS_REQUEST, getBrokerageOfficeRealtorsSaga);
  yield takeLatest(GET_BROKERAGE_OFFICES_DETAIL_REQUEST, getBrokerageOfficeDetailSaga);
  yield takeLatest(GET_BROKERAGE_OFFICE_BLAST_PERMISSIONS, getBrokerageOfficeBlastPermissionsSaga);
}

export default watcherBrokerageOfficeSaga;
