import { InputAdornment, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import { BreadCrumb } from '../components/BreadCrumb';
import { Label } from '../components/Label';
import { DataTable } from '../components/DataTable';
import { Button } from '../components/Button';
import { ViewMemberDialog } from '../components/Popup/ViewMemberDialog';
import { PageLoader } from '../components/PageLoader';
import { getMembers } from '../redux/actions/member';
import { DEBOUNCE_TIMEOUT, LABELS, memberPaginationQueryData } from '../constants/common';
import { ROUTE_LIST } from '../constants/routes';
import { dialogCloseIcon, viewAction } from '../constants/images';
import { debounce } from '../utils/helper';
import './../styles/inner-page-style.css';
import './../styles/data-table-style.css';
import './../styles/custom-dialog-style.css';

export const MemberList = () => {
  const [member, setMember] = useState();
  const dispatch = useDispatch();
  const [viewMemberOpen, setviewMemberOpen] = useState(false);
  const [queryData, setQueryData] = useState({ ...memberPaginationQueryData });
  const [search, setSearch] = useState('');
  const { membersData, paginationData } = useSelector((state) => state.member);
  const { isLoading } = useSelector((state) => state.apiStatus);

  const breadcrumbsLinks = [
    {
      label: 'Dashboard',
      to: ROUTE_LIST.DASHBOARD
    },
    {
      label: 'Members',
      last: true
    }
  ];

  useEffect(() => {
    dispatch(getMembers(queryData));
  }, []);

  // View Dialog
  const closeViewMemberDialog = () => {
    setviewMemberOpen(false);
  };
  const openViewMemberDialog = (params) => {
    setMember(params.row);
    setviewMemberOpen(true);
  };

  const columns = [
    {
      field: 'firstName',
      headerName: 'First name',
      flex: 1,
      minWidth: 140
    },
    { field: 'lastName', headerName: 'Last name', flex: 1, minWidth: 120 },
    { field: 'email', headerName: 'Email', flex: 1, minWidth: 200 },
    {
      field: 'phone',
      headerName: 'Phone',
      flex: 1,
      minWidth: 140,
      sortable: false
    },
    {
      field: 'membershipType',
      headerName: 'Membership Level',
      flex: 1,
      minWidth: 260
    },
    {
      field: 'membershipEndDate',
      headerName: 'Membership End Date',
      flex: 1,
      minWidth: 200
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 90,
      sortable: false,
      renderCell: (params) => {
        const statusClass = params.row.status.toLowerCase() || '';
        return (
          <>
            <span className={`status-badge ${statusClass}`}>{params.row.status}</span>
          </>
        );
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      minWidth: 100,
      headerClassName: 'action-col',
      cellClassName: 'action-col',
      sortable: false,
      align: 'right',
      renderCell: (params) => {
        return (
          <div className="action-column-wrapper">
            <div className="action-column-inner">
              <Button
                title="View"
                className="view-icon"
                onClick={() => openViewMemberDialog(params)}
                name={<img src={viewAction} alt="view-icon" />}
              />
            </div>
          </div>
        );
      }
    }
  ];

  // Search users based on timeout seconds
  const handleSearch = debounce((value) => {
    if (value === '') {
      delete queryData.search;
      dispatch(getMembers(queryData));
    } else {
      setSearch(value);
    }
  }, DEBOUNCE_TIMEOUT);
  const searchHandler = () => {
    const searchQueryData = {
      ...queryData,
      search: search
    };
    setQueryData(searchQueryData);
    dispatch(getMembers(searchQueryData));
  };
  useEffect(() => {
    if (search !== '') {
      searchHandler();
    }
  }, [search]);

  const paginationChangeHandler = (paginationModelData) => {
    const paginationQueryData = {
      ...queryData,
      page: paginationModelData.page + 1,
      perPage: paginationModelData.pageSize
    };
    setQueryData(paginationQueryData);
    dispatch(getMembers(paginationQueryData));
  };

  const sortingChangeHandler = (sortModelData) => {
    if (sortModelData && sortModelData.length > 0) {
      const sortQueryData = {
        ...queryData,
        sortField: sortModelData[0].field,
        sortDirection: sortModelData[0].sort
      };
      setQueryData(sortQueryData);
      dispatch(getMembers(sortQueryData));
    }
  };

  return (
    <>
      <div className="page-inner">
        <div className="page-content-block">
          <div className="inner-page-title-wrapper">
            <div className="title-left-block">
              <BreadCrumb links={breadcrumbsLinks} />
              <Label variant="h1" label={LABELS.MEMBERS} />
            </div>
          </div>
          <div className="white-box shadow-box page-table-wrapper">
            {isLoading && <PageLoader />}
            <div className="filter-wrapper memberlist-filter-wrapper">
              <div className="filter-left-block">
                <TextField
                  id="search"
                  label="Search"
                  type="search"
                  placeholder="Search here..."
                  name="search"
                  className="form-group search-field"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => handleSearch(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
              </div>
            </div>
            <div className="table-wrapper">
              <DataTable
                handlePagination={paginationChangeHandler}
                total={paginationData && paginationData.total ? paginationData.total : 0}
                columns={columns}
                rows={membersData}
                rowHeight={56}
                handleSorting={sortingChangeHandler}
                sortingMode="server"
                paginationMode="server"
                pageSizeOptions={[memberPaginationQueryData.perPage]} // Hide rowsPerPage dropdown
                pageSizeCustom={memberPaginationQueryData.perPage}
              />
            </div>
          </div>
        </div>
      </div>
      <ViewMemberDialog
        open={viewMemberOpen}
        dialogIcon={dialogCloseIcon}
        onClose={closeViewMemberDialog}
        handleClose={closeViewMemberDialog}
        memberData={member}
      />
    </>
  );
};
