import api from '../../utils/api';
import { API_ROUTES } from '../../constants/apiRoutes';
import { defaultQueryData } from '../../constants/common';
import { getApiRoute } from '../../utils/helper';

export const getBrokerageOfficesApi = async (queryData = defaultQueryData) => {
  try {
    const response = await api.get(API_ROUTES.GET_BROKERAGE_OFFICES, { params: queryData });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getBrokerageOfficeRealtorsApi = async ({
  queryData = defaultQueryData,
  brokerageOfficeId
}) => {
  try {
    const getBrokerageOfficeRealtorsApiRoute = getApiRoute(
      API_ROUTES.GET_BROKERAGE_OFFICE_REALTORS,
      brokerageOfficeId
    );
    const response = await api.get(getBrokerageOfficeRealtorsApiRoute, { params: queryData });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getBrokerageOfficeBlastPermissionsApi = async ({
  queryData = defaultQueryData,
  brokerageOfficeId
}) => {
  try {
    const getBrokerageOfficeBlastPermissionsApiRoute = getApiRoute(
      API_ROUTES.GET_USERS_BROKERAGE_OFFICE_BLAST_PERMISSIONS,
      brokerageOfficeId
    );
    const response = await api.get(getBrokerageOfficeBlastPermissionsApiRoute, {
      params: queryData
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getBrokerageOfficeDetailApi = async ({ brokerageOfficeId }) => {
  try {
    const getBrokerageOfficeRealtorsApiRoute = getApiRoute(
      API_ROUTES.GET_BROKERAGE_OFFICE_DETAIL,
      brokerageOfficeId
    );
    const response = await api.get(getBrokerageOfficeRealtorsApiRoute);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getBrokerageOfficesListApi = async (queryData) => {
  try {
    const response = await api.get(API_ROUTES.LIST_BROKERAGE_OFFICES, { params: queryData });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
